import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { Button, Divider, IconButton, Stack, TextField, TextFieldProps, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import classnames from 'classnames';
import { v4 as uuidv4 } from "uuid";
import {DashboardContext} from '../../../contexts/DashboardContext';
import { getUserEmail } from '../../../helpers/authUser';
import { updateDashboardFunc } from '../../../lib/helper';
import { Dashboard, DashboardNote, DashboardNoteType, RefreshDataEnum } from '../../../types/files';
import {AuthContext} from '../../../contexts/AuthContext';
import { NotesContent } from '../../modals/dashboard-notes/DashboardNoteDialog';
import { LoadingButton } from '@mui/lab';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        padding: 16,
        borderRadius: 16,
        border: `2px solid ${theme.colors.primary['100']}`,
        background: '#fff',
        transition: 'all 0.2s ease',
        "&:hover": {
            border: `2px solid ${theme.colors.primary['200']}`,
            boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
        },
    },
    title: {
        fontFamily: 'Inter',
        fontSize: '1.2rem',
        fontWeight: 'bold',
        color: theme.palette.primary.main,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    inputField: {
        width: '100%',
        "& .MuiOutlinedInput-root": {
            width: '100%',
            padding: 'unset',
            "& > textarea": {
                fontSize: '1rem',
                padding: 'unset',
            },
        },
    },
    cancelButton: {
        borderRadius: 40,
        borderColor: theme.colors.neutral['100'],
        background: theme.colors.neutral['100'],
        color: theme.colors.neutral['500'],
        textTransform: 'none',
        transition: 'ease-in-out 300ms',
    },
    saveButton: {
        borderRadius: 40,
        backgroundColor: theme.colors.primary['600'],
        color: 'white',
        textTransform: 'none',
        transition: 'ease-in-out 300ms',
        '&:hover': {
            backgroundColor: theme.colors.primary['500'],
        }
    },
    icon: {
        "& > svg": {
            width: 32,
            height: 32,
        },
        padding: 'unset',
        color: theme.colors.neutral['500'],
    },
}));

export const CommentaryTitle = `$['Commentary']`;

const Commentary: React.FC<{}> = () => {
    const classes = useStyles();
    const { user } = useContext(AuthContext);
    const { isPublicView, dashboard } = useContext(DashboardContext);

    const [saveReady, setSaveReady] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [expanded, setExpanded] = useState<boolean>(false);
    const inputValue = useRef<TextFieldProps>(null);

    const commentaryNote = useMemo(() => dashboard!.notes?.find(note => note.title === CommentaryTitle), [dashboard]);
    const commentaryNoteContent = useMemo(() => (JSON.parse(commentaryNote?.data || '{}') as NotesContent[])?.[1]?.data?.text || '', [commentaryNote]);

    const handleSave = useCallback(async (commentary: string) => {
        setLoading(true);

        const noteData = JSON.stringify([
            {type: 'header', data: {level: 2,  text: CommentaryTitle,}},
            {type: 'paragraph', data: {text: commentary}},
        ]);
        const newNote: DashboardNote = {
            id: uuidv4(),
            title: CommentaryTitle,
            data: noteData,
            type: DashboardNoteType.External,
            createdBy: getUserEmail(user),
            updatedBy: getUserEmail(user),
            createdAt: new Date(),
            updatedAt: new Date(),
        };
        let oldNotes: DashboardNote[] = dashboard!.notes || [];
        let newNotes: DashboardNote[] = [];

        if (commentaryNote) {
            newNotes = oldNotes.map(note => {
                if (note.id === commentaryNote.id)
                    return newNote;
                return note;
            }) || [];
        } else {
            newNotes = [...oldNotes, newNote];
        }

        const dashboardUpdate: Dashboard = {
            ...dashboard!,
            notes: newNotes,
            refreshData: {
                lastUpdatedBy: 'user',
                shouldRefresh: true,
                modifiedData: RefreshDataEnum.Notes,
            }
        };

        updateDashboardFunc(dashboardUpdate).then(() => {
            setLoading(false);
            setSaveReady(false);
            setExpanded(false);
        });
    }, [user, dashboard, commentaryNote]);

    return (<>
        <Stack direction="column" className={classes.container} spacing={1} width="100%">
            <Typography className={classes.title}> {'Commentary'} </Typography>
                <Stack direction="row" alignItems={!expanded ? 'flex-start' : 'flex-end'} justifyContent="flex-start" width="100%">
                    <TextField variant="outlined"
                        className={classnames('no-border', classes.inputField)}
                        inputProps={{ style: { width: '100%', height: '100%' }, className: 'no-fade' }}
                        defaultValue={commentaryNoteContent}
                        placeholder={!isPublicView ? 'Add your comments here...' : ''}
                        onChange={() => {setSaveReady(!!inputValue.current?.value); setExpanded(true);}}
                        inputRef={inputValue}
                        disabled={isPublicView}
                        rows={!expanded ? 1 : 4}
                        multiline
                    />
                    {!saveReady && (
                        <IconButton className={classes.icon}
                            onClick={(e) => {
                                e.stopPropagation();
                                setExpanded(prev => !prev);
                            }} disableRipple>
                            <ExpandMoreIcon style={{ transform: !expanded ? 'none' : 'rotate(180deg)' }} />
                        </IconButton>
                    )}
                </Stack>
                {saveReady && (<>
                    <Divider sx={{ margin: '8px 2px', height: 'unset' }} />
                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end">
                        <Button variant="outlined" size="small"
                            className={classes.cancelButton}
                            onClick={() => {setSaveReady(false); setExpanded(false);}}
                        > {'Cancel'} </Button>
                        <LoadingButton variant="contained" size="small"
                            className={classes.saveButton}
                            loading={loading}
                            onClick={() => handleSave?.(inputValue.current?.value as string)}
                        > {'Save'} </LoadingButton>
                    </Stack>
                </>)}
        </Stack>
    </>);
}

export default Commentary;
