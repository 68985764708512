import quotedPrintable from 'quoted-printable';
import { useCallback, useContext, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import topLeveDomains from "../helpers/topLevelDomains.json";
import { defaultStages } from "../shared/dashboard";
import { UnassignedContent } from "../types/files";
// import { getUrlDestinationFunc } from "../lib/helper";
import { AuthContext } from "../contexts/AuthContext";

export type AttachmentType = {
  url: string;
  name: string;
  size?: number;
  contentType: string;
};

export type EmailDeal = {
  id: string;
  name: string;
  website?: string;
  stage?: string;
  deck?: AttachmentType;
  externalDecks?: AttachmentType[];
  email?: string;
};

type ContentBlock = {
  name: string;
  url?: string|null;
  start: number;
  end: number;
};

export const CommonDomains = ['docsend.com', 'linkedin.com', 'gmail.com', 'outlook.com'];

const UrlPattern = /https?:\/\/(?:www\.)?([a-zA-Z0-9-]+\.[a-zA-Z]{2,})(?=\/|\?|#|$)|(?:www\.)?([a-zA-Z0-9-]+\.[a-zA-Z]{2,})(?=\/|\?|#|$)/gi;
const DomainPattern = /^([a-z0-9|-]+[a-z0-9]{1,}\.)*[a-z0-9|-]+[a-z0-9]{1,}\.[a-z]{2,}$/;
const IPv4Pattern = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
const MaxIterations = 1000;

export const extractDomains = (text: string) => {
  const results: string[] = [];
  let iterations = 0;
  let match;

  if (!(text && typeof text === 'string'))
    return results;

  while ((match = UrlPattern.exec(text)) !== null) {
    iterations++;

    if (iterations > MaxIterations) {
      console.warn("Exceeded maximum iterations.");
      break;
    }

    const fullUrl = match[0];
    const domain = match[1] || match[2];

    if (/^@/.test(fullUrl))
      continue;

    if (!(DomainPattern.test(domain) || IPv4Pattern.test(domain)))
      continue;

    if (!results.some(result => result === domain))
      results.push(domain);
  }

  return results;
}


const useEmailDealsExtraction = () => {
  const { userGroup } = useContext(AuthContext);
  const [urlDestinationMap, setUrlDestinationMap] = useState<Map<string, string>>(new Map());

  const topLevelDomainsList = useMemo(() =>
    Object.entries(topLeveDomains as { [key: string]: string | string [] }).find(([key]) => key === 'result')?.pop() ?? []
  , []);

  const sanitizeUrl = useCallback((originUrl?: string) => originUrl?.replace(/^https?:\/\//, '')?.replace(/^www\./, '') || '', []);

  const textToBlocks = useCallback((emailBody: string) => {
    const decoded = quotedPrintable.decode(emailBody);
    const blocks = decoded.split(/\n/).flatMap((block, i) => (block.trim() === '') ? (i > 0 ? [''] : []) : [block.trim()]);

    return blocks;
  }, []);

  const blocksToText = useCallback((blocks: string[], startIndex: number, endIndex?: number, noBreak?: boolean) => {
    const texts: string[] = [];
    let blanks = 0;

    for (let i = startIndex; i < (endIndex ?? blocks.length); i++) {
      const block = blocks[i].trim();

      if (!noBreak && (blanks >= 3 || block === '--'))
        break;

      if (block !== '') {
        texts.push(block);
        blanks = 0;
      } else {
        blanks++;
      }
    }

    return texts.join('\n');
  }, []);

  const extractCompanyUrls = useCallback((block: string) => {
    const matchesUrl = block.match(/[\\[(<]?\s*https?:\/\/[^\s\])>]+\s*[\])>]?/g);
    const matchesEmail = block.match(/[\\[(<]?\s*(?:mailto:)?\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b\s*[\])>]?/gi);
    let links: string[] = [];

    if (!!matchesUrl) {
      links = matchesUrl.map(match => {
        const urlMatch = match.match(/(https?:\/\/[^\s\])>]+)/);

        return urlMatch ? urlMatch[1] : null;
      }).filter(url => !!url) as string[];
    } else if (!!matchesEmail) {
      links = matchesEmail.map(match => {
        const mailMatch = match.match(/([A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,})/);

        return mailMatch ? (mailMatch[1]) : null;
      }).filter(email => !!email).map(email => email?.split('@')?.[1] ?? '');
    }

    if (!!links.length) {
      const extractedUrls = links.flatMap(link => extractDomains(link));

      return extractedUrls.filter(url => !!url
        && topLevelDomainsList.includes(((String(url)).split('.').pop() || '')?.trim().toLowerCase()));
    }

    return [];
  }, [topLevelDomainsList]);

  const extractFinalUrls = useCallback(async (initialUrls: string[]) => {
    const redirects = await Promise.all(initialUrls.map(url =>
      urlDestinationMap.has(url) ? Promise.resolve(urlDestinationMap.get(url))
        // : getUrlDestinationFunc({ url })
        : Promise.resolve(url)
    ));
    const links = redirects.map((redirect, i) => redirect ?? initialUrls[i]);

    setUrlDestinationMap(prev => {
      initialUrls.forEach((url, i) => prev.set(url, links[i]));

      return prev;
    });

    if (!!links.length) {
      const extractedUrls = links.flatMap(link => extractDomains(link));

      return extractedUrls.filter(url => !!url
        && topLevelDomainsList.includes(((String(url)).split('.').pop() || '')?.trim().toLowerCase()));
    }

    return [];
  }, [topLevelDomainsList, urlDestinationMap]);

  const extractCompanyStage = useCallback((blocks: string[], startIndex: number, endIndex?: number) => {
    const blockTexts = Array.from(blocks).slice(startIndex, endIndex);

    for (let block = 0; block < blockTexts.length; block++) {
      for (let stage = 0; stage < defaultStages.length; stage++) {
        if (blockTexts[block].toLowerCase().includes(defaultStages[stage].toLowerCase()))
          return defaultStages[stage];
      }
    }

    return '';
  }, []);

  const extractExternalDecks = useCallback((blocks: string[], startIndex: number, endIndex?: number) => {
    const blockTexts = Array.from(blocks).slice(startIndex, endIndex);
    const plainUrlPattern = /(?:\s*[<(\\[]\s*)?(https?:\/\/[^\s)>}\]]+)(?:\s*[>)\\]]\s*)?/gi; // ":url:" from any domain // :: as <>, [], ()
    const patterns = [
      /(?:\S*\s*)?\bdeck\b\s*:\s*here\s*[(<[]\s*(https?:\/\/[^\s)>}\]]+)\s*[)>}\]]/gi,        // "Deck: here :url:"      // :: as <>, [], ()
      /(?:\S*\s*)?\bdeck\b\s*here\s*[(<[]\s*(https?:\/\/[^\s)>}\]]+)\s*[)>}\]]/gi,            // "Deck here :url:"       // :: as <>, [], ()
      /(?:\S*\s*)?\bdeck\b\s*[(<[]\s*(https?:\/\/[^\s)>}\]]+)\s*[)>}\]]/gi,                   // "Deck :url:"            // :: as <>, [], ()
    ];
    const decks: Set<string> = new Set([]);
    let found = false;
    let next = false;

    for (let j = 0; j < blockTexts.length; j++) {
      if (found || !!blockTexts[j].toLowerCase().match(/\bdeck\b/)) {
        found = true;
        next = !next;
        let checkPatterns = (found && !next) ? [plainUrlPattern] : patterns;
        for (let i = 0; i < checkPatterns.length; i++) {
          const matches = blockTexts[j].match(checkPatterns[i])
            ?.map(match => {
              const urlMatch = match.match(/https?:\/\/[^\s)>}\]]+/i);

              return urlMatch ? urlMatch[0].trim() : null;
            }).filter(Boolean) || [];

          if (!!matches.length) {
            next = false;
            matches.forEach(matched => {
              if (!!matched)
                decks.add(JSON.stringify({ url: matched, name: matched, contentType: 'text/plain' }));
            });
          }
        }
        if (found && !next)
          break;
      }
    }

    return (!!decks.size ? Array.from(decks).map(deck => JSON.parse(deck)) : undefined);
  }, []);

  const matchCompanyName = useCallback((urls: string[], companyName: string) => {
    for (let i = 0; i < urls.length; i++) {
      const extractedUrl = urls[0];

      if (!!extractedUrl && topLevelDomainsList.includes((extractedUrl.split('.').pop() || '')?.trim().toLowerCase())) {
        const urlTokens = sanitizeUrl(extractedUrl).split(/[\W/]+/).slice(0, -1).map(item => item.trim().toLowerCase());
        const blockWords = companyName.split(/[^a-zA-Z0-9]+/)
          .filter((word, i, self) => (i === self.findIndex(item => item === word)))
          .filter(word => isNaN(Number(word)) && word.length >= 3)
          .map(word => word.trim().toLowerCase());
        const matchedWords: string[] = [];

        urlTokens.forEach(token => {
          blockWords.forEach(word => {
            if (token.includes(word) || word.includes(token)) {
              if (!matchedWords.join('').includes(word))
                matchedWords.push(word);
            }
          });
        });

        if (!!matchedWords.length)
          return extractedUrl;
      }
    }

    return null;
  }, [sanitizeUrl, topLevelDomainsList]);

  const matchAttachment = useCallback((companyName: string, attachments?: string[]) => {
    if(!Array.isArray(attachments))
      return undefined;

    const fileNameTokens = attachments.map(attachment => attachment.split(/[\W/]+/).slice(0, -1)
      .filter((word, i, self) => (i === self.findIndex(item => item === word)))
      .filter(word => isNaN(Number(word)) && word.length >= 3)
      .map(word => word.trim().toLowerCase()));
    let deck: AttachmentType|undefined = undefined;

    if (!!fileNameTokens.length) {
      const companyWords = companyName.split(/[\W/]+/)
        .filter((word, i, self) => (i === self.findIndex(item => item === word)))
        .filter(word => isNaN(Number(word)) && word.length >= 3)
        .map(word => word.trim().toLowerCase());

      fileNameTokens.forEach((fNtokens, i) => {
        if (JSON.stringify(companyWords.sort()) === JSON.stringify(fNtokens.sort())
          || companyWords.some(wFToken => fNtokens.some(fnToken => fnToken.includes(wFToken)))
          || fNtokens.some(fNToken => companyWords.some(wFToken => wFToken.includes(fNToken))))
          deck = {url: '', name: attachments[i], contentType: 'application/pdf' } as AttachmentType;
      });
    }

    return deck;
  }, []);

  const registerDeal = useCallback((deals: EmailDeal[], blocks: string[], current: ContentBlock, attachments?: string[]) => {
    const website = current.url ?? undefined;

    if (!website || !deals.find(deal => deal.website === website)) {
      const name = current.name;
      const stage = extractCompanyStage([...blocks], current.start, current.end);
      const extDecks = extractExternalDecks([...blocks], current.start, current.end) || [];
      const fileAttachment = matchAttachment(name, attachments);
      const deck = fileAttachment ?? extDecks?.[0] ?? undefined;
      const email = blocksToText([...blocks], current.start, current.end);
      let externalDecks: AttachmentType[] = [];

      if (!!fileAttachment)
        externalDecks.push(fileAttachment);

      if (!!extDecks.length)
        extDecks.forEach(deck => externalDecks.push(deck));

      deals.push({ id: uuidv4(), name, website, stage, deck, externalDecks, email });
    }
  }, [blocksToText, extractCompanyStage, extractExternalDecks, matchAttachment]);

  const extractEmailDeals = useCallback((emailContent: UnassignedContent) => new Promise<EmailDeal[]>(async (resolve) => {
    const { body, potentialCompanies } = emailContent;
    const blocks = textToBlocks(body?.trim() || '');
    const companyNames = potentialCompanies?.flatMap(companies =>
        companies.split('\n').map(company => company.replace(/^[\W_]+|[\W_]+$/g, '').trim()))
      ?.filter(company => !!company && company.toLowerCase() !== userGroup.toLowerCase()) ?? [];
    const promises: Promise<ContentBlock>[] = [];
    let deals: EmailDeal[] = [];

    if (!!companyNames.length) {
      promises.push(...companyNames.map(async (name, j, self) => {
        const startIndices = blocks.map((block, index) =>
          block.toLowerCase().split(/[\W/]+/).join('').includes(self[j].toLowerCase().split(/[\W/]+/).join('')) ? index : -1
        ).filter(index => index !== -1).reverse();
        let current: ContentBlock = { url: null, name, start: -1, end: -1 };

        if (!!startIndices.length) {
          outer: for (let start = 0; start < startIndices.length; start++) {
            let end = (j < self.length - 1) ? blocks.slice(startIndices[start] + 1).findIndex(block => block.toLowerCase().includes(self[j + 1].toLowerCase())) : blocks.length;

            if ((j < self.length - 1) && (end !== -1))
              end = startIndices[start] + end + 1;

            for (let i = startIndices[start]; (i >= 0) && (i < end); i++) {
              const initialUrls = extractCompanyUrls(blocks[i]);
              const matchedInitialUrl = matchCompanyName(initialUrls, name) as string|null;

              if (CommonDomains.some(excluded => initialUrls.join("|").includes(excluded)))
                continue;

              if (!!matchedInitialUrl) {
                const parts = matchedInitialUrl.split('.');

                current.url = parts.length > 2 ? parts.slice(-2).join('.') : matchedInitialUrl;
              } else if (!!initialUrls.length) {
                const finalUrls = await extractFinalUrls(initialUrls);
                const matchedFinalUrl = matchCompanyName(finalUrls, name) as string|null;

                if (!!matchedFinalUrl) {
                  const parts = matchedFinalUrl.split('.');

                  current.url = parts.length > 2 ? parts.slice(-2).join('.') : matchedFinalUrl;
                }
              }

              if (!!current.url) {
                current.start = startIndices[start];
                current.end = end;
                break outer;
              }
            }

            if (!current.url && (companyNames.length === 1)) {
              current.start = 0;
              current.end = blocks.length;
            }
          }
        }

        return current;
      }));

      const dealBlocks = await Promise.all(promises);

      dealBlocks.forEach(current => registerDeal(deals, blocks, current, emailContent.attachments));
    }

    resolve(deals);
  }), [extractCompanyUrls, extractFinalUrls, matchCompanyName, registerDeal, textToBlocks, userGroup]);

  return { sanitizeUrl, extractEmailDeals };
};

export default useEmailDealsExtraction;
