import { useRef, useState } from 'react';
import { Button, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { AnsweredQuestion } from '../../../contexts/DashboardQueriesContext';
import theme from '../../../theme';

const useStyles = makeStyles((theme) => ({
    menuButton: {
        minWidth: 32,
        color: theme.colors.neutral['400'],
        padding: 'unset !important',
        "&:hover": {
            color: theme.colors.neutral['600'],
            backgroundColor: 'inherit',
        },
    },
}));

const AnswerMenu: React.FC<{
    answeredQuestion: AnsweredQuestion,
    displayMenu?: boolean,
    onSetIsEditingAnswer?: (question: string | undefined) => void,
    onSetEditAnswer?: (answer: string | undefined) => void,
    onDelete?: () => void,
}> = ({
    answeredQuestion, displayMenu,
    onSetIsEditingAnswer, onSetEditAnswer, onDelete,
}) => {
    const classes = useStyles();
    const [open, setOpen] = useState<boolean>(false);
    const anchorRef = useRef<HTMLButtonElement>(null);

    if (!displayMenu)
        return (<></>);

    return (<>
        <Button className={classes.menuButton} ref={anchorRef}
            startIcon={<MoreVertIcon sx={{ height: '20px', width: '20px' }} />}
            onClick={(e: any) => {
                e.preventDefault();
                e.stopPropagation();
                setOpen(true);
            }}
        />
        <Popper open={open}
            anchorEl={anchorRef.current}
            placement="bottom-start"
            transition>
            {({ TransitionProps }) => (
                <Grow {...TransitionProps}>
                    <Paper>
                        <ClickAwayListener onClickAway={() => setOpen(false)}>
                            <MenuList autoFocusItem={open}>
                                <MenuItem onClick={(e) => {
                                    e.stopPropagation();
                                    setOpen(false);
                                    onSetIsEditingAnswer?.(answeredQuestion.question);
                                    onSetEditAnswer?.(answeredQuestion.answer as string);
                                }}> {'Edit'} </MenuItem>
                                <MenuItem onClick={(e) => {
                                    e.stopPropagation();
                                    setOpen(false);
                                    onDelete?.();
                                }} sx={{ color: theme.colors.error['500']}}> {'Delete'} </MenuItem>
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    </>);
}

export default AnswerMenu;
