import React, { useState } from "react";
import useDashboardQuery from "../../../hooks/useDashboardQuery";
import TeamAnswer from "../dashboard-query-answer/TeamAnswer";
import EmptyQueryContainer from "../dashboard-query-container/EmptyQueryContainer";

const TeamQuery: React.FC<{}> = () => {
    const { queryAnswer } = useDashboardQuery('Team');
    const [isSkipped, setSkippped] = useState<boolean>(false);

    return (<>
        <EmptyQueryContainer queryAnswer={queryAnswer} titleOnly={isSkipped} contentOnly>
            <TeamAnswer answeredQuestion={queryAnswer.answeredQuestion} onSkip={setSkippped} />
        </EmptyQueryContainer>
    </>);
}

export default TeamQuery;
