import { useCallback, useState } from "react";
import { Container, Divider, Stack, Tab, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { TabContext, TabList } from "@mui/lab";
import classnames from "classnames";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import ProPlanModal from "../modals/subscriptions/ProPlanModal";
import configuredAsyncComponentLoader from "../templates/loader";
import UnassignedContentsBadge from "../atoms/UnassignedContentsBadge";
import ForwardDeals from "../atoms/ForwardDeals";

const ActivityFeed = configuredAsyncComponentLoader(() => import('../organisms/home/ActivityFeed'));

export enum HomePageTabs {
    Companies = 'Companies',
    Investors = 'Investors',
}

const useStyles = makeStyles((theme) => ({
    rootContainer: {
        height: '100%',
        maxWidth: 'unset !important',
        overflow: 'hidden',
    },
    title: {
        fontFamily: 'Poppins',
        fontSize: '2.2rem',
        fontWeight: '700',
    },
    tabs: {
        "& .MuiTabs-indicator": {
            height: 3,
            background: theme.colors.primary['700'],
        }
    },
    tab: {
        minWidth: 100,
        minHeight: 48,
        marginRight: 8,
        padding: '6px 8px',
    },
    tabText: {
        color: '#878E95',
        fontFamily: 'Lato',
        fontWeight: 'bold',
        textTransform: 'none',
    },
    selectedTabText: {
        color: 'black',
        fontFamily: 'Lato',
        fontWeight: 'bold',
    },
    tabPanel: {
        height: '100%',
        width: '100%',
        padding: 'unset',
    },
}));

const HomePage: React.FC<{ flags?: any }> = ({ flags }) => {
    const classes = useStyles();
    const [selectedTab, setSelectedTab] = useState<HomePageTabs>(HomePageTabs.Companies);

    const getClassForText = useCallback((tab: HomePageTabs) =>
        (classnames(classes.tabText, selectedTab === tab && classes.selectedTabText))
        // eslint-disable-next-line
    , [selectedTab]);

    return (<>
        <Container className={classes.rootContainer}>
            <Stack direction="column" pt={2}>
                <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
                    <Typography className={classes.title}>
                        {'Welcome to your deal flow'}
                    </Typography>
                    <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-end">
                        {/* <BulkAddMenu /> */}
                        {/* {flags?.shareDealsWorkflow && (<BulkShareDeals.Button />)} */}
                    </Stack>
                </Stack>
                <TabContext value={selectedTab}>
                    <Stack>
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <TabList className={classes.tabs} onChange={(_, value) => setSelectedTab(value)}>
                                <Tab className={classes.tab}
                                    label={<Typography className={getClassForText(HomePageTabs.Companies)}>
                                            {HomePageTabs.Companies}
                                        </Typography>}
                                    value={HomePageTabs.Companies} />
                                <Tab className={classes.tab}
                                    label={<Typography className={getClassForText(HomePageTabs.Investors)}>
                                            {HomePageTabs.Investors}
                                        </Typography>}
                                    value={HomePageTabs.Investors} />
                            </TabList>
                            <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-end" mt="-20px">
                                <ForwardDeals />
                                <UnassignedContentsBadge />
                            </Stack>
                        </Stack>
                        <Divider />
                    </Stack>
                    <ActivityFeed />
                </TabContext>
            </Stack>
        </Container>
        <ProPlanModal variant="limit" />
    </>);
}

export default withLDConsumer()(HomePage);
