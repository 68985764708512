import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Dialog, DialogContent, DialogTitle, Divider, Stack, Typography } from "@mui/material";
import { Viewer, ScrollMode, SpecialZoomLevel } from "@react-pdf-viewer/core";
import { scrollModePlugin } from '@react-pdf-viewer/scroll-mode';

import awsConfig from '../../../aws-exports';
import { s3DownloadExternalBucket, s3Download } from "../../../helpers/s3";
import { scrollbarStyle } from '../../../shared/dashboard';
import { CompanyFile } from "../../../types/files";
import theme from "../../../theme";
import { ReactComponent as PdfIcon } from "../../../assets/icons/pdfNew.svg";
import {ReactComponent as WorldGridIcon} from "../../../assets/icons/world.svg";

const useStyles = makeStyles(() => ({
    dialog: {
        "& .MuiDialog-paper": {
            minWidth: 1200,
            maxWidth: 1200,
            minHeight: '80vh',
            maxHeight: '80vh',
            padding: '8px 16px',
            borderRadius: 24,
        }
    },
    dialogTitle: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 8,
        padding: '8px 16px',
    },
    dialogContent: {
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'stretch',
        padding: 0,
        overflowX: 'hidden',
        overflowY: 'auto',
        "& .rpv-core__inner-pages": {...scrollbarStyle},
        "& .rpv-core__page-layer": {
          '&::after': { all: 'revert' },
        },
        "& .rpv-core__canvas-layer": {
          boxShadow: '0px 3px 5px -1px rgba(16, 24, 40,0.2), 0px 5px 8px 0px rgba(16, 24, 40,0.14), 0px 1px 14px 0px rgba(16, 24, 40,0.12)',
          scale: '0.96',
          zIndex: 1000,
          transition: 'scale 200ms',
          "&:hover": {
              scale: '0.98',
          },
        },
        transition: 'width .3s ease-out',
    },
    error: {
        backgroundColor: '#e53e3e',
        borderRadius: '0.25rem',
        color: '#fff',
        padding: '0.5rem',
    },
    icon: {
        height: 30,
        width: 30,
    },
    name: {
        display: '-webkit-box',
        overflow: 'hidden',
        fontFamily: 'Inter !important',
        fontSize: '1.1rem !important',
        fontWeight: 'bold !important',
        color: 'black',
        textOverflow: 'ellipsis',
        textTransform: 'none !important' as any,
        textAlign: 'left',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: '1',
    },
    url: {
        display: 'inline-block',
        maxWidth: '24ch',
        fontFamily: 'Inter',
        fontSize: '1.1rem',
        fontWeight: 500,
        textDecoration: 'none',
        color: theme.colors.neutral['600'],
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        lineClamp: 1,
        "&:hover": {
            textDecoration: 'none !important',
        },
    },
}));

const ViewPDF: React.FC<{ pdfBytes?: Uint8Array }> = React.memo(({ pdfBytes }) => {
    const classes = useStyles();
    const scrollModePluginInstance = scrollModePlugin();
    const viewerPlugins = [scrollModePluginInstance];

    if (!pdfBytes)
        return (<></>);

    return (<>
        <Viewer fileUrl={pdfBytes}
            defaultScale={SpecialZoomLevel.PageWidth}
            plugins={viewerPlugins as any[]}
            scrollMode={ScrollMode.Vertical}
            renderError={() => (
                <Stack alignItems="center" justifyContent="center" height="100%">
                    <Typography className={classes.error}> {'Error loading the document'} </Typography>
                </Stack>
            )}
        />
    </>);
});

const CompanyMiniDeckViewer: React.FC<{
    open: boolean,
    defaultDeck?: CompanyFile,
    externalDeck?: string,
    loadFromCacheBucket?: boolean,
    onClose?: () => void,
}> = ({ open, defaultDeck, externalDeck, loadFromCacheBucket = false, onClose }) => {
    const classes = useStyles();
    const [pdfBytes, setPdfBytes] = useState<Uint8Array | undefined>(undefined);

    const sanitizeUrl = (originUrl: string) => originUrl.replace(/^https?:\/\//, '').replace(/^www\./, '');

    useEffect(() => {
      if (!!defaultDeck?.key && !pdfBytes) {
          if (loadFromCacheBucket) {
            s3DownloadExternalBucket({ key: defaultDeck?.key, bucket: awsConfig.aws_cache_bucket }).then(setPdfBytes);
          } else {
            s3Download({ key: defaultDeck?.key }).then(setPdfBytes)
              .catch(() => {
                fetch(defaultDeck?.key).then(data => {
                  data.arrayBuffer().then(r => setPdfBytes(new Uint8Array(r)))
                });
              });
          }
      }
    // eslint-disable-next-line
    }, [defaultDeck]);

    if (!defaultDeck)
        return (<></>);

    return (<>
        <Dialog className={classes.dialog} open={open} onClose={() => onClose?.()}>
            <DialogTitle className={classes.dialogTitle}>
                <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
                    <PdfIcon className={classes.icon} />
                    <Typography className={classes.name}>{defaultDeck?.name}</Typography>
                </Stack>
                {!!externalDeck && (
                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end">
                        <WorldGridIcon className={classes.icon} fill="#7bd4d4" />
                        <Typography component="a"
                            className={classes.url}
                            href={externalDeck}
                            target="_blank"
                            rel="noopener noreferrer">
                            {sanitizeUrl(externalDeck)}
                        </Typography>
                    </Stack>
                )}
            </DialogTitle>
            <Divider sx={{ margin: '8px 0'}} />
            <DialogContent className={classes.dialogContent}>
                <ViewPDF pdfBytes={pdfBytes} />
            </DialogContent>
        </Dialog>
    </>);
}

export default CompanyMiniDeckViewer;
