import { Typography } from "@mui/material";
import { AnsweredQuestion } from "../../../contexts/DashboardQueriesContext";
import { NoInfoYet } from "../../../types/search";
import { Fragment, useEffect, useMemo, useState } from "react";

const IntroValidator = /(?::\s*1\.|:1\.|^1\.)/;
const SkipAnswer = ['not', 'do not', 'cannot', `can't`, 'could not', `couldn't`];

const TeamAnswer: React.FC<{
    answeredQuestion: AnsweredQuestion,
    onSkip?: (skip: boolean) => void,
 }> = ({ answeredQuestion, onSkip }) => {
    const [sentences, setSentences] = useState<string[]>([]);

    const isAnswerInvalid = useMemo(() =>
        SkipAnswer.some(skippable => sentences.some(sentence => sentence?.includes(skippable)))
    , [sentences]);

    useEffect(() => {
        if (typeof answeredQuestion.answer === 'string') {
            let localSentences = [];

            if (answeredQuestion.answer.indexOf('\n') !== -1) {
                const answers = answeredQuestion.answer.replace(/\n+/g, '\n').split('\n');

                if (/^(?:\d+\.\s*)/g.test(answers[0]))
                    localSentences.push('');
                answers.forEach((answer) => localSentences.push(
                    ['- **', '-**', '**'].reduce((text, pattern) => text.replaceAll(pattern, ''), answer)
                ));
                setSentences(localSentences);
            } else if (IntroValidator.test(answeredQuestion.answer as string)) {
                const answers = answeredQuestion.answer.split(IntroValidator);

                localSentences.push(`${answers[0] + (!!answers[0] ? ':' : '')}`);
                if (!!answers[1]) {
                    answers[1].split(/(?:^|\.)\s*\d+\./g).forEach((content, index) =>
                        localSentences.push(`${index + 1}.${content + (content.endsWith('.') ? '' : '.')}`)
                    );
                }
                setSentences(localSentences);
            } else {
                setSentences([answeredQuestion.answer]);
            }
        }
    }, [answeredQuestion]);

    useEffect(() => {
        onSkip?.(isAnswerInvalid);
    }, [isAnswerInvalid, onSkip]);

    return (<>
        {!!sentences.length ? (
            sentences?.map((sentence, i) => (!!sentence && (
                <Fragment key={'text-answer-43-' + i}>
                    <Typography fontFamily="Inter">{sentence}</Typography>
                    {(i < sentences.length - 1) && (<Typography>&nbsp;</Typography>)}
                </Fragment>
        )))) : (
            <Typography fontFamily="Inter">{NoInfoYet.answer}</Typography>
        )}
    </>);
}

export default TeamAnswer;