import React, { ReactNode, useEffect, useState } from 'react';
import { Box, Stack } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import classnames from 'classnames';
import moment from 'moment';
import { AnswerQuestionResponse } from '../../../types/search';
import AnswerEditor from '../dashboard-query-answer/AnswerEditor';
import SkeletonLoader from '../../atoms/SkeletonLoader';
import { DashboardQueryAnswer } from '../../../contexts/DashboardQueriesContext';
import AnswerHeaderLink from '../dashboard-query-answer/AnswerHeaderLink';

const useStyles = makeStyles((theme) => ({
    masonryElement: {
        display: 'inline-block',
        width: '100%',
        height: 'auto',
        overflow: 'hidden',
        paddingTop: 16,
        paddingBottom: 16,
        borderRadius: 16,
        border: `2px solid ${theme.colors.primary['100']}`,
        background: theme.colors.primary['50'],
        transition: 'all 300ms ease',
    },
    linkOnly: {
        width: '100%',
        padding: 'unset',
        border: 'unset',
        background: 'transparent',
        "&:hover": {
            border: `unset !important`,
            background: 'transparent !important',
            boxShadow: 'unset !important',
        },
    },
    answerContainer: {
        width: 'auto',
        height: 'auto',
        marginLeft: 8,
        marginRight: 8,
        paddingLeft: 8,
        paddingRight: 8,
        border: `1px transparent solid`,
        borderRadius: 12,
    },
    answerBlock: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        maxHeight: 910,
        overflow: 'hidden'
    },
}));

const MaxWaitLoadingTime = 15;

const LinkedQueryContainer: React.FC<{
    queryAnswer: DashboardQueryAnswer,
    children?: ReactNode,
    isPublic?: boolean,
    linkOnly?: boolean,
    onEditing?: (queryTitle?: string) => void,
    onEdit?: (answer: AnswerQuestionResponse, history?: any) => void,
    onDelete?: () => void,
}> = ({ queryAnswer, children, isPublic, linkOnly, onEditing, onEdit, onDelete }) => {
    const classes = useStyles();
    const [isEditingAnswer, setIsEditingAnswer] = useState<boolean>(false);
    const [editAnswer, setEditAnswer] = useState<string | null >(null);
    const [showAnswer, setShowAnswer] = useState<boolean>(false);

    const answeredQuestion = queryAnswer.answeredQuestion;

    const saveEditAnswer = (answer: string) => {
        onEdit?.({answer: answer, sources: answeredQuestion.sources, type: answeredQuestion.type});
        setIsEditingAnswer(false);
        setEditAnswer(null);
    }

    useEffect(() => {
        if ('answer' in answeredQuestion)
            setShowAnswer((!!answeredQuestion?.answer || !!answeredQuestion?.type)
                || (!!answeredQuestion?.time && moment().diff(answeredQuestion.time, "seconds") > MaxWaitLoadingTime));
        else
            setShowAnswer(true);
    }, [answeredQuestion]);

    useEffect(() => {
        onEditing?.(isEditingAnswer ? queryAnswer.title : undefined);
    // eslint-disable-next-line
    }, [isEditingAnswer]);

    return (<>
        <Box className={classnames(classes.masonryElement, linkOnly && classes.linkOnly)}>
            <AnswerHeaderLink
                answeredQuestion={answeredQuestion}
                isEditingAnswer={isEditingAnswer}
                isPublic={isPublic}
                linkOnly={linkOnly}
                onEditingAnswer={setIsEditingAnswer}
                onEditAnswer={setEditAnswer}
                onDelete={onDelete}
            />
            {isEditingAnswer ? (
                <Box className={classes.answerBlock} px={2}>
                    <AnswerEditor
                        currentAnswer={editAnswer || ''}
                        placeHolder={"Add link..."}
                        rows={1}
                        handleCancel={() => {
                            setIsEditingAnswer(false);
                            setEditAnswer(null);
                        }}
                        handleSave={saveEditAnswer}
                        linkOnly />
                </Box>
            ) : (!!children) && (
                <Stack className={classes.answerContainer}>
                    <Box className={classes.answerBlock}>
                        {showAnswer ? ( children ) : (
                            <SkeletonLoader variant="regular" rowCount={1} />
                        )}
                    </Box>
                </Stack>
            )}
        </Box>
    </>);
}

export default LinkedQueryContainer;
