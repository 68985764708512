import makeStyles from "@mui/styles/makeStyles";
import TextQuery from "../../molecules/dashboard-queries/TextQuery";
import { scrollbarStyle } from "../../../shared/dashboard";
import { Dialog, DialogTitle, Divider, DialogContent, DialogActions, Button } from "@mui/material";
import { useContext } from "react";
import {DashboardContext} from "../../../contexts/DashboardContext";

const useStyles = makeStyles((theme) => ({
    dialog: {
        "& .MuiDialog-paper": {
            minWidth: 700,
            minHeight: '60vh',
            height: 'fit-content',
            padding: '16px 24px',
            borderRadius: 24,
        }
    },
    dialogTitle: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: 'unset',
        color: 'black',
        fontWeight: 'bold',
        fontFamily: 'Inter',
        fontSize: '1.3rem',
    },
    dialogContent: {
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        padding: '8px 0',
        gap: 16,
        overflowY: 'auto',
        ...scrollbarStyle,
    },
    dialogActions: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: 0,
        gap: 8,
    },
    closeButton: {
        minWidth: 80,
        borderRadius: 20,
        background: theme.palette.primary.main,
        color: 'white',
        textTransform: 'none',
        fontWeight: 'bold',
        transition: 'ease-in-out 300ms',
    },
}));

const CompanyQueriesModal: React.FC<{
    isOpen: boolean,
    onClose: () => void,
}> = ({ isOpen, onClose }) => {
    const classes = useStyles();
    const { dashboard } = useContext(DashboardContext);

    return (<>
        <Dialog className={classes.dialog} open={isOpen} onClose={() => onClose()}>
            <DialogTitle className={classes.dialogTitle}>
                {`${dashboard?.title!} Overview`}
            </DialogTitle>
            <Divider sx={{ margin: '8px 0'}} />
            <DialogContent className={classes.dialogContent}>
                <TextQuery queryTitle="Description" />
                <TextQuery queryTitle="Market" />
                <TextQuery queryTitle="Product" />
                <TextQuery queryTitle="Business Model" />
                <TextQuery queryTitle="Go To Market" />
                <TextQuery queryTitle="Possible Risks" />
            </DialogContent>
            <Divider sx={{ margin: '8px 0'}} />
            <DialogActions className={classes.dialogActions}>
                <Button variant="contained" className={classes.closeButton}
                    onClick={() => onClose()}
                > {'Close'} </Button>
            </DialogActions>
        </Dialog>
    </>);
}

export default CompanyQueriesModal;