import { Modal, Box, Typography, Checkbox, CircularProgress, Button, InputAdornment, OutlinedInput, Snackbar } from "@mui/material";
import { FC, useContext, useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import DeleteIcon from '@mui/icons-material/Delete';
import theme from "../../../theme";
import Tag from "../../molecules/dashboard-tags/Tag";
import { updateDashboardFunc } from "../../../lib/helper";
import CloseIcon from '@mui/icons-material/Close';
import { Dashboard } from "../../../types/files";
import ConfirmDialog from "../../modals/ConfirmDialog";
import { GroupSettingsContext } from "../../../contexts/GroupSettingsContext";

const modal = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: '0px 20px 30px -5px rgba(16, 24, 40, 0.06)',
    borderRadius: '8px',
};

const useStyles = makeStyles((theme) => ({
    header: {
        display: 'flex',
        padding: '16px 24px',
        borderBottom: `1px solid ${theme.colors.neutral['200']}`,
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    content: {
        padding: '24px',
        width: '100%',
        maxHeight: '500px',
        overflow: 'auto',
    },
    input: {
        height: 40,
        borderRadius: 8,
        border: `1px solid ${theme.colors.neutral['400']}`,
        '&::placeholder': {
            fontSize: '14px',
            lineHeight: '21.7px',
            fontWeight: 400,
            color: theme.colors.neutral['600']
        }
    },
    buttonContainer: {
        gap: '10px',
        display: 'flex',
        padding: '16px 24px',
        justifyContent: 'flex-end'
    },
    saveBtn: {
        height: '40px',
        width: '88px',
        padding: '8px 24px',
        borderRadius: '40px',
        backgroundColor: theme.colors.primary['600'],
        color: 'white',
        textTransform: 'none',
        transition: 'ease-in-out 300ms',
        '&:hover': {
            backgroundColor: theme.colors.primary['500'],
        }
    },
    cancelBtn: {
        height: '40px',
        width: '88px',
        padding: '8px 24px',
        borderRadius: '40px',
        backgroundColor: theme.colors.neutral['100'],
        color: theme.colors.neutral['500'],
        textTransform: 'none',
        transition: 'ease-in-out 300ms',
    },
    closeIcon: {
        width: '20px',
        height: '20px',
        color: theme.colors.neutral['500'],
        cursor: 'pointer',
        transition: 'ease-in-out 300ms',
        '&:hover': {
            color: theme.colors.neutral['700'],
        }
    },
    deleteIcon: {
        cursor: 'pointer',
        color: theme.colors.neutral['500'],
        transition: 'ease-in-out 300ms',
        '&:hover': {
            color: theme.colors.neutral['700'],
        }
    },
    tagsCheckboxContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: 10.5,
        maxHeight: '500px',
        overflow: 'auto'
    },
    tagsList: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: '16px',
    },
    addTag: {
        color: theme.colors.primary['500'],
        cursor: 'pointer',
        userSelect: 'none',
    }
}))

const DashboardTagModal: FC<{
    open: boolean;
    onClose: () => void;
    dashboard?: Dashboard;
    forSelection?: boolean;
    forManagement?: boolean;
}> = ({ open, onClose, dashboard, forSelection, forManagement }) => {
    const styles = useStyles();
    const { tags, setTags } = useContext(GroupSettingsContext);

    const [selected, setSelected] = useState<string[]>([]);
    const [loading] = useState(false);
    const [newTag, setNewTag] = useState('')
    const [snackIsOpen, setSnackIsOpen] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [dialogOpen, setDialogOpen] = useState(false);
    const [deletableTag, setDeletableTag] = useState('');

    useEffect(() => {
        if (dashboard?.tags?.length) {
            setSelected(dashboard.tags);
        }
    }, [dashboard]);

    const onChange = (checked: boolean, tag: string) => {
        const validTags = tags.filter(q => {
            return selected.map(x => x.toLowerCase()).includes(q.toLowerCase())
        })

        if (validTags.length === 3 && !selected.includes(tag)) {
            setErrorMessage('You may only select up to 3 tags')
            setSnackIsOpen(true)
            return;
        }

        if (checked) {
            setSelected(prev => {
                const current = [...prev, tag];
                return current;
            });
        } else {
            setSelected(prev => {
                const current = prev.filter(t => t !== tag)
                return current;
            });
        }
    }

    const createNewTag = () => {
        if (!newTag) {
            setErrorMessage('Please enter a tag')
            setSnackIsOpen(true)
            return;
        }

        if (newTag.length > 15) {
            setErrorMessage('Tag must not exceed 15 characters');
            setSnackIsOpen(true);
            return;
        }

        setTags([...tags, newTag]);
        setNewTag('');
        setErrorMessage('Successfully created new tag')
        setSnackIsOpen(true)
    }

    const onSave = async () => {
        await updateDashboardFunc({...dashboard, tags: selected,});

        onClose();
    }

    const removeTag = (tag: string) => {
        const newTags = tags.filter(item => item !== tag);
        setTags(newTags);
    }

    return (
        <>
            <Modal
                open={open}
                onClose={(e: { stopPropagation: () => void }) => {
                    e.stopPropagation();
                    onClose();
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modal} onClick={(e) => e.stopPropagation()}>
                    <Box className={styles.header}>
                        <Typography variant="subtitle-bold" >{forManagement ? 'Manage Tags' : forSelection ? 'Add Tags' : 'Tags'}</Typography>
                        <CloseIcon onClick={onClose} className={styles.closeIcon} />
                    </Box>
                    <Box className={styles.content}>
                        {forManagement &&
                            <OutlinedInput
                                type="text"
                                fullWidth
                                placeholder="Create a new tag"
                                className={styles.input}
                                value={newTag}
                                onChange={(e) => setNewTag(e.target.value)}
                                endAdornment={
                                    <InputAdornment position="end" onClick={createNewTag}>
                                        <Typography variant="b3-semibold" className={styles.addTag}>Add</Typography>
                                    </InputAdornment>
                                }
                                size="small"
                            />
                        }
                        <Box sx={{ height: forSelection ? '0px' : '16px' }} />
                        {
                            loading ? (
                                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '8px' }}>
                                    <CircularProgress />
                                </Box>
                            ) : <>
                                {forSelection &&
                                    <Typography variant="b3-semibold" color={theme.colors.neutral['700']}>You can add a maximum of 3 tags per dashboard</Typography>
                                }
                                {
                                    tags.length ? tags.sort((a, b) => a > b ? 1 : -1).map((tag, i) => {
                                        return (
                                            <Box className={styles.tagsList} key={'dashboard-tag-modal-259-' + i}>
                                                <Box className={styles.tagsCheckboxContainer}>
                                                    {forSelection &&
                                                        <Checkbox onChange={(e) => onChange(e.target.checked, tag)} sx={{ p: 0 }} value={tag} checked={selected.includes(tag)} />
                                                    }
                                                   <Tag title={tag} color={theme.colors.neutral['100']} />
                                                </Box>
                                                    {forManagement &&
                                                        <DeleteIcon className={styles.deleteIcon} onClick={() => {
                                                            setDeletableTag(tag);
                                                            setDialogOpen(true)
                                                        }} />
                                                    }
                                            </Box>
                                        )
                                    }) : <></>
                                }
                            </>
                        }
                    </Box>
                    {
                        loading ? (
                            <Box sx={{ display: 'none' }}></Box>
                        ) :
                            <Box className={styles.buttonContainer}>
                                {forManagement &&
                                    <Button className={styles.cancelBtn} onClick={onClose}>
                                        <Typography variant="b2-bold">
                                            Back
                                        </Typography>
                                    </Button>
                                }
                                {forSelection && <>
                                    <Button className={styles.cancelBtn} onClick={onClose}>
                                        <Typography variant="b2-bold">
                                            Cancel
                                        </Typography>
                                    </Button>
                                    <Button className={styles.saveBtn} onClick={async() => await onSave()}>
                                        <Typography variant="b2-bold">
                                            Save
                                        </Typography>
                                    </Button>
                                </>}
                            </Box>
                    }
                </Box>
            </Modal>
            <Snackbar
                open={snackIsOpen}
                autoHideDuration={2000}
                onClose={() => setSnackIsOpen(false)}
                message={errorMessage}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            />
            <ConfirmDialog
                title="Delete confirmation"
                content={`Are you sure you want to delete this ${deletableTag} tag?`}
                open={dialogOpen}
                confirmCallback={() => {
                    removeTag(deletableTag);
                    setDeletableTag('');
                    setDialogOpen(false)
                    setErrorMessage('Tag successfully deleted')
                    setSnackIsOpen(true)
                }}
                cancelCallback={() => setDialogOpen(false)}
            />
        </>
    )
};

export default DashboardTagModal;