/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getFiles = /* GraphQL */ `query GetFiles(
  $group: String
  $dashboardId: String
  $createdAfter: AWSDateTime
  $nextToken: String
  $limit: Int
) {
  getFiles(
    group: $group
    dashboardId: $dashboardId
    createdAfter: $createdAfter
    nextToken: $nextToken
    limit: $limit
  ) {
    nextToken
    items {
      id
      documentCategory
      key
      displayPath
      status
      name
      group
      project
      dashboardId
      marketMetrics {
        page
        pageKey
        title
      }
      isDeck
      createdAt
      updatedAt
    }
  }
}
` as GeneratedQuery<APITypes.GetFilesQueryVariables, APITypes.GetFilesQuery>;
export const getPublicFilesForDashboard = /* GraphQL */ `query GetPublicFilesForDashboard($dashboardId: String) {
  getPublicFilesForDashboard(dashboardId: $dashboardId) {
    id
    documentCategory
    key
    displayPath
    status
    name
    group
    project
    dashboardId
    marketMetrics {
      page
      pageKey
      title
    }
    isDeck
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetPublicFilesForDashboardQueryVariables,
  APITypes.GetPublicFilesForDashboardQuery
>;
export const getActions = /* GraphQL */ `query GetActions($group: String!, $nextToken: String, $limit: Int) {
  getActions(group: $group, nextToken: $nextToken, limit: $limit) {
    nextToken
    items {
      id
      uploadName
      uploadType
      uploadSource
      group
      project
      createdAt
      updatedAt
    }
  }
}
` as GeneratedQuery<
  APITypes.GetActionsQueryVariables,
  APITypes.GetActionsQuery
>;
export const getUserComments = /* GraphQL */ `query GetUserComments($email: String!, $nextToken: String, $limit: Int) {
  getUserComments(email: $email, nextToken: $nextToken, limit: $limit) {
    nextToken
    items {
      id
      parentId
      email
      title
      text
      project
      group
      documentId
      dashboardId
      commentedItem {
        height
        left
        pageIndex
        top
        width
      }
      usersMentioned
      originType
      originUrl
      description
      createdAt
      updatedAt
    }
  }
}
` as GeneratedQuery<
  APITypes.GetUserCommentsQueryVariables,
  APITypes.GetUserCommentsQuery
>;
export const getUserMentions = /* GraphQL */ `query GetUserMentions($email: String!, $nextToken: String, $limit: Int) {
  getUserMentions(email: $email, nextToken: $nextToken, limit: $limit) {
    nextToken
    items {
      id
      email
      emailOfPersonWhoMentioned
      commentId
      originType
      originUrl
      group
      createdAt
      updatedAt
    }
  }
}
` as GeneratedQuery<
  APITypes.GetUserMentionsQueryVariables,
  APITypes.GetUserMentionsQuery
>;
export const getDashboard = /* GraphQL */ `query GetDashboard($id: ID!) {
  getDashboard(id: $id) {
    id
    email
    title
    project
    group
    documents {
      id
      title
      searchTerms
      searchTitle
      pages
      order
      key
      summary
      description
      documentScreenshot
      document
      comment {
        title
        text
      }
    }
    selections {
      id
      title
      document
      page
      order
      key
      summary
      selectedText
      comment {
        title
        text
      }
    }
    screenshots {
      id
      title
      key
      summary
      order
      comment {
        title
        text
      }
      page
      url
    }
    notes {
      id
      title
      data
      type
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
    emailBodies {
      id
      subject
      content
      from
      type
      createdAt
    }
    isPublic
    summary
    createdAt
    updatedAt
    lastUpdatedBy
    status
    investmentStage
    tags
    shouldRefresh
    externalLinks
    refreshData {
      lastUpdatedBy
      shouldRefresh
      modifiedData
    }
    shouldSyncExternally
    workspaces
    workspaceMemberships {
      workspaceId
      updatedAt
    }
    source
    userId
  }
}
` as GeneratedQuery<
  APITypes.GetDashboardQueryVariables,
  APITypes.GetDashboardQuery
>;
export const getPublicDashboard = /* GraphQL */ `query GetPublicDashboard($id: ID!) {
  getPublicDashboard(id: $id) {
    id
    email
    title
    project
    group
    documents {
      id
      title
      searchTerms
      searchTitle
      pages
      order
      key
      summary
      description
      documentScreenshot
      document
      comment {
        title
        text
      }
    }
    selections {
      id
      title
      document
      page
      order
      key
      summary
      selectedText
      comment {
        title
        text
      }
    }
    screenshots {
      id
      title
      key
      summary
      order
      comment {
        title
        text
      }
      page
      url
    }
    notes {
      id
      title
      data
      type
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
    emailBodies {
      id
      subject
      content
      from
      type
      createdAt
    }
    isPublic
    summary
    createdAt
    updatedAt
    lastUpdatedBy
    status
    investmentStage
    tags
    shouldRefresh
    externalLinks
    refreshData {
      lastUpdatedBy
      shouldRefresh
      modifiedData
    }
    shouldSyncExternally
    workspaces
    workspaceMemberships {
      workspaceId
      updatedAt
    }
    source
    userId
  }
}
` as GeneratedQuery<
  APITypes.GetPublicDashboardQueryVariables,
  APITypes.GetPublicDashboardQuery
>;
export const getPublicDashboardComments = /* GraphQL */ `query GetPublicDashboardComments($id: ID!) {
  getPublicDashboardComments(id: $id) {
    id
    parentId
    email
    title
    text
    project
    group
    documentId
    dashboardId
    commentedItem {
      height
      left
      pageIndex
      top
      width
    }
    usersMentioned
    originType
    originUrl
    description
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetPublicDashboardCommentsQueryVariables,
  APITypes.GetPublicDashboardCommentsQuery
>;
export const getPublicDashboardQueries = /* GraphQL */ `query GetPublicDashboardQueries($id: ID!) {
  getPublicDashboardQueries(id: $id) {
    dashboardId
    title
    group
    query
    answer
    order
    columnOverride
    manualOverride
    isRefreshing
    source
    history
    status
    tags
    updatedAt
    deletedAt
  }
}
` as GeneratedQuery<
  APITypes.GetPublicDashboardQueriesQueryVariables,
  APITypes.GetPublicDashboardQueriesQuery
>;
export const getGroupDashboards = /* GraphQL */ `query GetGroupDashboards(
  $group: String
  $title: String
  $nextToken: String
  $limit: Int
) {
  getGroupDashboards(
    group: $group
    title: $title
    nextToken: $nextToken
    limit: $limit
  ) {
    nextToken
    items {
      id
      email
      title
      project
      group
      documents {
        id
        title
        searchTerms
        searchTitle
        pages
        order
        key
        summary
        description
        documentScreenshot
        document
        comment {
          title
          text
        }
      }
      selections {
        id
        title
        document
        page
        order
        key
        summary
        selectedText
        comment {
          title
          text
        }
      }
      screenshots {
        id
        title
        key
        summary
        order
        comment {
          title
          text
        }
        page
        url
      }
      notes {
        id
        title
        data
        type
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
      emailBodies {
        id
        subject
        content
        from
        type
        createdAt
      }
      isPublic
      summary
      createdAt
      updatedAt
      lastUpdatedBy
      status
      investmentStage
      tags
      shouldRefresh
      externalLinks
      refreshData {
        lastUpdatedBy
        shouldRefresh
        modifiedData
      }
      shouldSyncExternally
      workspaces
      workspaceMemberships {
        workspaceId
        updatedAt
      }
      source
      userId
    }
  }
}
` as GeneratedQuery<
  APITypes.GetGroupDashboardsQueryVariables,
  APITypes.GetGroupDashboardsQuery
>;
export const getGroupIntegrations = /* GraphQL */ `query GetGroupIntegrations($group: String!) {
  getGroupIntegrations(group: $group) {
    nextToken
    items {
      group
      integration
      externalConfig
      status
      lastSyncAt
      lastSuccessfulSyncAt
      createdAt
      updatedAt
      syncInProgress
      syncWaiting
    }
  }
}
` as GeneratedQuery<
  APITypes.GetGroupIntegrationsQueryVariables,
  APITypes.GetGroupIntegrationsQuery
>;
export const getUser = /* GraphQL */ `query GetUser($email: String!) {
  getUser(email: $email) {
    email
    firstName
    lastName
    group
    project
    username
    createdAt
  }
}
` as GeneratedQuery<APITypes.GetUserQueryVariables, APITypes.GetUserQuery>;
export const getCurrentUser = /* GraphQL */ `query GetCurrentUser($donotuse: String) {
  getCurrentUser(donotuse: $donotuse) {
    email
    firstName
    lastName
    group
    project
    username
    createdAt
  }
}
` as GeneratedQuery<
  APITypes.GetCurrentUserQueryVariables,
  APITypes.GetCurrentUserQuery
>;
export const getUsers = /* GraphQL */ `query GetUsers($group: String!, $nextToken: String, $limit: Int) {
  getUsers(group: $group, nextToken: $nextToken, limit: $limit) {
    nextToken
    items {
      email
      firstName
      lastName
      group
      project
      username
      createdAt
    }
  }
}
` as GeneratedQuery<APITypes.GetUsersQueryVariables, APITypes.GetUsersQuery>;
export const getDocumentComments = /* GraphQL */ `query GetDocumentComments($documentId: String!) {
  getDocumentComments(documentId: $documentId) {
    id
    parentId
    email
    title
    text
    project
    group
    documentId
    dashboardId
    commentedItem {
      height
      left
      pageIndex
      top
      width
    }
    usersMentioned
    originType
    originUrl
    description
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetDocumentCommentsQueryVariables,
  APITypes.GetDocumentCommentsQuery
>;
export const getDashboardComments = /* GraphQL */ `query GetDashboardComments($id: ID!) {
  getDashboardComments(id: $id) {
    id
    parentId
    email
    title
    text
    project
    group
    documentId
    dashboardId
    commentedItem {
      height
      left
      pageIndex
      top
      width
    }
    usersMentioned
    originType
    originUrl
    description
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetDashboardCommentsQueryVariables,
  APITypes.GetDashboardCommentsQuery
>;
export const getGroupComments = /* GraphQL */ `query GetGroupComments($group: String!, $nextToken: String, $limit: Int) {
  getGroupComments(group: $group, nextToken: $nextToken, limit: $limit) {
    nextToken
    items {
      id
      parentId
      email
      title
      text
      project
      group
      documentId
      dashboardId
      commentedItem {
        height
        left
        pageIndex
        top
        width
      }
      usersMentioned
      originType
      originUrl
      description
      createdAt
      updatedAt
    }
  }
}
` as GeneratedQuery<
  APITypes.GetGroupCommentsQueryVariables,
  APITypes.GetGroupCommentsQuery
>;
export const getCommentMentions = /* GraphQL */ `query GetCommentMentions($commentId: String!) {
  getCommentMentions(commentId: $commentId) {
    id
    email
    emailOfPersonWhoMentioned
    commentId
    originType
    originUrl
    group
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetCommentMentionsQueryVariables,
  APITypes.GetCommentMentionsQuery
>;
export const listIntegrationSubfolders = /* GraphQL */ `query ListIntegrationSubfolders(
  $group: String!
  $integration: String!
  $path: String
  $parentId: String
) {
  listIntegrationSubfolders(
    group: $group
    integration: $integration
    path: $path
    parentId: $parentId
  ) {
    documentCategory
    id
    isDirectory
    name
    parentId
    path
    key
  }
}
` as GeneratedQuery<
  APITypes.ListIntegrationSubfoldersQueryVariables,
  APITypes.ListIntegrationSubfoldersQuery
>;
export const batchGetFileChunk = /* GraphQL */ `query BatchGetFileChunk($ids: [ID!], $group: String!) {
  batchGetFileChunk(ids: $ids, group: $group) {
    id
    searchGroup
    fileId
    text
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.BatchGetFileChunkQueryVariables,
  APITypes.BatchGetFileChunkQuery
>;
export const listFileChunks = /* GraphQL */ `query ListFileChunks(
  $group: String!
  $fileId: String!
  $nextToken: String
  $limit: Int
) {
  listFileChunks(
    group: $group
    fileId: $fileId
    nextToken: $nextToken
    limit: $limit
  ) {
    nextToken
    items {
      id
      searchGroup
      fileId
      text
      createdAt
      updatedAt
    }
  }
}
` as GeneratedQuery<
  APITypes.ListFileChunksQueryVariables,
  APITypes.ListFileChunksQuery
>;
export const answerQuestion = /* GraphQL */ `query AnswerQuestion(
  $group: String!
  $question: String!
  $status: String
  $tags: [String]
) {
  answerQuestion(
    group: $group
    question: $question
    status: $status
    tags: $tags
  ) {
    answer
    filename
    page
    sources {
      filename
      page
    }
  }
}
` as GeneratedQuery<
  APITypes.AnswerQuestionQueryVariables,
  APITypes.AnswerQuestionQuery
>;
export const getGroupSettings = /* GraphQL */ `query GetGroupSettings($group: String) {
  getGroupSettings(group: $group) {
    group
    statuses
    investmentStages
    tags
    workspaces {
      id
      name
      email
      website
      isPublic
      investmentThesis {
        focusArea {
          tags
        }
        stage {
          stages
        }
        geography {
          regions
        }
        additionalDetails {
          freeText
        }
      }
      createdAt
      updatedAt
    }
    shouldSyncAllDashboardsExternally
    shouldIngestDashboardsFromEmail
    shouldSendDigestEmail
  }
}
` as GeneratedQuery<
  APITypes.GetGroupSettingsQueryVariables,
  APITypes.GetGroupSettingsQuery
>;
export const getAllGroupSettings = /* GraphQL */ `query GetAllGroupSettings($nextToken: String, $limit: Int) {
  getAllGroupSettings(nextToken: $nextToken, limit: $limit) {
    nextToken
    items {
      group
      statuses
      investmentStages
      tags
      workspaces {
        id
        name
        email
        website
        isPublic
        investmentThesis {
          focusArea {
            tags
          }
          stage {
            stages
          }
          geography {
            regions
          }
          additionalDetails {
            freeText
          }
        }
        createdAt
        updatedAt
      }
      shouldSyncAllDashboardsExternally
      shouldIngestDashboardsFromEmail
      shouldSendDigestEmail
    }
  }
}
` as GeneratedQuery<
  APITypes.GetAllGroupSettingsQueryVariables,
  APITypes.GetAllGroupSettingsQuery
>;
export const getDashboardQueries = /* GraphQL */ `query GetDashboardQueries(
  $dashboardId: String
  $group: String
  $order: Int
  $limit: Int
  $nextToken: String
) {
  getDashboardQueries(
    dashboardId: $dashboardId
    group: $group
    order: $order
    limit: $limit
    nextToken: $nextToken
  ) {
    nextToken
    items {
      dashboardId
      title
      group
      query
      answer
      order
      columnOverride
      manualOverride
      isRefreshing
      source
      history
      status
      tags
      updatedAt
      deletedAt
    }
  }
}
` as GeneratedQuery<
  APITypes.GetDashboardQueriesQueryVariables,
  APITypes.GetDashboardQueriesQuery
>;
export const listCrunchbaseCompanies = /* GraphQL */ `query ListCrunchbaseCompanies($dashboardName: String!) {
  listCrunchbaseCompanies(dashboardName: $dashboardName) {
    permalink
    name
    description
  }
}
` as GeneratedQuery<
  APITypes.ListCrunchbaseCompaniesQueryVariables,
  APITypes.ListCrunchbaseCompaniesQuery
>;
export const getCrunchbaseEntity = /* GraphQL */ `query GetCrunchbaseEntity($permalink: String!, $dashboardName: String) {
  getCrunchbaseEntity(permalink: $permalink, dashboardName: $dashboardName) {
    name
    website
    linkedin
    location
    shortDescription
  }
}
` as GeneratedQuery<
  APITypes.GetCrunchbaseEntityQueryVariables,
  APITypes.GetCrunchbaseEntityQuery
>;
export const getActivityFeedEvents = /* GraphQL */ `query GetActivityFeedEvents($group: String!, $nextToken: String, $limit: Int) {
  getActivityFeedEvents(group: $group, nextToken: $nextToken, limit: $limit) {
    nextToken
    items {
      id
      dashboardId
      group
      eventType
      events
      eventAt
      createdAt
    }
  }
}
` as GeneratedQuery<
  APITypes.GetActivityFeedEventsQueryVariables,
  APITypes.GetActivityFeedEventsQuery
>;
export const getLinkedin = /* GraphQL */ `query GetLinkedin($url: String!) {
  getLinkedin(url: $url) {
    url
    data
    createdAt
  }
}
` as GeneratedQuery<
  APITypes.GetLinkedinQueryVariables,
  APITypes.GetLinkedinQuery
>;
export const getSimilarCompany = /* GraphQL */ `query GetSimilarCompany($name: String!) {
  getSimilarCompany(name: $name) {
    displayName
    website
    description
    market
    investors
    product
    linkedinUrl
    location
    headcount
    founded
    funding
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetSimilarCompanyQueryVariables,
  APITypes.GetSimilarCompanyQuery
>;
export const getGroupActiveSubscription = /* GraphQL */ `query GetGroupActiveSubscription($group: String!) {
  getGroupActiveSubscription(group: $group) {
    id
    group
    user
    status
    seatCount
    pricePerSeatCents
    planId
    planName
    paymentMethodId
    startedAt
    endedAt
    currentPeriodStartedAt
    currentPeriodEndedAt
    trialStartedAt
    trialEndedAt
    cancelAt
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetGroupActiveSubscriptionQueryVariables,
  APITypes.GetGroupActiveSubscriptionQuery
>;
export const getGroupSubscriptions = /* GraphQL */ `query GetGroupSubscriptions($group: String!) {
  getGroupSubscriptions(group: $group) {
    nextToken
    items {
      id
      group
      user
      status
      seatCount
      pricePerSeatCents
      planId
      planName
      paymentMethodId
      startedAt
      endedAt
      currentPeriodStartedAt
      currentPeriodEndedAt
      trialStartedAt
      trialEndedAt
      cancelAt
      createdAt
      updatedAt
    }
  }
}
` as GeneratedQuery<
  APITypes.GetGroupSubscriptionsQueryVariables,
  APITypes.GetGroupSubscriptionsQuery
>;
export const getUsage = /* GraphQL */ `query GetUsage($group: String!) {
  getUsage(group: $group) {
    group
    dashboardsCreated
    resetsAt
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<APITypes.GetUsageQueryVariables, APITypes.GetUsageQuery>;
export const getUnassignedContentItems = /* GraphQL */ `query GetUnassignedContentItems($group: String!) {
  getUnassignedContentItems(group: $group) {
    id
    group
    type
    key
    subject
    body
    attachments
    potentialCompanies
    from
    createdAt
  }
}
` as GeneratedQuery<
  APITypes.GetUnassignedContentItemsQueryVariables,
  APITypes.GetUnassignedContentItemsQuery
>;
export const getUnassignedContentItem = /* GraphQL */ `query GetUnassignedContentItem($id: ID!) {
  getUnassignedContentItem(id: $id) {
    id
    group
    type
    key
    subject
    body
    attachments
    potentialCompanies
    from
    createdAt
  }
}
` as GeneratedQuery<
  APITypes.GetUnassignedContentItemQueryVariables,
  APITypes.GetUnassignedContentItemQuery
>;
export const getPublicWorkspace = /* GraphQL */ `query GetPublicWorkspace($id: ID!, $group: String!) {
  getPublicWorkspace(id: $id, group: $group) {
    workspace {
      id
      name
      email
      website
      isPublic
      investmentThesis {
        focusArea {
          tags
        }
        stage {
          stages
        }
        geography {
          regions
        }
        additionalDetails {
          freeText
        }
      }
      createdAt
      updatedAt
    }
    dashboards {
      id
      email
      title
      project
      group
      documents {
        id
        title
        searchTerms
        searchTitle
        pages
        order
        key
        summary
        description
        documentScreenshot
        document
        comment {
          title
          text
        }
      }
      selections {
        id
        title
        document
        page
        order
        key
        summary
        selectedText
        comment {
          title
          text
        }
      }
      screenshots {
        id
        title
        key
        summary
        order
        comment {
          title
          text
        }
        page
        url
      }
      notes {
        id
        title
        data
        type
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
      emailBodies {
        id
        subject
        content
        from
        type
        createdAt
      }
      isPublic
      summary
      createdAt
      updatedAt
      lastUpdatedBy
      status
      investmentStage
      tags
      shouldRefresh
      externalLinks
      refreshData {
        lastUpdatedBy
        shouldRefresh
        modifiedData
      }
      shouldSyncExternally
      workspaces
      workspaceMemberships {
        workspaceId
        updatedAt
      }
      source
      userId
    }
  }
}
` as GeneratedQuery<
  APITypes.GetPublicWorkspaceQueryVariables,
  APITypes.GetPublicWorkspaceQuery
>;
export const getUrlDestination = /* GraphQL */ `query GetUrlDestination($url: String!) {
  getUrlDestination(url: $url)
}
` as GeneratedQuery<
  APITypes.GetUrlDestinationQueryVariables,
  APITypes.GetUrlDestinationQuery
>;
