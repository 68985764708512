import { Button, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { ReactElement } from "react-markdown/lib/react-markdown";

const useStyles = makeStyles((theme) => ({
  text: {
    width: 280,
    fontFamily: 'Inter !important',
    fontSize: '1rem !important',
    fontWeight: '400 !important',
    lineHeight: '1.6 !important',
    color: `${theme.colors.neutral['900']} !important`,
  },
  commonButton: {
    width: 100,
    height: 30,
    borderRadius: '20px !important',
    background: `${theme.palette.primary.main} !important`,
    color: 'white !important',
    textTransform: 'none !important' as any,
    fontFamily: 'Inter !important',
    fontSize: '0.8rem !important',
    fontWeight: '500 !important',
    transition: 'ease-in-out 300ms !important',
    "&:hover": {
      background: `${theme.colors.primary['800']} !important`,
    },
  },
  cancelButton: {
    width: 100,
    height: 30,
    borderRadius: '20px !important',
    background: `${theme.colors.neutral['100']} !important`,
    color: `${theme.colors.neutral['600']} !important`,
    textTransform: 'none !important' as any,
    fontFamily: 'Inter !important',
    fontSize: '0.8rem !important',
    fontWeight: '500 !important',
    transition: 'ease-in-out 300ms !important',
    "&:hover": {
      background: `${theme.colors.neutral['50']} !important`,
    },
  },
}));

const IntegrationErrorSnackbar: React.FC<{
  Icon: ReactElement,
  message: string,
  onResolve: () => void,
  onClose: () => void,
}> = ({ Icon, message, onResolve, onClose }) => {
  const classes = useStyles();

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <Stack alignItems="flex-start" width="auto" height="100%"> {Icon} </Stack>
      <Typography className={classes.text}> {message} </Typography>
      <Stack direction="column" spacing={1} alignItems="flex-start" justifyContent="flex-end">
        <Button variant="contained"
          className={classes.commonButton}
          onClick={onResolve}> {'Resolve'} </Button>
        <Button variant="contained"
          className={classes.cancelButton}
          onClick={onClose}> {'Close'} </Button>
      </Stack>
    </Stack>
  );
};

export default IntegrationErrorSnackbar;
