import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Stack } from "@mui/material";
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { AnswerQuestionResponse, AnswerQuestionResponseType, MetricData, NoteMetric } from '../../../types/search';
import MetricComponentAnswer from './MetricComponentAnswer';
import { AnsweredQuestion } from '../../../contexts/DashboardQueriesContext';

const useStyles = makeStyles((theme) => ({
    masonryElement: {
        display: 'inline-block',
        width: '100%',
        height: 'auto',
        overflow: 'hidden',
        transition: 'all 0.5s ease',
    },
}));

export const RevenueSubcategories = ['MRR', 'ARR', 'Gross Revenue', 'Net Revenue'];
export const DollarMetrics = ['Fundraising', 'Valuation', 'Revenue'];
export const PercentMetrics = ['Retention', 'Margin'];
export const Units = new Map([['#', 'Number'], ['$', 'Dollar'], ['%', 'Percent']]);
export const DateFmt = 'MMM YYYY';

const MetricsAnswer: React.FC<{
    answeredQuestion: AnsweredQuestion,
    roundDetails?: boolean,
    readOnly?: boolean,
    onUpdate?: (answer: AnswerQuestionResponse) => void,
}> = ({ answeredQuestion, roundDetails, readOnly, onUpdate }) => {
    const classes = useStyles();
    const [metricsHistory, setMetricsHistory] = useState<NoteMetric[]>([]);
    const [metricsHistoryList, setMetricsHistoryList] = useState<{ metric: string, history: MetricData[], }[]>([]);

    const metricsCategory = useMemo(() => {
        let newCategories: string[] = [];

        if (roundDetails) {
            newCategories.push(...['Fundraising', 'Valuation']);
        } else {
            const filteredMetrics = metricsHistoryList.filter(({history}) => !!history?.some(({value}) => !!value));
            const metrics = filteredMetrics.map(metrics => metrics.metric);
            const sanitizedMetrics = metrics.map(metric => metric.trim().toLowerCase());

            newCategories.push('Revenue');
            newCategories.push(...PercentMetrics.filter(metric => sanitizedMetrics.includes(metric.trim().toLowerCase())));
            newCategories.push(...metrics.filter(metric => ![...DollarMetrics, ...PercentMetrics].includes(metric)));
            newCategories = [...newCategories].filter(metric => {
                const history = metricsHistoryList.find(metrics => metrics.metric === metric)?.history?.[0];

                return !(['Revenue', ...RevenueSubcategories].join(',').includes(metric) && !history?.value);
            });
        }

        return newCategories;
    }, [metricsHistoryList, roundDetails]);

    const handleDeleteMetric = useCallback((category: string) => {
        let updatedMetrics: any = {};
        let updatedHistory: any[] = [];

        metricsHistoryList.forEach(({metric, history}) => {
            if (metric !== category)
                updatedMetrics[metric] = {...history[0]};
        });

        metricsHistory.map((history: any) => history?.answer ?? history)
            ?.forEach((metrics: NoteMetric) => {
                let newMetrics: any = {};

                Object.entries(metrics).forEach(([metric, values]) => {
                    if (metric !== category)
                        newMetrics[metric] = values;
                });
                updatedHistory.push({answer: newMetrics, type: AnswerQuestionResponseType.METRICS} as AnswerQuestionResponse);
            });

        onUpdate?.({
            answer: updatedMetrics,
            history: updatedHistory,
            type: AnswerQuestionResponseType.METRICS,
        } as AnswerQuestionResponse);
    // eslint-disable-next-line
    }, [metricsHistory, metricsHistoryList]);

    useEffect(() => {
        const historyRecord = answeredQuestion.history?.map((historyAnswer: any) => historyAnswer?.answer ?? historyAnswer) ?? [];
        const historyList = Object.entries(answeredQuestion.answer ?? {})
            .map(([metric, value]) => {
                const filteredHistory = historyRecord
                    .map(history => history[metric as keyof NoteMetric])
                    .filter(values => !!values && Object.values(values).every(value => !!value));
                const sortedHistory = [{...value as MetricData}, ...filteredHistory as MetricData[]]
                    .sort((prev, next) => moment(prev.date).isAfter(moment(next.date)) ? -1 : 1);

                return ({ metric, history: sortedHistory });
            });

        const fundraisingHistory = historyList.find(metrics => metrics.metric === 'Fundraising')?.history;
        const valuationHistory = historyList.find(metrics => metrics.metric === 'Valuation')?.history;
        const revenueHistory = historyList.find(metrics => metrics.metric === 'Revenue')?.history;
        const subRevenueHistories = historyList.filter(metrics =>
            RevenueSubcategories.map(cat => cat.toLowerCase()).includes(metrics.metric.trim().toLowerCase()))
                ?.map(metrics => ({ metric: metrics.metric, history: metrics.history }));
        const acvHistory = historyList.find(metrics => metrics.metric === 'ACV')?.history;
        const nullMetrics: {metric: string, history: MetricData}[] = [];

        if (Array.isArray(fundraisingHistory) && Array.isArray(valuationHistory)) {
            if (moment(fundraisingHistory?.[0]?.date).format(DateFmt) === moment(valuationHistory?.[0]?.date).format(DateFmt)
                && fundraisingHistory?.[0]?.units === valuationHistory?.[0]?.units
                && fundraisingHistory?.[0]?.value === valuationHistory?.[0]?.value)
                nullMetrics.push({ metric: 'Valuation', history: valuationHistory?.[0] });
        }

        if (Array.isArray(revenueHistory)) {
            let sameRevenue: string[] = [];
            let sameSubRevenue: string[] = [];

            subRevenueHistories?.forEach(({metric, history}, mainIndex, self) => {
                if (moment(revenueHistory?.[0]?.date).format(DateFmt) === moment(history?.[0]?.date).format(DateFmt)
                    && revenueHistory?.[0]?.units === history?.[0]?.units
                    && revenueHistory?.[0]?.value === history?.[0]?.value)
                    sameRevenue.push(metric);

                const matchingMetrics = self.filter(({history: otherHistory}, otherIndex) =>
                        otherIndex !== mainIndex && history?.[0]?.value === otherHistory?.[0]?.value
                    ).map(({metric}) => metric);

                if (matchingMetrics.length > 0)
                    sameSubRevenue = Array.from(new Set([...sameSubRevenue, metric, ...matchingMetrics]));
            });

            if (sameRevenue.length === 1) {
                nullMetrics.push({ metric: 'Revenue', history: revenueHistory?.[0] });
            } else if (sameRevenue.length > 1 || !!sameSubRevenue.length) {
                subRevenueHistories?.forEach(({metric, history}) => {
                    if ([...sameRevenue, ...sameSubRevenue].join(',').includes(metric))
                        nullMetrics.push({ metric, history: history?.[0] });
                });
            }

            acvHistory?.forEach(acv => {
                if (moment(revenueHistory?.[0]?.date).format(DateFmt) === moment(acv.date).format(DateFmt)
                    && revenueHistory?.[0]?.units === acv.units
                    && revenueHistory?.[0]?.value === acv.value)
                    nullMetrics.push({ metric: 'ACV', history: acv });
            });
        }

        nullMetrics.forEach(metricsData => { metricsData.history.value = null });

        setMetricsHistory(historyRecord);
        setMetricsHistoryList(historyList);
    }, [answeredQuestion.history, answeredQuestion.answer]);

    if (!metricsCategory.length)
        return (<></>);

    return (<>
        <Stack direction="column" spacing={2} alignItems="flex-start" justifyContent="center" width="100%">
            {metricsCategory.map((metric, i)=> (
                <Box className={classes.masonryElement} key={'metrics-answer-161-' + i}>
                    <MetricComponentAnswer
                        metric={metric}
                        history={metricsHistoryList.find(metrics => metrics.metric === metric)?.history?.[0]}
                        onDeleteMetric={handleDeleteMetric}
                        readOnly={readOnly} />
                </Box>
            ))}
        </Stack>
    </>);
}

export default MetricsAnswer;
