import React, { useState } from "react";
import useDashboardQuery from "../../../hooks/useDashboardQuery";
import CompanyQueriesModal from "../../modals/dashboard-queries/CompanyQueriesModal";
import DigestAnswer from "../dashboard-query-answer/DigestAnswer";
import EmptyQueryContainer from "../dashboard-query-container/EmptyQueryContainer";

const DigestQuery: React.FC<{}> = () => {
    const { queryAnswer } = useDashboardQuery('Digest');
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    return (<>
        <EmptyQueryContainer queryAnswer={queryAnswer} contentOnly>
            <DigestAnswer answeredQuestion={queryAnswer.answeredQuestion} onModal={setModalOpen} />
        </EmptyQueryContainer>
        {modalOpen && (
            <CompanyQueriesModal isOpen={modalOpen} onClose={() => setModalOpen(false)} />
        )}
    </>);
}

export default DigestQuery;
