import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Box, IconButton, Stack, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import classnames from 'classnames';

import AnswerEditor from '../dashboard-query-answer/AnswerEditor';
import { scrollbarStyle } from '../../../shared/dashboard';
import { Dashboard } from '../../../types/files';
import {DashboardContext} from '../../../contexts/DashboardContext';
import useDashboard from '../../../hooks/useDashboard';

const useStyles = makeStyles((theme) => ({
    masonryElement: {
        display: 'inline-block',
        width: '100%',
        height: 'auto',
        overflow: 'hidden',
        padding: 'unset',
        border: 'unset',
        background: 'transparent',
        transition: 'all 300ms ease',
        "&:hover": {
            border: `unset !important`,
            background: 'transparent !important',
            boxShadow: 'unset !important',
        },
    },
    answerContainer: {
        width: 'auto',
        height: 'auto',
        marginLeft: 4,
        paddingLeft: 4,
        paddingRight: 16,
        border: `1px transparent solid`,
        borderRadius: 12,
    },
    answerBlock: {
        width: '100%',
        height: '48px',
        paddingTop: 8,
        paddingBottom: 8,
        color: '#666666',
        overflowY: 'auto',
        ...scrollbarStyle,
    },
    answerHover: {
        width: '96%',
        marginRight: 2,
        '&:hover': {
            border: `1px ${theme.colors.neutral['400']} solid`,
            borderRadius: 12,
        }
    },
    title: {
        fontFamily: 'Inter',
        fontSize: '1.1rem',
        fontWeight: 'bold',
        color: theme.palette.primary.main,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        marginLeft: 8,
    },
    deal: {
        display: 'inline-block',
        color: '#666666',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        lineClamp: 1,
        maxWidth: '22ch',
    },
    editBlock: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        maxHeight: 910,
        overflow: 'hidden',
    },
    icon: {
        width: 24,
        height: 24,
        fill: '#7bd4d4',
    },
}));

const DealSource: React.FC<{ dashboard: Dashboard }> = ({ dashboard }) => {
    const classes = useStyles();
    const { isPublicView } = useContext(DashboardContext);
    const { updateSource } = useDashboard();
    const [isEditingAnswer, setIsEditingAnswer] = useState<boolean>(false);
    const [editAnswer, setEditAnswer] = useState<string | undefined >(undefined);
    const [hoverAnswer, setHoverAnswer] = useState<boolean>(false);

    const saveEditAnswer = useCallback(async (answer: string) => {
        updateSource(answer);
        setIsEditingAnswer(false);
        setEditAnswer(answer);
    }, [updateSource]);

    useEffect(() => {
        if (!!dashboard.source)
            setEditAnswer(dashboard.source);
    }, [dashboard.source]);

    return (<>
        <Box className={classes.masonryElement}>
            <Stack direction="column" alignItems="flex-start" width="100%">
                <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                    <Typography className={classes.title}>{'Deal Source'}</Typography>
                </Stack>
                {!isEditingAnswer && (
                    <Stack direction="row" justifyContent="space-between"
                        className={classnames(classes.answerContainer, hoverAnswer && classes.answerHover)}
                        onMouseLeave={() => setHoverAnswer(false)}>
                            <Stack className={classes.answerBlock}
                                onMouseEnter={() => setHoverAnswer(!isPublicView)}
                                style={{ height: 'auto' }}>
                                <Typography className={classes.deal}>
                                    {editAnswer || 'N/A'}
                                </Typography>
                            </Stack>
                        <Stack direction="column" alignItems="center" justifyContent="center" width="40px">
                            {hoverAnswer && (
                                <IconButton size="small"
                                    className={classes.icon}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setIsEditingAnswer(true);
                                    }}
                                > <EditOutlinedIcon fontSize="small" /> </IconButton>
                            )}
                        </Stack>
                    </Stack>
                )}
            </Stack>
            {isEditingAnswer && (
                <Box className={classes.editBlock} px={1}>
                    <AnswerEditor
                        currentAnswer={editAnswer || ''}
                        placeHolder={"Deal source..."}
                        rows={2}
                        handleCancel={() => {
                            setIsEditingAnswer(false);
                            setEditAnswer(undefined);
                        }}
                        handleSave={saveEditAnswer} />
                </Box>
            )}
        </Box>
    </>);
}

export default DealSource;
