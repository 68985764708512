import { Box, Divider, IconButton, Stack, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Dashboard, EmailBody, UnassignedContent, UnassignedContentType } from '../../../types/files';
import UnassignedEmailBody from '../unassigned-contents/UnassignedEmailBody';
import moment from 'moment';
import theme from '../../../theme';
import { useCallback, useState } from 'react';
import {ReactComponent as CallIcon} from '../../../assets/icons/call.svg';
import {ReactComponent as DashboardIcon } from "../../../assets/icons/dashboard-outlined.svg";
import {ReactComponent as FolderIcon} from '../../../assets/icons/folder.svg';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EmailIcon from '@mui/icons-material/MailOutlineRounded';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const useStyles = makeStyles((theme) => ({
    contentIcon: {
        width: 32,
        height: 32,
    },
    title: {
        display: '-webkit-box',
        width: 'auto',
        fontFamily: 'Inter',
        fontSize: '0.9rem',
        fontWeight: 500,
        color: theme.colors.neutral['700'],
        overflow: 'hidden',
        WebkitLineClamp: 1,
        WebkitBoxOrient: 'vertical',
        textOverflow: 'ellipsis',
        textTransform: 'none',
        maxWidth: '32ch',
    },
    dashboard: {
        fontFamily: 'Inter',
        fontSize: '1rem',
        fontWeight: 'bold',
        color: theme.palette.primary.main,
    },
    subject: {
        display: '-webkit-box',
        width: 'fit-content',
        fontFamily: 'Inter',
        fontSize: '0.85rem',
        color: theme.colors.neutral['500'],
        overflow: 'hidden',
        WebkitLineClamp: 1,
        WebkitBoxOrient: 'vertical',
        textOverflow: 'ellipsis',
        textTransform: 'capitalize',
        maxWidth: '40ch',
        wordBreak: 'break-all',
    },
    subtitle: {
        fontFamily: 'Inter',
        fontSize: '0.85rem',
        fontWeight: 500,
        color: theme.colors.neutral['700'],
    },
    addTo: {
        paddingLeft: 16,
        paddingRight: 8,
        fontSize: '1rem',
        fontFamily: 'Inter',
        fontWeight: 'bold',
        color: theme.colors.neutral['600'],
    },
    stageChip: {
        height: 30,
        width: 100,
        fontFamily: 'Inter',
        fontSize: '0.85rem',
        fontWeight: 500,
        backgroundColor: '#E0F1F1',
        color: theme.palette.primary.main,
    },
    lastUpdatedDate: {
        color: theme.colors.neutral['600'],
        fontSize: '0.75rem !important',
        whiteSpace: 'nowrap',
    },
    label: {
        color: theme.colors.neutral['600'],
        fontSize: '0.8rem',
        whiteSpace: 'nowrap',
    },
    ownership: {
        fontFamily: 'Inter',
        fontSize: '0.85rem',
        color: theme.colors.neutral['500'],
        marginBottom: '-8px !important',
    },
    owner: {
        fontFamily: 'Inter',
        fontSize: '0.85rem',
        fontWeight: 500,
        color: theme.palette.primary.light,
    },
    icon: {
        width: 100,
        height: 'auto',
        padding: 'unset',
        color: theme.colors.neutral['500'],
    },
    seeEmail: {
        fontFamily: 'Inter',
        fontSize: '0.75rem',
        color: theme.colors.neutral['500'],
    }
}));

const ContentActivityFeed: React.FC<{
    activity: { dashboard: Dashboard, emailBody?: EmailBody, createdAt: string, type: 'attached' | 'created' },
    attached?: boolean,
    vertical?: boolean,
}> = ({ activity, attached, vertical }) => {
    const classes = useStyles();
    const [isExpanded, setExpanded] = useState<boolean>(false);

    const { dashboard, emailBody, createdAt, type } = activity;

    const getIcon = useCallback((type: UnassignedContentType) => {
        switch (type) {
            case UnassignedContentType.EMAIL:
            case UnassignedContentType.FULL_EMAIL:
                return <EmailIcon className={classes.contentIcon} sx={{ fill: '#7bd4d4 !important'}} />;
            case UnassignedContentType.CALL:
                return <CallIcon className={classes.contentIcon} stroke="#7bd4d4" />;
            case UnassignedContentType.DOCSEND:
                return  <DashboardIcon className={classes.contentIcon} fill={theme.palette.primary.main} />;
            case UnassignedContentType.OTHER:
            default:
                return <FolderIcon className={classes.contentIcon} fill="#7bd4d4" />;
        }
        // eslint-disable-next-line
    }, []);

    const getContent = useCallback((content?: UnassignedContent) => {
        switch (content?.type) {
            case UnassignedContentType.EMAIL:
            case UnassignedContentType.FULL_EMAIL:
                return 'Email';
            case UnassignedContentType.CALL:
                return 'Call';
            case UnassignedContentType.DOCSEND:
                return 'Docsend';
            case UnassignedContentType.OTHER:
            default:
                return 'Other content';
        }
        // eslint-disable-next-line
    }, []);

    return (<>
        <Stack direction="column" spacing={1} width="100%" pl={1} pb={1}>
            {!vertical && (<Divider sx={{ width: '100%' }} />)}
            {(type === 'created') ? (<>
                <Stack direction="row" alignItems="flex-start" width="100%">
                    <Box width="40px" mt="4px">
                        <AddCircleOutlineIcon className={classes.contentIcon} sx={{ fill: theme.palette.primary.light }} />
                    </Box>
                    <Stack direction="column" spacing={1} alignItems="flex-start" justifyContent="center" width="100%" py={1}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" pl={1} width="100%">
                            <Typography className={classes.title}>
                                <Typography component="span" className={classes.dashboard} display="inline">
                                    {dashboard.title}
                                </Typography>
                                {' was added.'}
                            </Typography>
                            <Typography className={classes.lastUpdatedDate}>{`Added ${moment(createdAt).fromNow()}`}</Typography>
                        </Stack>
                        <Typography className={classes.ownership} pl={1} width="100%">
                            {'Deal owner: '}
                            <Typography component="span" className={classes.owner} display="inline">
                                {dashboard.userId || 'N/A'}
                            </Typography>
                        </Typography>
                        <Box pb={1} />
                    </Stack>
                </Stack>
            </>) : (type === 'attached') ? (<>
                <Stack direction="row" alignItems="flex-start" width="100%">
                    {vertical && (<Divider orientation="vertical" sx={{ height: 'calc(100% - 36px)', translate: '16px 40px' }} />)}
                    <Box width="40px" mt="4px">
                        {getIcon(emailBody?.type as UnassignedContentType)}
                    </Box>
                    <Stack direction="column" spacing="4px" alignItems="flex-start" justifyContent="center" width="100%" py={1}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" pl={1} width="100%">
                            <Typography className={classes.title}>
                                {`New ${getContent(emailBody as UnassignedContent).toLowerCase()} added to `}
                                <Typography component="span" className={classes.dashboard} display="inline">
                                    {dashboard.title}
                                </Typography>
                            </Typography>
                            <Typography className={classes.lastUpdatedDate}>{`Added ${moment(createdAt).fromNow()}`}</Typography>
                        </Stack>
                        <Typography className={classes.subject} pl={1} width="100%">
                            {'Subject: '}
                            <Typography component="span" className={classes.subtitle} display="inline">
                                {emailBody?.subject?.replace('Fwd:', '')?.replace('Fw:', '') || '<Untitled>'}
                            </Typography>
                        </Typography>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" pl={1} width="100%">
                            <Typography className={classes.ownership}>
                                {'From: '}
                                <Typography component="span" className={classes.owner} display="inline">
                                    {(attached && !!emailBody?.from) ? emailBody?.from : 'N/A'}
                                </Typography>
                            </Typography>
                            <IconButton className={classes.icon}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setExpanded(prev => !prev);
                                }} disableRipple
                            > <Typography className={classes.seeEmail}>{`See ${emailBody?.type === 'CALL' ? 'call' : 'email'}`}</Typography>
                            <ExpandMoreIcon style={{ transform: !isExpanded ? 'none' : 'rotate(180deg)' }} />
                            </IconButton>
                        </Stack>
                        <Box px={2}>
                            <UnassignedEmailBody emailBody={emailBody?.content ?? undefined} isExpanded={isExpanded} />
                        </Box>
                    </Stack>
                </Stack>
            </>) : (<></>)}
        </Stack>
    </>);
}

export default ContentActivityFeed;
