import React, { useState } from 'react';
import { IconButton, Menu, MenuItem, Stack, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import {ReactComponent as LinkedInIcon} from "../../../assets/icons/linkedin.svg";
import { AnsweredQuestion } from '../../../contexts/DashboardQueriesContext';
import useContentCopy from '../../../hooks/useContentCopy';
import PersonIcon from '@mui/icons-material/Person';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import theme from '../../../theme';
import ConfirmDialog from '../../modals/ConfirmDialog';

const useStyles = makeStyles((theme) => ({
    masonryElement: {
        display: 'flex',
        width: '80%',
        height: 60,
        overflow: 'hidden',
        padding: '8px 16px',
        borderRadius: 16,
        border: `2px solid ${theme.colors.primary['100']}`,
        background: theme.colors.primary['50'],
        transition: 'all 0.2s ease',
        "&:hover": {
            border: `2px solid ${theme.colors.primary['200']}`,
            background: theme.colors.primary['50'],
            boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
        },
    },
    personIcon: {
        width: 32,
        height: 32,
        fill: theme.palette.primary.main,
    },
    icon: {
        width: 24,
        height: 24,
        fill: '#7bd4d4',
    },
    link: {
        display: 'inline-block',
        maxWidth: '14ch',
        fontFamily: 'Inter !important',
        fontSize: '1rem',
        fontWeight: 'bold',
        textDecoration: 'none',
        color: '#666666',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        lineClamp: 1,
        "&:hover": {
            textDecoration: 'none !important',
        },
    },
    moreIcon: {
        padding: 'unset',
        color: theme.colors.neutral['500'],
        "& > svg": {
            width: 20,
            height: 20,
        },
    },
}));

const KeyPeopleAnswer: React.FC<{
    keyPeople?: AnsweredQuestion|null,
    onEdit?: (element: any) => void,
    onDelete?: (element: any) => void,
}> = ({ keyPeople }) => {
    const classes = useStyles();
    const { getCopiableId } = useContentCopy();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState<boolean>(false);
    const [, setEdiItem] = useState<any>(undefined);
    const [, setDeleteItem] = useState<any>(undefined);

    const keyPeopleAnswer = keyPeople?.answer as AnsweredQuestion[] || [];
    const copiableId = getCopiableId('Key People');
    const extendCopyId = getCopiableId('extend');

    const sanitizeUrl = (originUrl: string) => originUrl.replace(/^https?:\/\//, '').replace(/^www\./, '');

    if (!Array.isArray(keyPeople?.answer) || !keyPeopleAnswer?.length)
        return (<></>);

    return (<>
        <Stack id={copiableId} width="100%">
            <Stack id={extendCopyId} direction="column" spacing={2} alignItems="stretch" width="100%" px={2}>
                {keyPeopleAnswer?.map((element: any, i) => (
                    <Stack className={classes.masonryElement} alignItems="flex-start" justifyContent="center"
                        key={'key-people-answer-87-' + i}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                            <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start" maxWidth="80%"
                                sx={{ cursor: !!element.link ? 'pointer' : 'auto' }}
                                onClick={!!element.link ? (e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    window.open(`https://${sanitizeUrl(element.link)}`, '_blank', 'noopener, noreferrer');
                                } : undefined}>
                                <PersonIcon className={classes.personIcon} />
                                <Typography className={classes.link}>
                                    {element.title.split('|')?.[0]?.split('-')?.[0]}
                                </Typography>
                            </Stack>
                            <Stack direction="row" alignItems="center" justifyContent="flex-end">
                                {!!element.link && element.link?.includes('linkedin') && (
                                    <IconButton onClick={(e) =>  {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        window.open(element.link, '_blank', 'noopener, noreferrer');
                                    }} disableRipple>
                                        <LinkedInIcon className={classes.icon} />
                                    </IconButton>
                                )}
                                <IconButton className={classes.moreIcon}
                                    onClick={(e) =>  {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        setAnchorEl(e.currentTarget);
                                    }} disableRipple> <MoreVertIcon /> </IconButton>
                                <Menu open={Boolean(anchorEl)}
                                    anchorEl={anchorEl}
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                                    transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                                    onClose={() => setAnchorEl(null)}>
                                    <MenuItem onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        setAnchorEl(null);
                                        setEdiItem(element);
                                    }}> {'Edit'} </MenuItem>
                                    <MenuItem sx={{ color: theme.colors.error['500']}}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            setAnchorEl(null);
                                            setDeleteItem(element);
                                            setConfirmDeleteOpen(true);
                                    }}> {'Delete'} </MenuItem>
                                </Menu>
                            </Stack>
                        </Stack>
                    </Stack>
                ))}
            </Stack>
        </Stack>
        {confirmDeleteOpen && (
            <ConfirmDialog
                title="Delete confirmation"
                content="Are you sure you want to delete this person?"
                open={confirmDeleteOpen}
                confirmCallback={() => {; setConfirmDeleteOpen(false);}}
                cancelCallback={() => setConfirmDeleteOpen(false)}
            />
        )}
    </>);
}

export default KeyPeopleAnswer;
