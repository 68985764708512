import React, { useEffect, useState } from 'react';
import { Box, IconButton, Menu, MenuItem, Stack, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import moment from 'moment';
import classnames from 'classnames';
import { DateFmt, DollarMetrics, PercentMetrics, RevenueSubcategories } from './MetricsAnswer';
import theme from '../../../theme';
import ConfirmDialog from '../../modals/ConfirmDialog';
import AddCustomMetricsModal from '../../modals/dashboard-queries/AddCustomMetricsModal';
import { MetricData } from '../../../types/search';

const useStyles = makeStyles((theme) => ({
    title: {
        fontFamily: 'Inter',
        fontSize: '1.05rem',
        fontWeight: 'bold',
        color: theme.colors.neutral['500'],
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    subtitle: {
        fontFamily: 'Inter',
        fontSize: '0.95rem',
        color: '#666666',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    recentDate: {
        fontSize: '0.9rem',
        color: '#7bd4d4',
        paddingRight: 4,
    },
    answerContainer: {
        width: 'auto',
        height: 42,
        border: `1px solid transparent`,
        borderRadius: 12,
    },
    answerHover: {
        "&:hover": {
            border: `1px solid ${theme.colors.neutral['400']}`,
        }
    },
    answerBlock: {
        width: '100%',
        height: 'auto',
        padding: 4,
        color: '#666666',
    },
    editicon: {
        width: 40,
        height: 40,
        padding: 'unset',
    },
    moreIcon: {
        padding: 'unset',
        color: theme.colors.neutral['500'],
        "& > svg": {
            width: 20,
            height: 20,
        },
    },
}));

const MetricComponentAnswer: React.FC<{
    metric: string,
    history?: MetricData,
    readOnly?: boolean,
    onDeleteMetric?: (category: string, softDelete?: boolean) => void,
}> = ({ metric, history, readOnly, onDeleteMetric }) => {
    const classes = useStyles();

    const [recentDate, setRecentDate] = useState<string|null>(null);
    const [selectedUnit, setSelectedUnit] = useState<string>('#');
    const [recentValue, setRecentValue] = useState<string>("");
    const [hoverAnswer, setHoverAnswer] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [addCustomMetricsModalOpen, setAddCustomMetricsModalOpen] = useState<boolean>(false);
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState<boolean>(false);

    useEffect(() => {
        const date = history?.date ?? null;
        const unit = history?.units || '#';
        const value = history?.value ?? null;

        if (value === null) {
            setSelectedUnit('#');
            setRecentValue(`N/A`);
            return;
        }

        let cleanInput = String(value!).replace(/[^0-9bmkt.$%-]/gi, '').toLowerCase();
        let multiplier = 1;

        if (cleanInput.includes('t'))
            multiplier = Math.pow(10, 12);
        else if (cleanInput.includes('b'))
            multiplier = Math.pow(10, 9);
        else if (cleanInput.includes('m'))
            multiplier = Math.pow(10, 6);
        else if (cleanInput.includes('k'))
            multiplier = 1000;

        const numericValue = multiplier * parseFloat(cleanInput.replace(/[^0-9.-]/g, ''));

        setRecentDate(date);
        if (!unit && DollarMetrics.includes(metric)) {
            setSelectedUnit('$');
            setRecentValue(`${numericValue < 0 ? '-' : ''}$${Math.abs(numericValue).toFixed(0)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ","));
        } else if (!unit && PercentMetrics.includes(metric)) {
            setSelectedUnit('%');
            setRecentValue(`${numericValue.toFixed(1)}%`);
        } else {
            setSelectedUnit(unit);
            if (unit === '$')
                setRecentValue(`${numericValue < 0 ? '-' : ''}$${Math.abs(numericValue).toFixed(0)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ","));
            else if (unit === '%')
                setRecentValue(`${numericValue.toFixed(1)}%`);
            else
                setRecentValue(`${numericValue}`);
        }
    }, [history, metric]);

    return (<>
        <Stack direction="column" width="100%">
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                {RevenueSubcategories.map(cat => cat.toLowerCase()).includes(metric.trim().toLowerCase()) ? (
                    <Typography className={classes.title}>
                        {'Revenue '}
                        <Typography component="span" className={classes.subtitle} display="inline"> ({metric})</Typography>
                    </Typography>
                ): (
                    <Typography className={classes.title}>
                        {metric}
                    </Typography>
                )}
                <Stack direction="row" alignItems="center" justifyContent="flex-end">
                    {!!recentDate && (
                        <Typography className={classes.recentDate}>{moment(recentDate).format(DateFmt)}</Typography>
                    )}
                    <IconButton className={classes.moreIcon}
                        onClick={(e) => setAnchorEl(e.currentTarget)}
                        disableRipple>
                        <MoreVertIcon />
                    </IconButton>
                    <Menu open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                        onClose={() => setAnchorEl(null)}>
                        <MenuItem onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            setAnchorEl(null);
                            setAddCustomMetricsModalOpen(true);
                            setHoverAnswer(false);
                        }}> {'Edit'} </MenuItem>
                        <MenuItem sx={{ color: theme.colors.error['500']}}
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                setAnchorEl(null);
                                setConfirmDeleteOpen(true);
                        }}> {'Delete'} </MenuItem>
                    </Menu>
                </Stack>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="space-between"
                className={classnames(classes.answerContainer, hoverAnswer && classes.answerHover)}
                onMouseLeave={() => setHoverAnswer(false)}>
                <Box className={classes.answerBlock} onMouseEnter={() => setHoverAnswer(!readOnly)}>
                    <Typography fontSize="1.2rem" color="#666666">{recentValue}</Typography>
                </Box>
                {hoverAnswer && (
                    <Stack direction="column" alignItems="center" justifyContent="flex-end" width="40px">
                        <IconButton size="small"
                            className={classes.editicon}
                            onClick={(e) => {
                                e.stopPropagation();
                                setAddCustomMetricsModalOpen(true);
                                setHoverAnswer(false);
                            }}
                        > <EditOutlinedIcon fontSize="small" /> </IconButton>
                    </Stack>
                )}
            </Stack>
        </Stack>
        {addCustomMetricsModalOpen && (
            <AddCustomMetricsModal
                isOpen={addCustomMetricsModalOpen}
                customValues={({metric,
                    value: recentValue,
                    date: moment(recentDate || new Date()),
                    units: selectedUnit,
                })}
                onClose={() => {
                    setAddCustomMetricsModalOpen(false);
                }} />
        )}
        {confirmDeleteOpen && (
            <ConfirmDialog
                title="Delete confirmation"
                content="Are you sure you want to delete this metric?"
                open={confirmDeleteOpen}
                confirmCallback={() => {onDeleteMetric?.(metric); setConfirmDeleteOpen(false);}}
                cancelCallback={() => setConfirmDeleteOpen(false)}
            />
        )}
    </>);
}

export default MetricComponentAnswer;
