import React, { useContext, useEffect, useState } from "react";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeKatex from 'rehype-katex';
import 'katex/dist/katex.min.css';

import useDashboardQuery from "../../../hooks/useDashboardQuery";
import {DashboardContext} from "../../../contexts/DashboardContext";
import { AnsweredQuestion } from "../../../contexts/DashboardQueriesContext";
import makeStyles from "@mui/styles/makeStyles";
import { Dialog, DialogTitle, Divider, DialogContent, DialogActions, Button, Typography } from "@mui/material";
import { scrollbarStyle } from "../../../shared/dashboard";
import theme from "../../../theme";

const useStyles = makeStyles((theme) => ({
    dialog: {
        "& .MuiDialog-paper": {
            minWidth: 700,
            minHeight: '60vh',
            height: 'fit-content',
            padding: '16px 24px',
            borderRadius: 24,
        }
    },
    dialogTitle: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: 'unset',
        color: 'black',
        fontWeight: 'bold',
        fontFamily: 'Inter',
        fontSize: '1.3rem',
    },
    dialogContent: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        padding: '8px 0',
        height: '100%',
        overflowY: 'auto',
        ...scrollbarStyle,
    },
    dialogActions: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: 0,
        gap: 8,
    },
    closeButton: {
        minWidth: 80,
        borderRadius: 20,
        background: theme.palette.primary.main,
        color: 'white',
        textTransform: 'none',
        fontWeight: 'bold',
        transition: 'ease-in-out 300ms',
    },
}));

const MarketSizeOverviewModal: React.FC<{
    isOpen: boolean,
    onClose: () => void,
}> = ({ isOpen, onClose }) => {
    const classes = useStyles();
    const { dashboard } = useContext(DashboardContext);
    const { queryAnswer } = useDashboardQuery('Market Size & Overview');
    const [answeredQuestion, setAnsweredQuestion] = useState<AnsweredQuestion|undefined>(undefined);

    useEffect(() => {
        if (!!queryAnswer.answeredQuestion)
            setAnsweredQuestion(queryAnswer.answeredQuestion);
    }, [dashboard, queryAnswer]);

    return (<>
        <Dialog className={classes.dialog} open={isOpen} onClose={() => onClose()}>
            <DialogTitle className={classes.dialogTitle}>
                {`${dashboard?.title!} Market Size & Overview`}
            </DialogTitle>
            <Divider sx={{ margin: '8px 0'}} />
            <DialogContent className={classes.dialogContent}>
                <Typography fontFamily="Inter" fontSize="1.2rem" fontWeight="bold" color={theme.palette.primary.main}>
                    {'TAM Breakdown'}
                </Typography>
                <ReactMarkdown
                    children={answeredQuestion?.answer as string}
                    remarkPlugins={[remarkGfm]}
                    rehypePlugins={[rehypeKatex]} />
            </DialogContent>
            <Divider sx={{ margin: '8px 0'}} />
            <DialogActions className={classes.dialogActions}>
                <Button variant="contained" className={classes.closeButton}
                    onClick={() => onClose()}
                > {'Close'} </Button>
            </DialogActions>
        </Dialog>
    </>);
}

export default MarketSizeOverviewModal;