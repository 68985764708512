import React, { useContext } from "react";
import { IconButton, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useSnackbar } from "notistack";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EmailIcon from '@mui/icons-material/MailOutlineRounded';
import {AuthContext} from "../../contexts/AuthContext";
import theme from "../../theme";

const useStyles = makeStyles((theme) => ({
    subText: {
        fontFamily: 'Inter',
        fontSize: '1rem',
        fontWeight: 400,
        color: theme.colors.neutral['500'],
    },
    link: {
        fontFamily: 'Inter',
        fontSize: '1rem',
        fontWeight: 'bold',
        color: theme.palette.primary.main,
        cursor: 'pointer',
    },
}));

const ForwardDeals: React.FC<{}> = () => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { userGroup } = useContext(AuthContext);

    return (<>
        <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end">
            <EmailIcon sx={{ width: 28, height: 28, color: theme.colors.cyan['500'] }} />
            <Typography className={classes.subText}>
                {'Forward emails to: '}
                <Typography component="span" className={classes.link}
                    onClick={() => window.open(`mailto: ${userGroup}@notissia.awsapps.com`, '_self', 'noopener, noreferrer')}
                    display="inline">
                    {`${userGroup}@notissia.awsapps.com`}
                </Typography>
                <CopyToClipboard text={`${userGroup}@notissia.awsapps.com`}
                    options={{ format: "text/plain" }}
                    onCopy={() => {
                        enqueueSnackbar(`Successfully copied to clipboard.`,
                            { anchorOrigin: { vertical: "bottom", horizontal: "right" }, });
                    }}>
                    <IconButton size="small" sx={{ color: theme.colors.cyan['500'] }}>
                        <ContentCopyIcon sx={{ width: 24, height: 24, }} />
                    </IconButton>
                </CopyToClipboard>
            </Typography>
        </Stack>
    </>);
}

export default ForwardDeals;
