import React, { useContext } from "react";
import { DashboardContext } from "../../../contexts/DashboardContext";
import useDashboardQuery from "../../../hooks/useDashboardQuery";
import MetricsAnswer from "../dashboard-query-answer/MetricsAnswer";

const MetricsQuery: React.FC<{ roundDetails?: boolean }> = ({ roundDetails }) => {
    const { isPublicView } = useContext(DashboardContext);
    const { queryAnswer, updateQuery } = useDashboardQuery('Note Metrics');

    return (<>
        <MetricsAnswer
            answeredQuestion={queryAnswer.answeredQuestion}
            roundDetails={roundDetails}
            readOnly={isPublicView}
            onUpdate={answer => updateQuery(answer)}
        />
    </>);
}

export default MetricsQuery;
