import React, { useState } from "react";
import { Box, Button, Stack } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import AddIcon from '@mui/icons-material/Add';
import AddCustomPeopleModal from "../modals/dashboard-queries/AddCustomPeopleModal";

const useStyles = makeStyles((theme) => ({
    masonryElement: {
        display: 'flex',
        width: '80%',
        height: 60,
        overflow: 'hidden',
        padding: '12px 16px',
        borderRadius: 16,
        border: `2px solid ${theme.colors.primary['100']}`,
        background: theme.colors.primary['50'],
        transition: 'all 0.2s ease',
        '&:hover': {
            border: `2px solid ${theme.colors.primary['200']}`,
            background: theme.colors.primary['50'],
            boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
        },
    },
    iconButton: {
        minWidth: 32,
        padding: 'unset !important',
        fontFamily: 'Inter !important',
        fontSize: '1rem',
        fontWeight: 'bold',
        color: theme.palette.primary.light,
        textTransform: 'none',
        "& > .MuiButton-startIcon": {
            width: 'auto',
            height: 32,
            padding: 6,
            borderRadius: '50%',
            background: '#E0F1F1',
            "& > .MuiSvgIcon-root": {
                fontSize: 22,
                color: theme.palette.primary.main,
            }
        },
        "&:hover": {
            backgroundColor: 'inherit',
        },
    },
}));

const AddPeople: React.FC<{}> = () => {
    const classes = useStyles();
    const [addCustomPeopleModalOpen, setAddCustomPeopleModalOpen] = useState<boolean>(false);

    return (<>
        <Box width="100%" px={2} sx={{ cursor: 'pointer' }}
            onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setAddCustomPeopleModalOpen(true);
            }}>
            <Stack className={classes.masonryElement} alignItems="flex-start">
                <Button variant="text"
                    className={classes.iconButton}
                    startIcon={<AddIcon />}>
                    {'Add a person'}
                </Button>
            </Stack>
        </Box>
        {addCustomPeopleModalOpen && (
            <AddCustomPeopleModal
                isOpen={addCustomPeopleModalOpen}
                onClose={() => {
                    setAddCustomPeopleModalOpen(false);
                }}>
            </AddCustomPeopleModal>
        )}
    </>);
}

export default AddPeople;