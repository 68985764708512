import { Box } from "@mui/material";
import 'katex/dist/katex.min.css';
import React, { useEffect, useState } from "react";
import ReactMarkdown from 'react-markdown';
import rehypeKatex from 'rehype-katex';
import remarkGfm from 'remark-gfm';
import { DashboardQueryAnswer } from "../../../contexts/DashboardQueriesContext";
import { splitter } from "../../../helpers/textSplitter";
import useDashboardQuery from "../../../hooks/useDashboardQuery";
import MarketSizeOverviewModal from "../../modals/dashboard-queries/MarketSizeOverviewModal";
import EmptyQueryContainer from "../dashboard-query-container/EmptyQueryContainer";

const MarketSizeOverviewQuery: React.FC<{}> = () => {
    const { queryAnswer } = useDashboardQuery('Market Size & Overview');
    const [answer, setAnswer] = useState<string|undefined>(undefined);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [updatedQueryAnswer, setUpdatedQueryAnswer] = useState<DashboardQueryAnswer|undefined>(undefined);

    useEffect(() => {
        setUpdatedQueryAnswer(({ ...queryAnswer,
            answeredQuestion: { ...queryAnswer.answeredQuestion, question: 'Market Size & Overview'}
        }));
    }, [queryAnswer]);

    useEffect(() => {
        if (!!queryAnswer?.answeredQuestion?.answer)
            setAnswer(splitter(queryAnswer?.answeredQuestion?.answer as string).map((split) => split?.sent)?.[0]);
    }, [queryAnswer.answeredQuestion, queryAnswer.answeredQuestion.answer]);

    return (<>
        <EmptyQueryContainer queryAnswer={updatedQueryAnswer ?? queryAnswer} onModal={setModalOpen} hoverEffect>
            <Box sx={{ cursor: 'pointer' }}
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setModalOpen(true);
                }}> <ReactMarkdown
                    children={answer as string}
                    remarkPlugins={[remarkGfm]}
                    rehypePlugins={[rehypeKatex]} />
            </Box>
        </EmptyQueryContainer>
        {modalOpen && (
            <MarketSizeOverviewModal isOpen={modalOpen} onClose={() => setModalOpen(false)} />
        )}
    </>);
}

export default MarketSizeOverviewQuery;