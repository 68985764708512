import LaunchIcon from '@mui/icons-material/Launch';
import { Box, CircularProgress, IconButton, Link, Stack, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import classnames from 'classnames';
import React, { useCallback, useContext, useMemo, useState } from 'react';

import { ReactComponent as PDFIcon } from "../../../assets/icons/pdfNew.svg";
import { ReactComponent as WorldGridIcon } from "../../../assets/icons/world.svg";
import { FileStructureContext } from '../../../contexts/FileStructureContext';
import useFileUploader from '../../../hooks/useFileUploader';
import useRoute from '../../../hooks/useRoute';
import { updateDashboardFunc } from '../../../lib/helper';
import { scrollbarStyle } from '../../../shared/dashboard';
import theme from '../../../theme';
import { Dashboard } from '../../../types/files';
import AnswerEditor from '../dashboard-query-answer/AnswerEditor';
import CompanyMiniDeckViewer from '../dashboard-deck/CompanyMiniDeckViewer';

const useStyles = makeStyles((theme) => ({
    masonryElement: {
        display: 'inline-block',
        width: '100%',
        height: 'auto',
        overflow: 'hidden',
        padding: 'unset',
        border: 'unset',
        background: 'transparent',
        transition: 'all 300ms ease',
        "&:hover": {
            border: `unset !important`,
            background: 'transparent !important',
            boxShadow: 'unset !important',
        },
    },
    answerContainer: {
        width: 'auto',
        height: 'auto',
        marginLeft: 4,
        paddingLeft: 4,
        paddingRight: 16,
        border: `1px transparent solid`,
        borderRadius: 12,
    },
    answerBlock: {
        width: '100%',
        height: '48px',
        paddingTop: 8,
        paddingBottom: 8,
        color: '#666666',
        overflowY: 'auto',
        ...scrollbarStyle,
    },
    answerHover: {
        width: '96%',
        marginRight: 2,
        '&:hover': {
            border: `1px ${theme.colors.neutral['400']} solid`,
            borderRadius: 12,
        }
    },
    title: {
        fontFamily: 'Inter',
        fontSize: '1.1rem',
        fontWeight: 'bold',
        color: theme.palette.primary.main,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        marginLeft: 8,
    },
    deck: {
        color: '#666666',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    url: {
        display: 'inline-block',
        color: '#666666',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textDecoration: 'none',
        whiteSpace: 'nowrap',
        lineClamp: 1,
        maxWidth: '22ch',
        "&:hover": {
            textDecoration: 'none !important',
        },
    },
    editBlock: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        maxHeight: 910,
        overflow: 'hidden',
    },
    icon: {
        width: 24,
        height: 24,
        fill: '#7bd4d4',
    },
}));

const DeckLink: React.FC<{ dashboard: Dashboard }> = ({ dashboard }) => {
    const classes = useStyles();
    const { fileStructure } = useContext(FileStructureContext);
    const { docsendIngestion } = useFileUploader({});
    const { redirectToDashboard } = useRoute();
    const [isEditingAnswer, setIsEditingAnswer] = useState<boolean>(false);
    const [editAnswer, setEditAnswer] = useState<string | undefined >(undefined);
    const [hoverAnswer, setHoverAnswer] = useState<boolean>(false);
    const [saving, setSaving] = useState<boolean>(false);
    const [deckModalOpen, setDeckModalOpen] = useState<boolean>(false);

    const sanitizeUrl = (originUrl: string) => originUrl.replace(/^https?:\/\//, '').replace(/^www\./, '');

    const pdfDocs = useMemo(() => fileStructure.filter(fS => fS.dashboardId === dashboard.id), [fileStructure, dashboard]);

    const saveEditAnswer = useCallback(async (answer: string) => {
        setSaving(true);
        Promise.all([
            docsendIngestion(dashboard, answer),
            updateDashboardFunc({ ...dashboard, externalLinks: [answer] })
        ]).then(() => {
            setIsEditingAnswer(false);
            setEditAnswer(undefined);
            setSaving(false);
        });
    }, [dashboard, docsendIngestion]);

    return (<>
        <Box className={classes.masonryElement}>
            <Stack direction="column" alignItems="flex-start" width="100%">
                <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                    <Typography className={classes.title}>{'Latest Deck'}</Typography>
                    {saving && (<CircularProgress size={24} />)}
                </Stack>
                {!isEditingAnswer && (
                    <Stack direction="row" justifyContent="space-between" mt={1} py="6px"
                        className={classnames(classes.answerContainer, hoverAnswer && classes.answerHover)}
                        onMouseLeave={() => setHoverAnswer(false)}>
                        {!!dashboard.externalLinks?.length ? (
                            <Stack direction="row" spacing={1} alignItems="center" justifyContent={hoverAnswer ? 'space-between' : 'flex-start'} width="100%"
                                onMouseEnter={() => setHoverAnswer(!!pdfDocs.length)}>
                                <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start" width="100%">
                                    <WorldGridIcon className={classes.icon} fill="#7bd4d4" />
                                    <Typography component="a"
                                        className={classes.url}
                                        href={dashboard?.externalLinks?.[0]}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        sx={{ maxWidth: `${hoverAnswer ? 12 : 14}ch !important`}}>
                                        {sanitizeUrl(dashboard?.externalLinks?.[0])}
                                    </Typography>
                                </Stack>
                                {hoverAnswer && (
                                    <IconButton disableRipple
                                        onClick={() => setDeckModalOpen(true)}
                                        sx={{ padding: 'unset !important'}}>
                                        <LaunchIcon className={classes.icon} sx={{ fill: theme.colors.neutral['500'] }} />
                                    </IconButton>
                                )}
                            </Stack>
                        ) : (!!pdfDocs.length) ? (
                            <Stack direction="row" spacing={1} alignItems="center" justifyContent={hoverAnswer ? 'space-between' : 'flex-start'} width="100%"
                                onMouseEnter={() => setHoverAnswer(true)}>
                                <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start" width="100%">
                                    <PDFIcon className={classes.icon} fill="#7bd4d4" />
                                    <Link onClick={() => redirectToDashboard(dashboard.id, ['files', pdfDocs[0].id].join('/'))}
                                        underline="hover" target="_self" sx={{ cursor: 'pointer' }}>
                                        <Typography className={classes.url} sx={{ maxWidth: `${hoverAnswer ? 12 : 14}ch !important`}}>
                                            {pdfDocs[0].name}
                                        </Typography>
                                    </Link>
                                </Stack>
                                {hoverAnswer && (
                                    <IconButton disableRipple
                                        onClick={() => setDeckModalOpen(true)}
                                        sx={{ padding: 'unset !important'}}>
                                        <LaunchIcon className={classes.icon} sx={{ fill: theme.colors.neutral['500'] }} />
                                    </IconButton>
                                )}
                            </Stack>
                        ) : (<Typography className={classes.url} color="#666666 !important">{'N/A'}</Typography>)}
                    </Stack>
                )}
            </Stack>
            {isEditingAnswer && (
                <Box className={classes.editBlock} px={1}>
                    <AnswerEditor
                        currentAnswer={editAnswer || ''}
                        placeHolder={"Add link..."}
                        rows={2}
                        handleCancel={() => {
                            setIsEditingAnswer(false);
                            setEditAnswer(undefined);
                        }}
                        handleSave={saveEditAnswer} />
                </Box>
            )}
        </Box>
        {deckModalOpen && (
            <CompanyMiniDeckViewer
                open={deckModalOpen}
                defaultDeck={pdfDocs[0]}
                externalDeck={dashboard?.externalLinks?.[0]}
                onClose={() => setDeckModalOpen(false) } />
        )}
    </>);
}

export default DeckLink;
