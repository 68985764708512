import React from "react";
import useDashboardQuery from "../../../hooks/useDashboardQuery";
import KeyPeopleAnswer from "../dashboard-query-answer/KeyPeopleAnswer";

const KeyPeopleQuery: React.FC<{}> = () => {
    const { queryAnswer } = useDashboardQuery('Key People');

    return (<>
        <KeyPeopleAnswer keyPeople={queryAnswer?.answeredQuestion} />
    </>);
}

export default KeyPeopleQuery;
