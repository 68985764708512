import { FormControl, MenuItem, Select, SelectChangeEvent, Stack } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { useCallback, useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { getAllUsersFunc } from "../../../lib/helper";

const useStyles = makeStyles((theme) => ({
    text: {
        fontFamily: 'Inter',
        fontSize: '1.1rem',
        fontWeight: 'bold',
        color: theme.colors.neutral['600'],
    },
    formContainer: {
        height: 'auto',
        minWidth: '150px',
        paddingTop: '8px',
        paddingBottom: '8px',
        "& > .MuiInputBase-root": {
            justifyContent: 'center',
            fontFamily: 'Inter',
            color: '#666666',
            "& > div": {
                backgroundColor: 'unset !important',
            },
            "&::before": {
                content: 'unset !important',
            },
            "&::after": {
                content: 'unset !important',
            }
        }
    },
}));

const DealOwnerSelector: React.FC<{
    defaultOwner?: string,
    readOnly?: boolean,
    onSelectOwner?: (stage: string) => void,
}> = ({ defaultOwner, readOnly, onSelectOwner }) => {
    const classes = useStyles();
    const { userGroup } = useContext(AuthContext);
    const [selected, setSelectedOwner] = useState<string>('');
    const [owners, setOwners] = useState<string[]>([]);

    const handleChange = useCallback((event: SelectChangeEvent) => {
        const stage = event.target.value;

        setSelectedOwner(stage);
        onSelectOwner?.(stage);
    }, [onSelectOwner]);

    useEffect(()=>{
        if (!!userGroup)
            getAllUsersFunc(userGroup).then(users => setOwners(users.map((user: any) => user.username)));
    }, [userGroup]);

    useEffect(()=>{
        if (defaultOwner)
            setSelectedOwner(defaultOwner);
    }, [defaultOwner]);

    return (<>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
            <FormControl className={classes.formContainer} variant={'standard'} disabled={readOnly}>
                <Select value={selected} onChange={handleChange}
                    renderValue={(selected) => {
                        if (selected.length === 0)
                            return 'N/A';

                        return selected;
                    }}
                    displayEmpty>
                    {owners.map((owner, i) => (
                        <MenuItem value={owner}
                            sx={{ fontSize: '1rem', color: (selected === owner) ? '#7bd4d4' : 'gray', }}
                            key={'deal-owner-selector-52-' + i}>
                            {owner}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Stack>
    </>);
}

export default DealOwnerSelector;