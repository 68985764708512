import React, { Fragment, useContext, useMemo } from "react";
import { Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment";

import {DashboardsContext} from "../../contexts/DashboardsContext";
import { Dashboard } from "../../types/files";
import theme from "../../theme";
import { NoteMetric } from "../../types/search";

const useStyles = makeStyles(() => ({
    linkedIn: {
        fontFamily: 'Inter !important',
        fontSize: '0.9rem !important',
        fontStyle: 'italic',
        color: '#666666',
    },
    link: {
        fontFamily: 'Inter !important',
        fontSize: '0.85rem !important',
        fontStyle: 'normal !important',
        textDecoration: 'none',
        "&:hover": {
            textDecoration: 'none !important',
        }
    }
}));

const QueryAnswer: React.FC<{
    dashboard: Dashboard,
    title: string,
    disabled?: boolean,
 }> = ({ dashboard, title, disabled }) => {
    const classes = useStyles();
    const { mappedOverviewQueries } = useContext(DashboardsContext);

    const headerColor = disabled ? theme.colors.neutral['400'] : theme.colors.cyan['400'];
    const textColor = disabled ? theme.colors.neutral['600'] : '#666666';

    const memoAnswer = useMemo(() =>
        mappedOverviewQueries.get(`${dashboard.id}:${(title === 'Metrics') ? 'Note Metrics': (title === 'Description') ? 'Digest' : title}`)
    , [dashboard.id, mappedOverviewQueries, title]);

    if (!!memoAnswer) {
        switch (title) {
            case 'Location':
                const locationAnswer = memoAnswer as string;
                const locationSearch = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(locationAnswer)}`;

                if (locationAnswer.trim().toLowerCase().includes('N/A')) {
                    return (<>
                        <Stack alignItems="baseline" justifyContent="center">
                            <Typography color={headerColor} fontSize="0.95rem" fontWeight="bold">
                                {`${title}: `}
                                <Typography component="span" fontSize="0.95rem" color={textColor} display="inline">
                                    {locationAnswer}
                                </Typography>
                            </Typography>
                        </Stack>
                    </>);
                }

                return (<>
                    <Stack alignItems="baseline" justifyContent="center">
                        <Typography color={headerColor} fontSize="0.95rem" fontWeight="bold">
                            {`${title}: `}
                            <Typography component="a"
                                className={classes.link}
                                href={locationSearch}
                                target="_blank"
                                rel="noopener noreferrer"
                                display="inline"
                                sx={{ color: disabled ? textColor : theme.palette.primary.main }}>
                                {locationAnswer}
                            </Typography>
                        </Typography>
                    </Stack>
                </>);
            case 'LinkedIn':
            case 'Website':
                const linkedAnswer = memoAnswer as string;

                return (<>
                    <Stack alignItems="baseline" justifyContent="center">
                        <Typography color={headerColor} fontSize="0.95rem" fontWeight="bold">
                            {`${title}: `}
                            <Typography component="a"
                                className={classes.link}
                                href={linkedAnswer}
                                target="_blank"
                                rel="noopener noreferrer"
                                display="inline">
                                {linkedAnswer}
                            </Typography>
                        </Typography>
                    </Stack>
                </>);
            case 'Metrics':
                const metrics = memoAnswer as NoteMetric;
                let metricsAnswer = '';
                let metricsList: {metric: string, value: string}[] = [];

                if (Object.values(metrics).every(metric => metric?.value === null)) {
                    metricsAnswer = 'No metrics data available.';
                } else {
                    Object.entries(metrics).forEach(([metricName, metricValues]) => {
                        if (metricValues?.value !== null) {
                            const date = !!metricValues?.date ? moment(metricValues?.date).format('MMM YYYY') : '';
                            const cleanInput = String(metricValues?.value!).replace(/[^0-9bmkt.$%-]/gi, '').toLowerCase();
                            let multiplier = 1;
                            let displayValue = '0';

                            if (cleanInput.includes('t'))
                                multiplier = Math.pow(10, 12);
                            else if (cleanInput.includes('b'))
                                multiplier = Math.pow(10, 9);
                            else if (cleanInput.includes('m'))
                                multiplier = Math.pow(10, 6);
                            else if (cleanInput.includes('k'))
                                multiplier = 1000;

                            const numericValue = multiplier * parseFloat(cleanInput.replace(/[^0-9.-]/g, ''));

                            if (metricValues?.units === '%')
                                displayValue = `${numericValue.toFixed(1)}%`;
                            else if (metricValues?.units === '$')
                                displayValue = `${numericValue < 0 ? '-' : ''}$${Math.abs(numericValue).toFixed(0)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                            else
                                displayValue = `${numericValue}`;

                            metricsList.push({ metric: metricName, value: `${displayValue} ${!!date ? `(${date})` : ''}`, });
                        }
                    });
                }

                return (<>
                    <Stack alignItems="baseline" justifyContent="center">
                        <Typography color={headerColor} fontSize="0.95rem" fontWeight="bold">
                            {`${title}: `}
                            <Typography component="span" fontSize="0.95rem" color={textColor} display="inline">
                                {metricsAnswer}
                            </Typography>
                        </Typography>
                        <Stack alignItems="flex-start" justifyContent="center">
                            {metricsList.map((item: any, i) => (
                                <Stack direction="row" spacing="4px" alignItems="center" justifyContent="flex-start" key={'query-answer-130-' + i}>
                                    <Typography component="span" fontSize="0.95rem" fontStyle="italic" color={textColor}>{item.metric}:</Typography>
                                    <Typography component="span" fontSize="0.9rem" color={textColor}>{item.value}</Typography>
                                </Stack>
                            ))}
                        </Stack>
                    </Stack>
                </>);
            case 'Team':
                const teamAnswer = memoAnswer as string;
                const keyPeople = mappedOverviewQueries.get(`${dashboard.id}:Key People`);

                return (<>
                    <Stack alignItems="baseline" justifyContent="center">
                        <Typography color={headerColor} fontSize="0.95rem" fontWeight="bold">
                            {`${title}: `}
                            <Typography component="span" fontSize="0.95rem" color={textColor} display="inline">
                                {teamAnswer}
                            </Typography>
                        </Typography>
                        {Array.isArray(keyPeople) && (
                            <Stack alignItems="flex-start" justifyContent="center">
                                {keyPeople.map((element: any, i) => (
                                    <Fragment key={'query-answer-153-' + i}>
                                        {!!element?.title?.split('|')?.[0] && (
                                            <Typography className={classes.linkedIn}>
                                                {'LinkedIn: '}
                                                <Typography component="a"
                                                    className={classes.link}
                                                    fontWeight="bold !important"
                                                    href={element?.link || '#'}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    display="inline">
                                                    {element?.title?.split('|')?.[0]}
                                                </Typography>
                                            </Typography>
                                        )}
                                    </Fragment>
                                ))}
                            </Stack>
                        )}
                    </Stack>
                </>);
            case 'Description':
                const descriptionAnswer = mappedOverviewQueries.get(`${dashboard.id}:Digest`)?.[0];

                return (<>
                    <Stack alignItems="baseline" justifyContent="center">
                        <Typography color={headerColor} fontSize="0.9rem" fontWeight="bold">
                            {`${title}: `}
                            <Typography component="span" fontSize="0.9rem" color={textColor} display="inline">
                                {descriptionAnswer}
                            </Typography>
                        </Typography>
                    </Stack>
                </>);
            default:
                const fullWordAnswer = memoAnswer as string;

                return (<>
                    <Stack alignItems="baseline" justifyContent="center">
                        <Typography color={headerColor} fontSize="0.9rem" fontWeight="bold">
                            {`${title}: `}
                            <Typography component="span" fontSize="0.9rem" color={textColor} display="inline">
                                {fullWordAnswer}
                            </Typography>
                        </Typography>
                    </Stack>
                </>);
        }
    }

    return (<></>);
}

export default QueryAnswer;