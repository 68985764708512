import { useCallback, useContext } from "react";

import { AuthContext } from "../contexts/AuthContext";
import { addDashboardQueryFunc } from "../lib/helper";
import { Dashboard, DashboardQuerySource } from "../types/files";
import { AnswerQuestionResponseType } from "../types/search";

const GlobalUrlPattern = /^(?:(?:(?:https?):\/\/)|(?:www\.|(?!www))[A-Za-z0-9.-]+[A-Za-z]{2,}\/?)?[A-Za-z0-9-._~:/?#[\]@!$&'()*+,;=%]+$/i;

const useStaticDashboard = () => {
  const { userGroup } = useContext(AuthContext);

  const saveWebQueryAnswer = useCallback(async (dashboard: Dashboard, customUrl: string) => {
    let permalink = customUrl;

    if (/^https?:\/\//i.test(customUrl)) {
      permalink = customUrl.replace(/^https?:\/\/(?:www\.)?/, "https://");
    } else if (/^(www\.)?\S+\.\S+$/.test(customUrl)) {
      permalink = "https://" + customUrl.replace(/^www\./, '');
    } else if (/^\S+\.\S+$/.test(customUrl)) {
      permalink = "https://" + customUrl;
    }

    if (GlobalUrlPattern.test(permalink)) {
      await addDashboardQueryFunc(
        dashboard!.id,
        'Website',
        userGroup!,
        dashboard!.title,
        JSON.stringify({
          answer: permalink,
          type: AnswerQuestionResponseType.URL,
        }),
        undefined,
        301,
        true,
        DashboardQuerySource.CRUNCHBASE,
      );
    }
  }, [userGroup]);

  return { saveWebQueryAnswer };
};

export default useStaticDashboard;
