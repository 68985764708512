import { FC, useState, useContext, useMemo, useCallback, useEffect } from "react";
import { Box, Button, Menu, MenuItem, Stack } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import StatusBadge from "../status/StatusBadge";
import { GroupSettingsContext } from "../../../contexts/GroupSettingsContext";
import useBulkDashboards from "../../../hooks/useBulkDashboards";
import { collectionKey } from '../../organisms/dashboards/DashboardsView';
import theme from "../../../theme";
import { updateDashboardFunc } from "../../../lib/helper";
import {DashboardsContext} from "../../../contexts/DashboardsContext";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const useStyles = makeStyles((theme) => ({
    expandMoreIcon: {
        color: theme.colors.neutral['500'],
        width: '20px',
        height: '20px',
        transition: '0.25s ease-in-out ',
        '&:hover': {
            color: theme.colors.neutral['700'],
        },
    },
    expandButton: {
        minWidth: '20px',
        padding: 0,
        '&:hover': {
            backgroundColor: 'transparent'
        }
    },
}));

const CompaniesStatusMenu: FC<{}> = () => {
    const classes = useStyles();
    const { dashboards } = useContext(DashboardsContext);
    const { statuses } = useContext(GroupSettingsContext);
    const { getBulkDashboards, clearBulk } = useBulkDashboards();
    const [anchorElement, setAnchorElement] = useState<HTMLElement|null>(null);
    const [status, setStatus] = useState<string>('');

    const menuOpen = Boolean(anchorElement);

    const bulkDashboards = useMemo(() => getBulkDashboards(collectionKey), [getBulkDashboards]);

    const handleOpenMenu = useCallback((event: any) => {
        setAnchorElement(event.currentTarget);
    }, []);

    const handleSave = useCallback(async (item: string) => {
        const promises: Promise<any>[] = [];

        if (!!bulkDashboards.length && status !== item) {
            setStatus(item);
            promises.push(...bulkDashboards.map(({dashboard}) => updateDashboardFunc({...dashboard, status: item})));
            Promise.all(promises).then(() => {
                bulkDashboards.forEach(({dashboard}) => {
                    if (!!dashboards.length) {
                        const found = dashboards.find(d => d.id === dashboard.id);

                        if (!!found)
                          found.status = item;
                    }
                });

                clearBulk();
            });
        }
    // eslint-disable-next-line
    }, [bulkDashboards, status]);

    useEffect(() => {
        if (!!bulkDashboards.length)
            setStatus(bulkDashboards[0].dashboard.status);
    }, [bulkDashboards]);

    return (<>
        <Box onClick={(e) => e.stopPropagation()}>
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
                <Button className={classes.expandButton} onClick={handleOpenMenu} size="small">
                    <StatusBadge title={status} />
                    <ExpandMoreIcon className={classes.expandMoreIcon} />
                </Button>
                <Menu open={menuOpen} anchorEl={anchorElement} onClose={() => setAnchorElement(null)}>
                    {statuses.map((item, i) => (
                        <MenuItem onClick={(e) => {
                                e.stopPropagation();
                                setAnchorElement(null);
                                handleSave(item);
                            }}
                            sx={{
                                minWidth: '150px',
                                backgroundColor: (item === status) ? theme.colors.neutral['200'] : '',
                                '&:hover': {
                                    backgroundColor: (item === status) ? theme.colors.neutral['200'] : ''
                                }
                            }}
                            key={'companies-status-menu-120-' + i}>
                            <StatusBadge title={item} />
                        </MenuItem>
                    ))}
                </Menu>
            </Stack>
        </Box>
    </>);
}

export default CompaniesStatusMenu;