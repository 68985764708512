import { Box, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import StageSelector from "../crunchbase-modal/StageSelector";
import { useContext } from "react";
import {DashboardContext} from "../../../contexts/DashboardContext";
import useDashboard from "../../../hooks/useDashboard";

const useStyles = makeStyles((theme) => ({
    title: {
        fontFamily: 'Inter',
        fontSize: '1.2rem',
        fontWeight: 'bold',
        color: theme.palette.primary.main,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
}));

const DealStage: React.FC<{}> = () => {
    const classes = useStyles();
    const { dashboard, isPublicView } = useContext(DashboardContext);
    const { updateStage } = useDashboard();

    return (<>
        <Stack spacing={2} width="100%">
            <Typography className={classes.title}>
                {'Stage'}
            </Typography>
            <Box sx={{ transform: 'scale(1.1)' }} pl={2}>
                <StageSelector
                    defaultStage={dashboard?.investmentStage ?? undefined}
                    onSelectStage={updateStage}
                    readOnly={isPublicView}
                    filled />
            </Box>
        </Stack>
    </>);
}

export default DealStage;