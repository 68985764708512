import { API, graphqlOperation } from "aws-amplify";
import {
  deleteDashboard,
  deleteMention,
  deleteComment,
  addUser,
  updateUser,
  createComment,
  createDashboard,
  createDocSendIngestion,
  createGroup,
  createMention,
  createOauth2Creds,
  updateComment,
  updateDashboard,
  updateMention,
  createIntegration,
  updateIntegration,
  runFileSync,
  updateGroupSettings,
  refreshDashboardQuery,
  deleteDashboardQuery,
  addDashboardQuery,
  deleteUser,
  resendUserInvite,
  assignContentToDashboard,
  createUnassignedContent,
  deleteUnassignedContent,
  createWorkspace,
  deleteWorkspace,
  updateWorkspace,
  addWorkspaceToDashboard,
  removeWorkspaceFromDashboard,
  refreshWorkspaceScore,
  sendShareWorkspaceEmail,
  updateInvestmentThesis,
  updateFile,
  addEmailBodyToDashboard,
} from "../graphql/mutations";
import {
  getActions,
  getUserComments,
  getUserMentions,
  getDashboard,
  getGroupDashboards,
  getFiles,
  getUser,
  getUsers,
  getDashboardComments,
  getDocumentComments,
  getGroupComments,
  getCommentMentions,
  getGroupIntegrations,
  getPublicDashboard,
  getPublicDashboardComments,
  getPublicDashboardQueries,
  listIntegrationSubfolders,
  getGroupSettings,
  getDashboardQueries,
  listCrunchbaseCompanies,
  getCrunchbaseEntity,
  getActivityFeedEvents,
  getSimilarCompany,
  getGroupActiveSubscription,
  getUsage,
  getAllGroupSettings,
  getUnassignedContentItems,
  getUnassignedContentItem,
  getPublicWorkspace,
  getPublicFilesForDashboard,
  getUrlDestination,
} from "../graphql/queries";

import { Comment, Mention } from "../types/comments";
import { GroupSettings, PaginatedGroupSettings, User } from "../types/common";
import {
  ActivityFeedEvent,
  CompanyFile,
  CrunchbaseEntity,
  Dashboard,
  DashboardQuery,
  DashboardQuerySource,
  DocSendIngestion,
  EmailBody,
  InvestmentThesis,
  MarketMetric,
  UnassignedContent,
  Workspace,
} from "../types/files";
import {
  OAuth2CredsRequest,
  IntegrationState,
  ListIntegrationSubfoldersRequest,
} from "../types/integrations";
import instanceHelper from "../utils/InstanceHelper";
import { CustomerSubscription, UsageData } from "../API";

// QUERIES
export const getComments = (
  userId: string,
  setDbComments: (x: any) => void,
  setCommentsLoading: (x: any) => void,
  setCommentsError: (x: any) => void
) => {
  setCommentsLoading(true);
  (
    API.graphql(
      graphqlOperation(getUserComments, {
        userId,
      })
    ) as Promise<any>
  )
    .then((res) => {
      setDbComments(res?.data?.getUserComments);
      setCommentsLoading(false);
    })
    .catch((err) => {
      setCommentsError(err.errors && err.errors[0].message);
      setCommentsLoading(false);
    });
};

export const getMentions = (
  userId: string,
  setDbMentions: (x: any) => void,
  setMentionsLoading: (x: any) => void,
  setMentionsError: (x: any) => void
) => {
  setMentionsLoading(true);
  (
    API.graphql(
      graphqlOperation(getUserMentions, {
        email: userId,
      })
    ) as Promise<any>
  )
    .then((res: any) => {
      setDbMentions(res?.data?.getUserMentions);
      setMentionsLoading(false);
    })
    .catch((err) => {
      setMentionsLoading(false);
      setMentionsError(err.errors && err.errors[0].message);
    });
};

export const fetchDashboardById = async (
  dashboardId: string | null,
  setSingleDashboardData: (x: any) => void
) => {
  try {
    await (
      API.graphql(
        graphqlOperation(getDashboard, {
          id: dashboardId,
        })
      ) as Promise<any>
    ).then((res) => {
      setSingleDashboardData(res?.data?.getDashboard);
    });
  } catch (err) {
    console.log(err);
  }
};

export const getDashboards = async (group: string, title?: string) => {
  try {
    const result: any = await API.graphql(
      graphqlOperation(getGroupDashboards, {
        group,
        title,
      })
    );

    return result.data.getGroupDashboards.items;
  } catch (err) {
    console.log(err);
    return [];
  }
};

export const getDashboardQueriesFunc = async (dashboardId: string) => {
  try {
    const result: any = await API.graphql(
      graphqlOperation(getDashboardQueries, {
        dashboardId
      })
    );

    return result?.data?.getDashboardQueries?.items as DashboardQuery[];
  } catch (err) {
    console.log("error while getDashboardQueriesFunc", err);
    return [];
  }
};

export const getSimilarCompanyFunc = async (name: string) => {
  try {
    const result: any = await API.graphql(
      graphqlOperation(getSimilarCompany, {
        name
      })
    );

    return result?.data?.getSimilarCompany;
  } catch (err) {
    console.log(err);
    return [];
  }
};

export const getGroupDashboardQueriesFunc = async (group: string) => {
  try {
    const result: any = await API.graphql(
      graphqlOperation(getDashboardQueries, {
        group,
      })
    );

    const items = result?.data?.getDashboardQueries?.items;

    let nextToken = result?.data?.getDashboardQueries?.nextToken;
    while (nextToken) {
      const nextResult: any = await API.graphql(
        graphqlOperation(getDashboardQueries, {
          group,
          nextToken,
        })
      );
      nextToken = nextResult?.data?.getDashboardQueries?.nextToken;
      items.push(...nextResult?.data?.getDashboardQueries?.items);
    }

    return items?.filter((dq: any) => {
      return !dq.deletedAt;
    });
  } catch (err) {
    console.log(err);
    return [];
  }
};

export const listCrunchbaseCompaniesFunc = async (dashboardName: string) => {
  try {
    const result: any = await API.graphql(
      graphqlOperation(listCrunchbaseCompanies, {
        dashboardName
      })
    );

    return result.data.listCrunchbaseCompanies;
  } catch (err) {
    console.log(err);
    return [];
  }
};

export const getCrunchbaseEntityFunc = async (permalink: string, dashboardName?: string): Promise<CrunchbaseEntity | undefined> => {
  try {
    const result: any = await API.graphql(
      graphqlOperation(getCrunchbaseEntity, {
        permalink,
        dashboardName,
      })
    );

    return result.data.getCrunchbaseEntity;
  } catch (err) {
    console.log(err);
  }
};

export const getActivityFeedEventsFunc = async (group: string): Promise<ActivityFeedEvent[]> => {
  try {
    const result: any = await API.graphql(
      graphqlOperation(getActivityFeedEvents, {
        group
      })
    );

    return result?.data?.getActivityFeedEvents?.items || [];
  } catch (err) {
    console.log(err);
    return [];
  }
}


export const getFilesFunc = async (group: string, dashboardId?: string): Promise<CompanyFile[]> => {
  try {
    const result: any = await API.graphql(
      graphqlOperation(getFiles, {
        group,
        dashboardId,
      })
    );

    return result.data.getFiles.items;
  } catch (err) {
    console.log(err);
    return [];
  }
};

export const getDashboardById = async (id: string) => {
  try {
    const result: any = await API.graphql(
      graphqlOperation(getDashboard, { id })
    );
    return result?.data?.getDashboard;
  } catch (err) {
    return null;
  }
};

export const getPublicDashboardById = async (id: string) => {
  try {
    const result: any = await API.graphql({
      query: getPublicDashboard,
      variables: { id },
      authMode: "AWS_IAM",
    });

    return result?.data?.getPublicDashboard;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const getPublicDashboardCommentsFunc = async (id: string) => {
  try {
    const result: any = await API.graphql({
      query: getPublicDashboardComments,
      variables: { id },
      authMode: "AWS_IAM",
    });

    return result?.data?.getPublicDashboardComments;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const getPublicDashboardQueriesFunc = async (id: string) => {
  try {
    const result: any = await API.graphql({
      query: getPublicDashboardQueries,
      variables: { id },
      authMode: "AWS_IAM",
    });

    return result?.data?.getPublicDashboardQueries;
  } catch (err) {
    console.log(err);
    return [];
  }
};

export const getUserData = async (email: string, setUserData: any) => {
  try {
    await (
      API.graphql(
        graphqlOperation(getUser, {
          email,
        })
      ) as Promise<any>
    ).then((res) => {
      setUserData(res.data?.getUser);
    });
  } catch (err) {
    console.log("error fetching User data", err);
  }
};

export const getAllUsersFunc = async (group: string) => {
  try {
    const result: any = await API.graphql(
      graphqlOperation(getUsers, { group })
    );

    return result.data.getUsers.items;
  } catch (err) {
    console.log(err);
    return [];
  }
};

export const getCommentsFunc = async (documentId: string) => {
  try {
    const result: any = await API.graphql(
      graphqlOperation(getDocumentComments, {
        documentId,
      })
    );

    return result.data.getDocumentComments;
  } catch (err) {
    console.log(err);
    return [];
  }
};

export const getDashboardCommentsFunc = async (dashboardId: string) => {
  try {
    const result: any = await API.graphql(
      graphqlOperation(getDashboardComments, {
        id: dashboardId,
      })
    );

    return result.data.getDashboardComments;
  } catch (err) {
    console.log(err);
    return [];
  }
};

export const getCommentsFilteredFunc = async (
  group: string,
  hasMentions?: boolean,
  content?: string | undefined,
  userMentioned?: string | undefined,
  token?: string | undefined,
  resultsCount?: number | undefined
): Promise<any[]> => {
  try {
    const filter: any = {
      group: {
        eq: group,
      },
    };

    if (content) {
      filter.text = {
        contains: content,
      };
    }

    if (userMentioned) {
      hasMentions = true;
      filter.usersMentioned = {
        contains: userMentioned,
      };
    }

    if (hasMentions) {
      filter.usersMentioned = {
        ...filter.usersMentioned,
        size: {
          gt: 0,
        },
      };
    } else {
      filter.usersMentioned = {
        size: {
          eq: 0,
        },
      };
    }

    const result: any = await API.graphql(
      graphqlOperation(getGroupComments, {
        group,
        //sortDirection: "DESC", //TODO, see if I can set this on resolver
        //filter: filter,
        limit: 10,
        nextToken: token,
      })
    );

    if (!resultsCount) {
      resultsCount = 0;
    }

    resultsCount += result.data.getGroupComments.items.length;

    if (result.data.getGroupComments.nextToken && resultsCount < 20) {
      return [
        ...result.data.getGroupComments.items,
        ...(await getCommentsFilteredFunc(
          group,
          hasMentions,
          content,
          userMentioned,
          result.data.getGroupComments.nextToken
        )),
      ];
    }

    return result.data.getGroupComments.items;
  } catch (err) {
    console.log(err);
    return [];
  }
};

export const getMentionsFunc = async (userId: string) => {
  try {
    const result: any = await API.graphql(
      graphqlOperation(getUserMentions, {
        email: userId,
      })
    );

    return result.data.getUserMentions.items;
  } catch (err) {
    console.log(err);
    return [];
  }
};

export const getMentionsForCommentFunc = async (commentId: string) => {
  try {
    const result: any = await API.graphql(
      graphqlOperation(getCommentMentions, {
        commentId,
      })
    );

    return result.data.getCommentMentions;
  } catch (err) {
    console.log(err);
    return [];
  }
};

export const getUploadActionsByProjectFunc = async (projectId: string) => {
  try {
    const result: any = await API.graphql(
      graphqlOperation(getActions, {
        group: projectId,
      })
    );

    return result.data.getActions?.items;
  } catch (err) {
    console.log(err);
    return [];
  }
};

export const getIntegrationsFunc = async (group: string) => {
  try {
    const result: any = await API.graphql(
      graphqlOperation(getGroupIntegrations, { group })
    );
    return result?.data?.getGroupIntegrations?.items;
  } catch (err) {
    console.log(err);
    return [];
  }
};

export const listIntegrationSubfoldersFunc = async (
  params: ListIntegrationSubfoldersRequest
) => {
  try {
    const result: any = await API.graphql(
      graphqlOperation(listIntegrationSubfolders, params)
    );
    return result?.data?.listIntegrationSubfolders;
  } catch (err) {
    console.log(err);
    return [];
  }
};

// MUTATIONS

export const createUserFunc = async (user: User) => {
  await API.graphql({
    query: addUser,
    variables: user,
  });
};

export const updateUserFunc = async (user: User) => {
  const result: any = await API.graphql({
    query: updateUser,
    variables: user,
  });

  return result.data.updateUser;
};

export const deleteUserFunc = async (user: User) => {
  const result: any = await API.graphql({
    query: deleteUser,
    variables: {
      email: user.email,
      group: user.group,
      username: user.username,
    },
  });

  return result.data.deleteUser;
};

export const resendUserInviteFunc = async({ email, username }: { email: string, username: string }) => {
  return API.graphql({
    query: resendUserInvite,
    variables: {
      email,
      username,
    },
  });
};

export const createCommentFunc = async (commentData: Comment) => {
  try {
    const result: any = await API.graphql({
      query: createComment,
      variables: commentData,
    });

    return result.data.createComment;
  } catch (err) {
    console.log(err);
  }
};

export const updateCommentFunc = async (commentData: Comment) => {
  try {
    const result: any = await API.graphql({
      query: updateComment,
      variables: commentData,
    });
    return result.data.updateComment;
  } catch (err) {
    console.log(err);
  }
};

export const deleteCommentFunc = async (id: string) => {
  const mentions = await getMentionsForCommentFunc(id);
  await Promise.all(
    mentions.map(async (mention: any) => {
      await deleteMentionFunc(mention.id);
    })
  );

  await API.graphql({
    query: deleteComment,
    variables: { id },
  });
};

export const deleteCommentsFunc = async (entityId: string) => {
  const comments = await getCommentsFunc(entityId);

  await Promise.all(
    comments.map(async (comment: any) => {
      await deleteCommentFunc(comment.id);
    })
  );
};

export const createMentionFunc = async (mentionData: Mention) => {
  try {
    const result: any = await API.graphql({
      query: createMention,
      variables: mentionData,
    });

    return result.data.createMention;
  } catch (err) {
    console.log(err);
  }
};

export const updateMentionFunc = async (mentionData: Mention) => {
  try {
    const result: any = await API.graphql({
      query: updateMention,
      variables: mentionData,
    });

    return result.data.updateMention;
  } catch (err) {
    console.log(err);
  }
};
export const deleteMentionFunc = async (id: string) => {
  await API.graphql({
    query: deleteMention,
    variables: { id },
  });
};

export const setNullOrder = (newDashboardDetails: any) => {
  let length = -1;

  if (newDashboardDetails.documents) {
    newDashboardDetails.documents.forEach((document: any) => {
      if (document.order > length) {
        length = document.order;
      }
    });
  }
  if (newDashboardDetails.screenshots) {
    newDashboardDetails.screenshots.forEach((document: any) => {
      if (document.order > length) {
        length = document.order;
      }
    });
  }
  if (newDashboardDetails.selections) {
    newDashboardDetails.selections.forEach((document: any) => {
      if (document.order > length) {
        length = document.order;
      }
    });
  }

  if (newDashboardDetails.documents) {
    newDashboardDetails.documents = newDashboardDetails.documents.map(
      (document: any) => {
        if (!document.order && document.order !== 0) {
          document.order = length + 1;
          length += 1;
        }
        return document;
      }
    );
  }

  if (newDashboardDetails.screenshots) {
    newDashboardDetails.screenshots = newDashboardDetails.screenshots.map(
      (document: any) => {
        if (!document.order && document.order !== 0) {
          document.order = length + 1;
          length += 1;
        }
        return document;
      }
    );
  }

  if (newDashboardDetails.selections) {
    newDashboardDetails.selections = newDashboardDetails.selections.map(
      (document: any) => {
        if (!document.order && document.order !== 0) {
          document.order = length + 1;
          length += 1;
        }
        return document;
      }
    );
  }
  return newDashboardDetails;
};

export const createDashboardFunc = async (newDashboardDetails: any) => {
  try {
    newDashboardDetails = setNullOrder(newDashboardDetails);
    if (!newDashboardDetails.refreshData) {
      newDashboardDetails.refreshData = {};
    }
    newDashboardDetails.refreshData.lastUpdatedBy = instanceHelper.getId();
    await API.graphql({
      query: createDashboard,
      variables: newDashboardDetails,
    });
  } catch (err) {
    console.log("error while creating dashboard", err);
    return false;
  }

  return true;
};

export const updateDashboardFunc = async (newDashboardDetails: any) => {
  try {
    newDashboardDetails = setNullOrder(newDashboardDetails);
    if (!newDashboardDetails.refreshData) {
      newDashboardDetails.refreshData = {};
    }
    newDashboardDetails.refreshData.lastUpdatedBy = instanceHelper.getId();
    await API.graphql({
      query: updateDashboard,
      variables: newDashboardDetails,
    });
  } catch (err) {
    console.log("error while updating dashboard", err);
    return false;
  }

  return true;
};

export const deleteDashboardFunc = async (id: string) => {
  await API.graphql({
    query: deleteDashboard,
    variables: { id },
  });
};

export const addDashboardQueryFunc = async (dashboardId: string, title: string, group: string, query: string, answer: string, history: string | undefined, order: number, manualOverride: boolean, source: DashboardQuerySource, deleted?: boolean) => {
  try {
    const result: any = await API.graphql({
      query: addDashboardQuery,
      variables: {
        dashboardId,
        title,
        group,
        query,
        answer,
        history,
        order,
        manualOverride,
        source,
        deletedAt: deleted ? new Date().toISOString() : undefined,
      },
    });

    return result.data.addDashboardQuery as any;
  } catch (err) {
    console.log("error while addDashboardQuery", err);
  }
}

export const refreshDashboardQueryFunc = async (dashboardId: string, title: string, group: string, query: string, order: number, source: DashboardQuerySource) => {
  try {
    const result: any = await API.graphql({
      query: refreshDashboardQuery,
      variables: {
        dashboardId,
        title,
        group,
        query,
        order,
        source,
      },
    });

    return result.data.refreshDashboardQuery as any;
  } catch (err) {
    console.log("error while refreshDashboardQueryFunc", err);
  }
}

export const deleteDashboardQueryFunc = async (id: string, title: string, group: string) => {
  try {
    const result: any = await API.graphql({
      query: deleteDashboardQuery,
      variables: {
        id,
        title,
        group
      },
    });

    return result.data.deleteDashboardQuery as any;
  } catch (err) {
    console.log("error while deleteDashboardQueryFunc", err);
  }
}

export const createDocSendIngestionFunc = async (
  newDocSendIngestionDetails: DocSendIngestion
) => {
  try {
    await API.graphql({
      query: createDocSendIngestion,
      variables: newDocSendIngestionDetails,
    });
  } catch (err) {
    console.log("error while creating docsend ingestion", err);
    return false;
  }

  return true;
};

export const createOauth2CredsFunc = async (
  newOauth2CredsDetails: OAuth2CredsRequest
) => {
  try {
    return API.graphql({
      query: createOauth2Creds,
      variables: newOauth2CredsDetails,
    });
  } catch (err) {
    console.log("error while creating oauth2 creds", err);
    throw err;
  }
};

export const createIntegrationFunc = async (data: IntegrationState) => {
  try {
    const result: any = await API.graphql({
      query: createIntegration,
      variables: data,
    });

    return result.data.createIntegration;
  } catch (err) {
    console.log("error while creating integration", err);
  }
};

export const updateIntegrationFunc = async (data: IntegrationState) => {
  try {
    const result: any = await API.graphql({
      query: updateIntegration,
      variables: data,
    });

    return result.data.updateIntegration;
  } catch (err) {
    console.log("error while updating integration", err);
  }
};

export const runFileSyncFunc = async (group: string) => {
  try {
    await API.graphql({
      query: runFileSync,
      variables: {
        group,
      },
    });
  } catch (err) {
    console.log("error while running file sync", err);
  }
};

export const getGroupSettingsFunc = async (params: { group?: string }): Promise<GroupSettings | undefined> => {
  try {
    const result: any = await API.graphql({
      query: getGroupSettings,
      variables: params,
    });

    return result.data.getGroupSettings as GroupSettings;
  } catch (err) {
    console.log("error while getting group settings", err);
  }
};

export const updateGroupSettingsFunc = async (params: GroupSettings): Promise<GroupSettings | undefined> => {
  try {
    const result: any = await API.graphql({
      query: updateGroupSettings,
      variables: params,
    });

    return result.data.getGroupSettings as GroupSettings;
  } catch (err) {
    console.log("error while updating group settings", err);
  }
};

export const getGroupActiveSubscriptionFunc = async({ group }: { group: string }): Promise<CustomerSubscription | undefined> => {
  try {
    const result: any = await API.graphql({
      query: getGroupActiveSubscription,
      variables: {
        group,
      },
    });

    return result.data.getGroupActiveSubscription as CustomerSubscription;
  } catch (err) {
    console.log("error while getting group active subscription", err);
  }
};

export const getUsageFunc = async({ group }: { group: string }): Promise<UsageData | undefined> => {
  try {
    const result: any = await API.graphql({
      query: getUsage,
      variables: {
        group,
      },
    });

    return result.data.getUsage as UsageData;
  } catch (err) {
    console.log("error while getting group usage", err);
  }
};

export const getAllGroupSettingsFunc = async ({ nextToken, limit }: { nextToken?: string, limit?: Number}): Promise<PaginatedGroupSettings> => {
  const result: any = await API.graphql({
    query: getAllGroupSettings,
    variables: {
      nextToken,
      limit,
    },
  });

  return result.data.getAllGroupSettings as PaginatedGroupSettings;
};

export const createGroupFunc = async (variables: GroupSettings): Promise<GroupSettings> => {
  const result: any = await API.graphql({
    query: createGroup,
    variables,
  });

  return result.data.createGroup as GroupSettings;
};

export const getUnassignedContentItemsFunc = async({ group }: { group: string }): Promise<UnassignedContent[] | undefined> => {
  try {
    const result: any = await API.graphql({
      query: getUnassignedContentItems,
      variables: {
        group,
      },
    });

    return result.data.getUnassignedContentItems as UnassignedContent[];
  } catch (err) {
    console.log("error while getting unassigned content items", err);
  }
};

export const getUnassignedContentItemFunc = async(id: string): Promise<UnassignedContent | undefined> => {
  try {
    const result: any = await API.graphql({
      query: getUnassignedContentItem,
      variables: {
        id,
      },
    });

    return result.data.getUnassignedContentItem as UnassignedContent;
  } catch (err) {
    console.log("error while getting unassigned content item", err);
  }
};

export const assignContentToDashboardFunc = async({ unassignedContentId, dashboardId }: { unassignedContentId: string, dashboardId: string }): Promise<any | undefined> => {
  try {
    const result: any = await API.graphql({
      query: assignContentToDashboard,
      variables: {
        unassignedContentId,
        dashboardId,
      },
    });

    return result.data.assignContentToDashboard as any;
  } catch (err) {
    console.log("error while assignContentToDashboard", err);
  }
};

export const createUnassignedContentFunc = async(unassignedContent: UnassignedContent): Promise<any | undefined> => {
  try {
    const result: any = await API.graphql({
      query: createUnassignedContent,
      variables: unassignedContent,
    });

    return result.data.createUnassignedContent as any;
  } catch (err) {
    console.log("error while createUnassignedContent", err);
  }
};

export const deleteUnassignedContentFunc = async({ id, group }: { id: string, group: string }): Promise<any | undefined> => {
  try {
    const result: any = await API.graphql({
      query: deleteUnassignedContent,
      variables: {
        id,
        group,
      },
    });

    return result.data.deleteUnassignedContent as any;
  } catch (err) {
    console.log("error while deleteUnassignedContent", err);
  }
};

export const createWorkspaceFunc = async({ name, email, website, isPublic }: { name: string, email: string, website: string, isPublic: boolean }): Promise<any | undefined> => {
  try {
    const result: any = await API.graphql({
      query: createWorkspace,
      variables: {
        name,
        email,
        website,
        isPublic,
      },
    });

    return result.data.createWorkspace as any;
  } catch (err) {
    console.log("error while createWorkspace", err);
  }
};

export const deleteWorkspaceFunc = async({ id }: { id: string }): Promise<any | undefined> => {
  try {
    const result: any = await API.graphql({
      query: deleteWorkspace,
      variables: {
        id,
      },
    });

    return result.data.deleteWorkspace as any;
  } catch (err) {
    console.log("error while deleteWorkspace", err);
  }
};

export const updateWorkspaceFunc = async({ id, name, email, website, isPublic, investmentThesis }: { id: string, name: string, email: string, website: string, isPublic: boolean, investmentThesis: InvestmentThesis }): Promise<any | undefined> => {
  try {
    const result: any = await API.graphql({
      query: updateWorkspace,
      variables: {
        id,
        name,
        email,
        website,
        isPublic,
        investmentThesis,
      },
    });

    return result.data.updateWorkspace as any;
  } catch (err) {
    console.log("error while updateWorkspace", err);
  }
};

export const addWorkspaceToDashboardFunc = async({ dashboardId, workspaceId, group }: { dashboardId: string, workspaceId: string, group: string }): Promise<any | undefined> => {
  try {
    const result: any = await API.graphql({
      query: addWorkspaceToDashboard,
      variables: {
        dashboardId,
        workspaceId,
        group,
      },
    });

    return result.data.addWorkspaceToDashboard as any;
  } catch (err) {
    console.log("error while addWorkspaceToDashboard", err);
  }
};

export const removeWorkspaceFromDashboardFunc = async({ dashboardId, workspaceId, group }: { dashboardId: string, workspaceId: string, group: string }): Promise<any | undefined> => {
  try {
    const result: any = await API.graphql({
      query: removeWorkspaceFromDashboard,
      variables: {
        dashboardId,
        workspaceId,
        group,
      },
    });

    return result.data.removeWorkspaceFromDashboard as any;
  } catch (err) {
    console.log("error while removeWorkspaceFromDashboard", err);
  }
};

export const getPublicWorkspaceFunc = async ({ id, group }: { id: string, group?: string }): Promise<{workspace: Workspace, dashboards: Dashboard[]} | undefined> => {
  try {
    const result: any = await API.graphql({
      query: getPublicWorkspace,
      variables: {
        id,
        group
      },
      authMode: "AWS_IAM",
    });

    return result?.data?.getPublicWorkspace as {workspace: Workspace, dashboards: Dashboard[]};
  } catch (err) {
    console.log("error while getPublicWorkspace", err);
  }
};

export const refreshWorkspaceScoreFunc = async({ dashboardId, workspaceId, group, query }: { dashboardId: string, workspaceId: string, group: string, query: string }): Promise<any | undefined> => {
  try {
    const result: any = await API.graphql({
      query: refreshWorkspaceScore,
      variables: {
        dashboardId,
        workspaceId,
        group,
        query
      },
    });

    return result.data.refreshWorkspaceScore as any;
  } catch (err) {
    console.log("error while refreshWorkspaceScore", err);
  }
};

export const sendShareWorkspaceEmailFunc = async({ workspaceId, toAddress, replyToAddress, dashboardIds }: { workspaceId: string, toAddress: string, replyToAddress: string, dashboardIds: string[] }): Promise<any | undefined> => {
  try {
    const result: any = await API.graphql({
      query: sendShareWorkspaceEmail,
      variables: {
        workspaceId,
        toAddress,
        replyToAddress,
        dashboardIds
      },
    });

    return result.data.sendShareWorkspaceEmail as any;
  } catch (err) {
    console.log("error while sendShareWorkspaceEmail", err);
  }
};

export const updateInvestmentThesisFunc = async({ id, group, investmentThesis }: { id: string, group: string, investmentThesis: InvestmentThesis }): Promise<any | undefined> => {
  try {
    const result: any = await API.graphql({
      query: updateInvestmentThesis,
      variables: {
        id,
        group,
        investmentThesis,
      },
      authMode: "AWS_IAM",
    });

    return result.data.updateInvestmentThesis as any;
  } catch (err) {
    console.log("error while updateInvestmentThesis", err);
  }
};

export const updateFileFunc = async({ id, group, documentCategory, status, dashboardId, marketMetrics, isDeck}: { id: string, group: string, documentCategory?: string, status: string, dashboardId?: string, marketMetrics?: MarketMetric[], isDeck?: boolean}): Promise<any | undefined> => {
  try {
    const result: any = await API.graphql({
      query: updateFile,
      variables: {
        id,
        group,
        documentCategory,
        status,
        dashboardId,
        marketMetrics,
        isDeck,
      },
    });

    return result.data.updateFile as any;
  } catch (err) {
    console.log("error while updateFile", err);
  }
};

export const getPublicFilesForDashboardFunc = async(dashboardId: string): Promise<CompanyFile[] | undefined> => {
  try {
    const result: any = await API.graphql({
      query: getPublicFilesForDashboard,
      variables: {
        dashboardId,
      },
      authMode: "AWS_IAM",
    });

    return result.data.getPublicFilesForDashboard as any;
  } catch (err) {
    console.log("error while getPublicFilesForDashboard", err);
  }
};

export const addEmailBodyToDashboardFunc = async({ id, emailBody }: { id: string, emailBody: EmailBody }): Promise<any | undefined> => {
  try {
    const result: any = await API.graphql({
      query: addEmailBodyToDashboard,
      variables: {
        id,
        emailBody,
      },
    });

    return result.data.addEmailBodyToDashboard as any;
  } catch (err) {
    console.log("error while addEmailBodyToDashboard", err);
  }
};

export const getUrlDestinationFunc = async({ url }: { url: string }): Promise<any | undefined> => {
  try {
    const result: any = await API.graphql({
      query: getUrlDestination,
      variables: { url },
    });

    return result.data.getUrlDestination as any;
  } catch (err) {
    console.log("error while getUrlDestination", err);
  }
};
