import React, { Fragment, useEffect, useState } from 'react';
import { Box, Stack, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { AnswerQuestionResponse } from '../../../types/search';
import moment from 'moment';
import classnames from 'classnames';

export type FundingAnsweredQuestion = Omit<AnswerQuestionResponse, 'answer'> & {
    question: string,
    answer?: {
        investor_list: {
            name: string,
            type: string,
            linkedin_profile_url: string | null,
        }[] | null,
        announced_date: {
            month: number,
            year: number,
            day: number,
        } | null,
        funding_type?: string,
        money_raised?: number,
    }[] | null;
    time: Date,
};

const useStyles = makeStyles((theme) => ({
    fundingHeading: {
        fontWeight: 'bolder',
        fontSize: '1rem',
        color: '#9e9e9e',
    },
    fundingText: {
        fontFamily: 'Inter',
        fontWeight: '500',
        fontSize: '0.95rem',
        color: '#666666',
    },
    textClamp: {
        color: '#666666',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: '3',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    url: {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        textDecoration: 'none',
        "&:hover": {
            textDecoration: 'underline',
        }
    },
}));

const FundingListAnswer: React.FC<{ answeredQuestion: FundingAnsweredQuestion, showPriorOnly?: boolean }> = ({ answeredQuestion, showPriorOnly }) => {
    const classes = useStyles();
    const [fundingList, setFundingList] = useState<any[]>([]);

    useEffect(() => {
        if (Array.isArray(answeredQuestion?.answer) && !!(answeredQuestion?.answer as any[])?.length) {
            setFundingList(answeredQuestion.answer?.sort((curr: any, next: any) => {
                if (!!curr.announced_date && !!next.announced_date) {
                    const currMoment = moment({
                        year: curr.announced_date.year,
                        month: curr.announced_date.month - 1,
                        day: curr.announced_date.day,
                    });
                    const nextMoment = moment({
                        year: next.announced_date.year,
                        month: next.announced_date.month - 1,
                        day: next.announced_date.day,
                    });

                    // If we have the same date, sort by money_raised then number of investors
                    if (currMoment.isSame(nextMoment)) {
                        if (curr.money_raised === null && next.money_raised === null) {
                            return curr.number_of_investor > next.number_of_investor ? -1 : 1;
                        }

                        return Number(curr.money_raised) > Number(next.money_raised) ? -1 : 1;
                    }

                    return currMoment.isBefore(nextMoment) ? 1 : -1;
                }

                return 0;
            }));
        }
    }, [answeredQuestion.answer]);

    return (<>
        <Stack direction="column">
            {Array.isArray(answeredQuestion?.answer) && !!(answeredQuestion?.answer as any[])?.length ?
                fundingList?.map((funding, i) => {
                    const dateString = !!funding.announced_date ? moment({
                        year: funding.announced_date!.year,
                        month: funding.announced_date!.month - 1,
                        day: funding.announced_date!.day,
                    }).format('MMM D, YYYY') : null;

                    if (showPriorOnly && (i > 1))
                        return (<Fragment key={'search-result-list-105-' + i} />);

                    return (<Fragment key={'search-result-list-107-' + i}>
                        <Stack direction="row" justifyContent="space-between" mt={(!showPriorOnly || i === 0) ? `${8}px !important` : 'unset'}>
                            {i === 0 ? (
                                <Typography className={classes.fundingHeading}>
                                    {"Last round: "}
                                    <Typography component="span" className={classes.fundingText} display="inline">
                                        {`${funding.funding_type}${!!dateString ? (' - ' + dateString) : ''}`}
                                    </Typography>
                                </Typography>
                            ) : !showPriorOnly && (
                                <Typography className={classes.fundingText} display="inline">
                                    {`${funding.funding_type}${!!dateString ? (' - ' + dateString) : ''}`}
                                </Typography>
                            )}
                            {funding.money_raised && (
                                <Typography className={classes.fundingText}>
                                    {`US$${(funding.money_raised >= 1e6)
                                        ? (funding.money_raised / 1e6).toFixed(1) + 'M': (funding.money_raised >= 1e3)
                                            ? (funding.money_raised / 1e3).toFixed(1) + 'K': funding.money_raised.toString()}`.replace('.0', '')}
                                </Typography>
                            )}
                        </Stack>
                        {!!funding.investor_list?.length &&
                            <Box className={classnames((i === 0) && classes.textClamp)} width="80% !important" mt="-2px !important">
                                <Typography className={classes.fundingHeading} fontWeight="500 !important">
                                    {"Investors: "}
                                    {(funding.investor_list as any[])?.map((investor: any, i) => (<Fragment key={'search-result-list-1174-' + i}>
                                        {investor.linkedin_profile_url ? (
                                            <Typography
                                                component="a"
                                                className={classes.url}
                                                href={investor.linkedin_profile_url}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                display="inline"
                                            >{investor.name}</Typography>
                                        ) : (
                                            <Typography component="span" className={classes.fundingText} display="inline">{investor.name}</Typography>
                                        )}
                                        {i < (funding.investor_list?.length || 0) - 1 && (
                                            <Typography component="span" className={classes.fundingText} display="inline">, </Typography>
                                        )}
                                    </Fragment>))}
                                </Typography>
                            </Box>
                        }
                        {i === 0 && fundingList.length > 1 && !showPriorOnly && (
                            <Box>
                                <Typography className={classes.fundingHeading} mt={3}>
                                    {"Prior rounds:"}
                                </Typography>
                            </Box>
                        )}
                    </Fragment>);
            }) : (
                <Stack direction="column" height={24} overflow="hidden" mt={1}>
                    <Typography fontFamily="Inter">{'No funding data available.'}</Typography>
                </Stack>
            )}
        </Stack>
    </>);
}

export default FundingListAnswer;
