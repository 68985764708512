import React, { useContext, useMemo, useState } from "react";
import { Box, ClickAwayListener, Divider, IconButton, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { scrollbarStyle } from '../../../shared/dashboard';
// import DashboardTimeline from "../../organisms/dashboard-timeline/DashboardTimeline";
import DashboardItems from "../../organisms/dashboard-details/DashboardItems";
import { DashboardContext } from "../../../contexts/DashboardContext";
import DashboardMetricsTimeline from "../dashboard-timeline/DashboardMetricsTimeline";
import DashboardDeck from "../../molecules/dashboard-deck/DashboardDeck";
import ContentActivityFeed from "../../molecules/home-table-view/ContentActivityFeed";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import { Dashboard, EmailBody } from "../../../types/files";
import classNames from "classnames";
import DashboardQueries from "../dashboard-queries/DashboardQueries";

const useStyles = makeStyles((theme) => ({
    panel: {
        height: '100%',
        width: '100%',
        minWidth: 'calc(100vw - 94px - 550px)',
        maxWidth: 'calc(100vw - 94px - 135px)',
        padding: 8,
        transition: 'width .3s ease',
        overflowX: 'hidden',
        overflowY: 'auto',
        ...scrollbarStyle,
    },
    expanded: {
        height: '100%',
        width: '100%',
        minWidth: 'calc(100vw - 94px - 156px)',
        maxWidth: '100%',
    },
    title: {
        fontFamily: 'Inter',
        fontSize: '1.3rem !important',
        fontWeight: 'bold',
        color: theme.colors.neutral['700'],
    },
    activity: {
        overflowX: 'hidden',
        overflowY: 'auto',
        zIndex: 1300,
        ...scrollbarStyle,
    },
    feed: {
        width: '100%',
        height: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
        ...scrollbarStyle,
    },
}));

const DashboardOverview: React.FC<{}> = () => {
    const classes = useStyles();
    const { dashboard, isPublicView } = useContext(DashboardContext);
    const [expanded, setExpanded] = useState<boolean>(false);

    const height = 'calc(100vh - 140px)';

    const sortedActivities = useMemo(() => {
        const activityItems: { dashboard: Dashboard, emailBody?: EmailBody, createdAt: string, type: 'attached' | 'created' }[] = [];

        if (!!dashboard) {
            activityItems.push({ dashboard, createdAt: String(dashboard.createdAt), type: 'created', });
            dashboard?.emailBodies?.forEach(emailBody => activityItems.push({
                dashboard, emailBody, createdAt: String(emailBody.createdAt), type: 'attached',
            }));
        }

        return activityItems.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
    }, [dashboard]);

    if (!dashboard)
        return (<></>);

    return (<>
        <Stack direction="row" alignItems="flex-start" width="100%" height="100%">
            <Stack direction="column" className={classes.panel} alignItems="stretch" justifyContent="flex-start">
                <Box className={classNames(expanded && classes.expanded)}>
                    <Box padding="12px 0" />
                    <DashboardQueries />
                    <Divider sx={{ mb: "16px !important"}}/>
                    <DashboardMetricsTimeline />
                    {/* <DashboardTimeline /> */}
                    {/* <Divider sx={{ mb: "16px !important"}}/> */}
                    {!isPublicView && (<DashboardItems />)}
                    <Box display="none">
                        <DashboardDeck />
                    </Box>
                </Box>
            </Stack>
            {expanded ? (<>
                <ClickAwayListener onClickAway={(e) => { e.stopPropagation(); setExpanded(false); }}>
                    <Stack className={classes.activity} width="100%" height={height} p={1}
                        sx={{ boxShadow: '-16px  20px 28px #AAAAAA' }}>
                        <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between" mb={1}>
                            <Typography className={classes.title} ml={1}>
                                {`Your ${dashboard.title} feed`}
                            </Typography>
                            <Box mr="16px !important">
                                <IconButton onClick={(e) => {
                                    e.stopPropagation();
                                    setExpanded(false);
                                }}> <ArrowForwardIosIcon /> </IconButton>
                            </Box>
                        </Stack>
                        <Divider sx={{ width: '100%' }} />
                        <Stack direction="column" className={classes.feed} alignItems="stretch" mt={2}>
                            {sortedActivities.map((activity, i) => (
                                <ContentActivityFeed activity={activity} vertical
                                    key={`activity-feed-116-${i}-` + activity.dashboard.id}/>
                            ))}
                        </Stack>
                    </Stack>
                </ClickAwayListener>
            </>) : (<>
                <Divider orientation="vertical" sx={{ height, marginTop: '-8x !important' }} />
                <Stack direction="column" className={classes.activity} spacing={3} alignItems="center" mt={1} px={3}
                    sx={{ width: 'fit-content', height: '100%', cursor: 'pointer'}}
                    onClick={(e) => {
                        e.stopPropagation();
                        setExpanded(true);
                    }}>
                    <IconButton onClick={(e) => {
                        e.stopPropagation();
                        setExpanded(true);
                    }}> <ArrowBackIosIcon /> </IconButton>
                    <FormatAlignRightIcon sx={{ fill: 'rgba(0, 0, 0, 0.5)' }} />
                </Stack>
            </>)}
        </Stack>
    </>);
}

export default DashboardOverview;