import React, { useContext } from "react";
import { DashboardContext } from "../../../contexts/DashboardContext";
import useDashboardQuery from "../../../hooks/useDashboardQuery";
import LinkedQueryContainer from "../dashboard-query-container/LinkedQueryContainer";

const UrlQuery: React.FC<{
    queryTitle: string,
    onEditing?: (queryTitle?: string) => void,
}> = ({ queryTitle, onEditing }) => {
    const { isPublicView } = useContext(DashboardContext);
    const { queryAnswer, updateQuery } = useDashboardQuery(queryTitle);

    return (<>
        <LinkedQueryContainer
            queryAnswer={queryAnswer}
            isPublic={isPublicView}
            onEditing={onEditing}
            onEdit={updateQuery}
            linkOnly
        />
    </>);
}

export default UrlQuery;
