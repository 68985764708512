import MailIcon from '@mui/icons-material/MailOutline';
import { Badge, Fab, } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { API, graphqlOperation } from "aws-amplify";
import classNames from "classnames";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { v4 as uuidv4 } from "uuid";
import { AuthContext } from "../../contexts/AuthContext";
import { DashboardsContext } from "../../contexts/DashboardsContext";
import { GroupSettingsContext } from "../../contexts/GroupSettingsContext";
import { onDocSendIngestionCreation, onUnassignedContent } from "../../graphql/subscriptions";
import { getUserEmail } from "../../helpers/authUser";
import useEmailDealsExtraction, { EmailDeal } from "../../hooks/useEmailDealsExtraction";
import useFileUploader from "../../hooks/useFileUploader";
import useStaticDashboard from "../../hooks/useStaticDashboard";
import { assignContentToDashboardFunc, createDashboardFunc, createDocSendIngestionFunc, getDashboards, getUnassignedContentItemsFunc } from "../../lib/helper";
import { User } from "../../types/auth";
import { Dashboard, DocSendIngestion, UnassignedContent, UnassignedContentType } from "../../types/files";
import ArrayUtils from "../../utils/ArrayUtils";
import UnassignedContentsModal from "../modals/dashboard-unassigned-contents/UnassignedContentsModal";

export type UnassignedContentData = {
    content: UnassignedContent,
    deals: EmailDeal[],
    exists: boolean[],
    createdAt: string,
};

const useStyles = makeStyles((theme) => ({
    badge: {
        '& .MuiBadge-badge': {
            position: 'absolute',
            top: 8,
            right: 8,
            width: 24,
            height: 24,
            padding: '12px 6px',
            borderRadius: '50%',
            background: theme.colors.orange['400'],
            color: 'white',
            fontSize: '0.85rem',
            fontWeight: 'bold',
            zIndex: 1100,
        },
    },
    incomingContentsButton: {
        height: 60,
        width: 60,
        boxShadow: 'none',
        textTransform: 'none',
        background: theme.colors.primary['500'],
        "&:hover": {
            background: theme.colors.primary['400'],
        }
    },
    incomingContentsOpen: {
        boxShadow: theme.shadows[5],
        background: theme.colors.primary['600'],
    },
    icon: {
        height: 32,
        width: 32,
        fill: 'white',
    },
}));

const UnassignedContentsBadge: React.FC<{}> = () => {
    const classes = useStyles();
    const {user, userGroup} = useContext(AuthContext);
    const { externalSync, autoIngestFromEmail } = useContext(GroupSettingsContext);
    const { dashboards, setDashboards } = useContext(DashboardsContext);
    const { extractEmailDeals } = useEmailDealsExtraction();
    const { saveWebQueryAnswer } = useStaticDashboard();
    const { docsendIngestion } = useFileUploader({});
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [incomingContents, setIncomingContents] = useState<UnassignedContent[]>([]);
    const [outgoingContents, setOutgoingContents] = useState<UnassignedContent[]>([]);
    const [contentsData, setContentsData] = useState<UnassignedContentData[]>([]);
    const [ingestableDeals, setIngestableDeals] = useState<{content: UnassignedContent, deals: EmailDeal[]}[]>([]);
    const [isIngesting, setIngesting] = useState<boolean>(false);

    const unassignedContentSubscriptionRef = useRef<any>(null);
    const onDocSendIngestionCreationRef = useRef<any>(null);
    const menuOpen = Boolean(anchorEl);

    const filteredContents = useMemo(() => incomingContents.filter(content => {
        if (content?.type === UnassignedContentType.DOCSEND) {
            const parsed = JSON.parse(content?.body || '{}') as DocSendIngestion;
            const lookUpDashboard = dashboards.find(dashboard => parsed?.existingDashboardId ?
                dashboard.id === parsed?.existingDashboardId : dashboard.title === parsed?.newDashboardTitle);

            return !!lookUpDashboard;
        }

        return true;
    }), [dashboards, incomingContents]);

    const updateSubscribedContents = useCallback(async (
        incomingContents: UnassignedContent[],
        outgoingContents: UnassignedContent[],
        updatedContent?: UnassignedContent|null
    ) => {
        if (!updatedContent?.id)
            return;

        const lookUpIncomingContent = incomingContents.find(content => content.id === updatedContent.id);
        const lookUpOutgoingContent = outgoingContents.find(content => content.id === updatedContent.id);

        if (!lookUpOutgoingContent && !lookUpIncomingContent)
            setIncomingContents(prev => [updatedContent, ...prev]);
    }, []);

    const removedSubscribedContents = useCallback(async (updatedContent?: UnassignedContent|null) => {
        if (!updatedContent?.id)
            return;

        setIncomingContents(prev => [...prev].filter(content => content.id !== updatedContent!.id));
    }, []);

    const subscribeUnassignedContents = useCallback(async (
        incomingContents: UnassignedContent[],
        outgoingContents: UnassignedContent[]
    ) => {
        if (unassignedContentSubscriptionRef.current)
            unassignedContentSubscriptionRef.current.unsubscribe();

        if (userGroup) {
            unassignedContentSubscriptionRef.current = await (API.graphql(
                graphqlOperation(onUnassignedContent, { group: userGroup })) as any)
                .subscribe({ next: ({ value }: any) => updateSubscribedContents(
                    incomingContents, outgoingContents, value?.data?.onUnassignedContent
                )});
        }
    }, [userGroup, updateSubscribedContents]);

    const subscribeDocsendIngestionCreation = useCallback(async () => {
        if (onDocSendIngestionCreationRef.current)
            onDocSendIngestionCreationRef.current.unsubscribe();

        if (userGroup) {
            onDocSendIngestionCreationRef.current = await (API.graphql(
                graphqlOperation(onDocSendIngestionCreation, { group: userGroup })) as any)
                .subscribe({ next: ({ value }: any) => removedSubscribedContents(
                    value?.data?.onDocSendIngestionCreation
                )});
        }
    }, [userGroup, removedSubscribedContents]);

    const moveContent = useCallback((contentId: string) => {
        const lookUpContent = incomingContents.find(content => content.id === contentId);

        if (!!lookUpContent) {
            setOutgoingContents(prev => [lookUpContent, ...prev]);
            setIncomingContents(prev => [...prev]
                .filter(content => content.id !== lookUpContent.id));
        }
    }, [incomingContents]);

    const handleAssignContent = useCallback(async (dashboardId: string, unassignedContentId: string) => {
        moveContent(unassignedContentId);
        await assignContentToDashboardFunc({unassignedContentId, dashboardId});
    }, [moveContent]);

    const handleDocsendIngestion = useCallback(async (unassignedContentId: string, docsend: DocSendIngestion, password: string) => {
        moveContent(unassignedContentId);
        return await createDocSendIngestionFunc({ ...docsend,
            email: getUserEmail(user as User),
            group: userGroup,
            password,
        });
    }, [moveContent, user, userGroup]);

    const handleRemoveContent = useCallback(async (unassignedContentId: string) => {
        moveContent(unassignedContentId);
        // await deleteUnassignedContentFunc({id: unassignedContentId, group: userGroup});
        await Promise.resolve({id: unassignedContentId, group: userGroup});
    }, [moveContent, userGroup]);

    const getContentsData = useCallback(() => new Promise<UnassignedContentData[]>(async (resolve) => {
        const contentsData: UnassignedContentData[] = [];

        for (const content of filteredContents) {
            if (!outgoingContents.find(out => out.id === content.id))
                contentsData.push({ createdAt: String(content.createdAt || ''), content, deals: [], exists: [] });
        }

        setContentsData(contentsData);

        for (const contentData of contentsData) {
            const { content } = contentData;

            if (content.type === UnassignedContentType.FULL_EMAIL) {
                contentData.deals = await extractEmailDeals(content);
                contentData.exists = contentData.deals.map(deal => !!dashboards.find(dashboard => dashboard.title.toLowerCase() === deal.name.toLowerCase()));
            }

            setContentsData(prev => prev.map(ctx => ctx.content.id === content.id ? contentData : ctx));
        }

        resolve(contentsData);
    // eslint-disable-next-line
    }), [filteredContents, outgoingContents, dashboards]);

    const ingestEmailDeal = useCallback(async (content: UnassignedContent, deal: EmailDeal) => {
        const isExternalLink = !!deal.deck?.name && !deal.deck?.name?.toLowerCase()?.includes('.pdf');
        const newDashboard = {
            createdAt: (new Date()).toISOString(),
            updatedAt: (new Date()).toISOString(),
            email: getUserEmail(user!),
            group: userGroup!,
            project: userGroup!,
            id: uuidv4(),
            title: deal.name,
            documents: [],
            selections: [],
            screenshots: [],
            notes: [],
            lastUpdatedBy: '',
            shouldRefresh: false,
            externalLinks: isExternalLink ? [deal.deck?.name] : null,
            shouldSyncExternally: externalSync,
            refreshData: null,
            summary: '',
            status: '',
            investmentStage: deal.stage,
        } as Dashboard;

        if (!dashboards.find(dashboard => dashboard.title.trim() === deal.name.trim())) {
            return await new Promise<string|null>((resolve) => {
                createDashboardFunc(newDashboard).then(async () => {
                    Promise.all([
                        !!deal.website ? saveWebQueryAnswer(newDashboard, deal.website) : Promise.resolve(true),
                        handleAssignContent(newDashboard.id!, content.id!),
                        docsendIngestion(newDashboard, deal.deck?.name),
                    ]).then(() =>
                        resolve(`${newDashboard?.title} successfully created.`)
                    ).catch(() => resolve(null));
                }).catch(() => resolve(null));
            });
        }

        return null;
    }, [user, userGroup, externalSync, dashboards, saveWebQueryAnswer, docsendIngestion, handleAssignContent]);

    const handleSaveEmailDeals = useCallback(async (content: UnassignedContent, dashboard?: Dashboard, deals?: EmailDeal[]) => {
        if (!dashboard && !!deals?.length) {
            const promises: Promise<any>[] = [];

            deals.forEach(deal => promises.push(ingestEmailDeal(content, deal)));
            await Promise.all(promises);
        } else {
            await handleAssignContent(dashboard?.id!, content.id!);
        }
        await handleRemoveContent(content.id!);
        setContentsData(prev => [...prev].filter(ctx => ctx.content.id !== content.id));
    }, [handleAssignContent, handleRemoveContent, ingestEmailDeal]);

    const refreshDashboards = useCallback(async () => {
        getDashboards(userGroup).then((dashboardsData) =>
            setDashboards(ArrayUtils.sortByDescending(dashboardsData, 'createdAt')));
    // eslint-disable-next-line
    }, [userGroup]);

    const debouncedAutoIngestion = useDebouncedCallback(() => {
        setIngesting(true);

        const timeOut = setTimeout(async () => {
            const promises: Promise<any>[] = [];

            clearTimeout(timeOut);
            ingestableDeals.forEach(({content, deals}) => handleSaveEmailDeals(content, undefined, deals));
            Promise.all(promises).then(() => {
                refreshDashboards();
                setIngesting(false);
            });
        }, 3000);
    }, 3000, { maxWait: 5000 });

    useEffect(() => {
        if (userGroup) {
            getUnassignedContentItemsFunc({group: userGroup})
                .then((filteredContents: UnassignedContent[]|undefined) =>
                    setIncomingContents(filteredContents ?? []));
        }
    }, [userGroup]);

    useEffect(() => {
        subscribeUnassignedContents(incomingContents, outgoingContents);
        subscribeDocsendIngestionCreation();

        return () => {
            unassignedContentSubscriptionRef.current?.unsubscribe();
            onDocSendIngestionCreationRef.current?.unsubscribe();
        }
        // eslint-disable-next-line
    }, [incomingContents, subscribeUnassignedContents]);

    useEffect(() => {
        if (!!filteredContents.length) {
            getContentsData().then(contents => {
                if (!!contents.length) {
                    const ingestableDeals = contents.flatMap(({content, deals, exists}) =>
                        ({content, deals: deals.filter((deal, i) => !exists[i] && !!deal.website)})
                    ).filter(({deals}) => !!deals.length);

                    setIngestableDeals(ingestableDeals);
                    if (!!ingestableDeals.length && autoIngestFromEmail && !isIngesting)
                        debouncedAutoIngestion();
                }
            });
        }
    // eslint-disable-next-line
    }, [filteredContents]);

    return (<>
        <Badge className={classes.badge} badgeContent={contentsData.length}>
            <Fab className={classNames(classes.incomingContentsButton,
                menuOpen && classes.incomingContentsOpen)}
                onClick={event => setAnchorEl(event.currentTarget)}>
                <MailIcon className={classes.icon} />
            </Fab>
        </Badge>
        {!!anchorEl && (
            <UnassignedContentsModal
                unassignedContents={contentsData}
                anchorEl={anchorEl}
                onAnchorEl={setAnchorEl}
                onSaveEmailDeals={handleSaveEmailDeals}
                onDocsendIngestion={handleDocsendIngestion}
                onRemoveContent={handleRemoveContent} />
        )}
    </>);
};

export default UnassignedContentsBadge;
