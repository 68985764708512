import React, { useCallback, useContext, useMemo, useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { LoadingButton } from "@mui/lab";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { addDashboardQueryFunc, updateDashboardFunc } from "../../../lib/helper";
import { Dashboard, DashboardQuerySource } from "../../../types/files";
import { DashboardsContext } from "../../../contexts/DashboardsContext";
import DashboardsSearchOrCreate from "../../molecules/dashboards-search-create/DashboardsSearchOrCreate";
import StageSelector from "../../molecules/crunchbase-modal/StageSelector";
import WebsiteUrlInput from "../../molecules/crunchbase-modal/WebsiteUrlInput";
import DashboardDeckInput from "../../molecules/dashboard-deck/DashboardDeckInput";
import { ExtFile } from "@files-ui/react";
import {AuthContext} from "../../../contexts/AuthContext";
import useFileUploader from "../../../hooks/useFileUploader";
import { AnswerQuestionResponseType } from "../../../types/search";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
    dialog: {
        "& .MuiDialog-paper": {
            width: 550,
            height: 'fit-content',
            padding: '16px 24px',
            borderRadius: 24,
        }
    },
    dialogTitle: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: 'unset',
        color: 'black',
        fontWeight: 'bold',
        fontFamily: 'Inter',
        fontSize: '1.3rem',
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        padding: '8px 0',
        height: '100%',
        overflowY: 'hidden',
    },
    dialogActions: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: 0,
    },
    block: {
        width: '100%',
        height: 'fit-content',
        padding: '12px 16px',
        borderRadius: 16,
        background: 'rgba(240,254,254,0.7)',
        border: `2px solid ${theme.colors.primary['100']}`,
        boxShadow: '0px 4px 10px -1px rgba(16, 24, 40, 0.06)',
        transition: '0.5s ease-in-out',
    },
    title: {
        fontFamily: 'Inter',
        fontSize: '1.1rem !important',
        fontWeight: '700 !important',
        color: theme.palette.primary.main,
    },
    createButton: {
        height: 36,
        minWidth: 72,
        borderRadius: '24px !important',
        borderWidth: 3,
        fontFamily: 'Lato',
        fontSize: '0.9rem !important',
        textTransform: 'none !important' as any,
        fontWeight: 'bold !important',
        "&:hover": {
            borderWidth: 3,
            fontWeight: 'bold !important',
        },
    },
    success: {
        background: theme.colors.success[600],
        "&:hover, &:disabled": {
            background: `${theme.colors.success[600]} !important`,
        },
        "& > svg": {
            fill: 'white !important',
        }
    },
    failed: {
        background: theme.colors.error[600],
        "&:hover, &:disabled": {
            background: `${theme.colors.error[600]} !important`,
        },
        "& > svg": {
            fill: 'white !important',
        }
    },
    blocked: {
        "&:hover, &:disabled": {
            cursor: 'not-allowed !important',
        },
        cursor: 'not-allowed !important',
    },
    label: {
        minWidth: 80,
        fontFamily: 'Inter',
        fontSize: '1rem',
        fontWeight: 'bold',
        color: 'black',
    },
    link: {
        fontFamily: 'Inter',
        fontSize: '0.85rem',
        fontWeight: 'bold',
        color: theme.palette.primary.main,
        textDecoration: 'none',
        "&:hover": {
            textDecoration: 'none !important',
        },
        cursor: 'pointer',
    },
    caption: {
        fontFamily: 'Inter',
        fontSize: '0.85rem',
        color: theme.colors.neutral['500'],
    },
    commonButton: {
        minWidth: 80,
        borderRadius: 32,
        textTransform: 'none',
        fontWeight: 'bold',
    },
    cancelButton: {
        width: 100,
        borderRadius: 32,
        borderColor: theme.colors.neutral['100'],
        backgroundColor: theme.colors.neutral['100'],
        color: theme.colors.neutral['500'],
        textTransform: 'none',
        fontWeight: 'bold',
        transition: 'ease-in-out 300ms',
    },
}));

const GlobalUrlPattern = /^(?:(?:(?:https?):\/\/)|(?:www\.|(?!www))[A-Za-z0-9.-]+[A-Za-z]{2,}\/?)?[A-Za-z0-9-._~:/?#[\]@!$&'()*+,;=%]+$/i;

const AddEditCompanyModal: React.FC<{
    isOpen: boolean,
    dashboardId?: string,
    onClose: () => void,
}> = ({ isOpen, dashboardId, onClose }) => {
    const classes = useStyles();
    const { userGroup } = useContext(AuthContext);
    const { dashboards } = useContext(DashboardsContext);
    const { uploadToS3, docsendIngestion } = useFileUploader({});

    const [extFiles, setExtFiles] = useState<ExtFile[]>([]);
    const [status, setStatus] = useState<string>('');
    const [execute, setExecute] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [name, setName] = useState<string>('');
    const [customUrl, setCustomUrl] = useState<string>('');
    const [deckLink, setDeckLink] = useState<string>('');
    const [stage, setStage] = useState<string>('');

    const dashboard = useMemo(() => dashboards.find(d => d.id === dashboardId), [dashboardId, dashboards]);

    const executeDisabled = useMemo(() => (!name.trim()
        // || (!customUrl && status === 'create')
        || (!loading && status === 'success')
        || ['failed', 'blocked', 'existing'].includes(status)
    ), [name, loading, status]);

    const saveWebQueryAnswer = useCallback(async (dashboard: Dashboard, customUrl: string) => {
        let permalink = customUrl;

        if (/^https?:\/\//i.test(customUrl)) {
            permalink = customUrl.replace(/^https?:\/\/(?:www\.)?/, "https://");
        } else if (/^(www\.)?\S+\.\S+$/.test(customUrl)) {
            permalink = "https://" + customUrl.replace(/^www\./, '');
        } else if (/^\S+\.\S+$/.test(customUrl)) {
            permalink = "https://" + customUrl;
        }

        if (GlobalUrlPattern.test(permalink)) {
            await addDashboardQueryFunc(
                dashboard!.id,
                'Website',
                userGroup!,
                dashboard!.title,
                JSON.stringify({
                    answer: permalink,
                    type: AnswerQuestionResponseType.URL,
                }),
                undefined,
                301,
                true,
                DashboardQuerySource.CRUNCHBASE,
            );
        }
    }, [userGroup]);

    const handleCreate = useCallback(async (dashboard: Dashboard) => {
        const uploadables = await new Promise<{ blob: Blob; filename: string; }[]>((resolve) => {
            (async () => new Blob([new Uint8Array(await extFiles[0].file!.arrayBuffer())], {type: extFiles[0].type }))()
                .then((blob: Blob) => resolve([{ blob, filename: extFiles[0].name! }]))
                .catch(() => resolve([]));
        });

        Promise.all([
            updateDashboardFunc({...dashboard,
                investmentStage: stage,
                externalLinks: !!deckLink ? [deckLink] : null,
            }),
            saveWebQueryAnswer(dashboard, customUrl),
            !!extFiles.length ? uploadToS3(dashboard, uploadables)
                : docsendIngestion(dashboard, deckLink || undefined),
        ]).then(() => onClose());
        setExecute('');
    // eslint-disable-next-line
    }, [customUrl, deckLink, extFiles, stage]);

    const handleExecute = useCallback(() => {
        setLoading(true);
        setExecute('create');
    }, []);

    return (<>
        <Dialog className={classes.dialog} open={isOpen} onClose={() => !loading ? onClose() : undefined}>
            <DialogTitle className={classes.dialogTitle}>
                {!dashboard ? 'Add a ' : 'Edit existing '}
                {'company'}
            </DialogTitle>
            <Divider sx={{ margin: '8px 0'}} />
            <DialogContent className={classes.dialogContent}>
                <Stack direction="column" spacing={2} alignItems="stretch" justifyContent="flex-start">
                    <Stack direction="row" alignItems="center" justifyContent="flex-start" width="100%">
                        <Typography className={classes.label}>{'Name'}</Typography>
                        <DashboardsSearchOrCreate create mediumFit notify execute={execute}
                            onSearch={(search, status) => {setName(search); setStatus(status);}}
                            onCreate={handleCreate} />
                    </Stack>
                    <Stack direction="row" alignItems="center" justifyContent="flex-start" width="100%">
                        <Typography className={classes.label}>{'Website'}</Typography>
                        <WebsiteUrlInput customUrl={customUrl} onCustomUrl={setCustomUrl} inlineCaption />
                    </Stack>
                    <Stack direction="row" alignItems="center" justifyContent="flex-start" width="100%">
                        <Typography className={classes.label}>{'Deck'}</Typography>
                        <DashboardDeckInput deckLink={deckLink} extFiles={extFiles} onUpdate={setExtFiles} onDeckLink={setDeckLink} />
                    </Stack>
                    <Stack direction="row" alignItems="center" justifyContent="flex-start">
                        <Typography className={classes.label}>{'Stage'}</Typography>
                        <Box sx={{ "& .MuiFormControl-root > .MuiInputBase-root": { height: '36px' } }}>
                            <StageSelector onSelectStage={setStage} filled />
                        </Box>
                    </Stack>
                </Stack>
            </DialogContent>
            <Divider sx={{ margin: '8px 0'}} />
            <DialogActions className={classes.dialogActions}>
                <Button
                    variant="outlined"
                    className={classes.cancelButton}
                    onClick={() => onClose()}
                > {'Cancel'} </Button>
                <LoadingButton
                    variant="contained"
                    className={classNames(classes.createButton,
                        (status === 'blocked') && classes.blocked,
                        (!loading && status === 'success') && classes.success,
                        (status === 'failed') && classes.failed,
                    )}
                    loading={loading}
                    disabled={executeDisabled}
                    onClick={handleExecute}>
                    {(status === 'success') ? (
                        <CheckCircleOutlineIcon />
                    ) : (status === 'failed') ? (
                        <ErrorOutlineIcon />
                    ) : (
                        <>{'Add'}</>
                    )}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    </>);
}

export default AddEditCompanyModal;