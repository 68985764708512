import React, { useContext } from "react";
import { DashboardContext } from "../../../contexts/DashboardContext";
import useDashboardQuery from "../../../hooks/useDashboardQuery";
import TextAnswer from "../dashboard-query-answer/TextAnswer";
import InlineTextQueryContainer from "../dashboard-query-container/InlineTextQueryContainer";

const HeadcountQuery: React.FC<{}> = () => {
    const { isPublicView } = useContext(DashboardContext);
    const { queryAnswer, updateQuery } = useDashboardQuery('Headcount');

    return (<>
        <InlineTextQueryContainer
            queryAnswer={queryAnswer}
            displayMenu={!isPublicView}
            editRows={1}
            onEdit={updateQuery}
            contentOnly>
            <TextAnswer answeredQuestion={queryAnswer.answeredQuestion} />
        </InlineTextQueryContainer>
    </>);
}

export default HeadcountQuery;
