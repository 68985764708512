import React from "react";
import useDashboardQuery from "../../../hooks/useDashboardQuery";
import TextAnswer from "../dashboard-query-answer/TextAnswer";
import EmptyQueryContainer from "../dashboard-query-container/EmptyQueryContainer";

const TextQuery: React.FC<{ queryTitle: string }> = ({ queryTitle }) => {
    const { queryAnswer } = useDashboardQuery(queryTitle);

    return (<>
        <EmptyQueryContainer queryAnswer={queryAnswer} contentOnly>
            <TextAnswer answeredQuestion={queryAnswer.answeredQuestion} />
        </EmptyQueryContainer>
    </>);
}

export default TextQuery;
