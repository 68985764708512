import { Stack, Typography } from "@mui/material";
import MetricsQuery from "../../molecules/dashboard-queries/MetricsQuery";
import { makeStyles } from "@mui/styles";
import AddMetrics from "../../atoms/AddMetrics";

const useStyles = makeStyles((theme) => ({
    title: {
        fontFamily: 'Inter',
        fontSize: '1.2rem',
        fontWeight: 'bold',
        color: theme.palette.primary.main,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
}));

const KeyMetricsColumnQueries: React.FC<{}> = () => {
    const classes = useStyles();

    return (<>
        <Stack spacing={2} width="100%">
            <Typography className={classes.title}>
                {'Round Details'}
            </Typography>
            <MetricsQuery roundDetails />
            <Typography className={classes.title}>
                {'Metrics'}
            </Typography>
            <MetricsQuery />
            <AddMetrics />
        </Stack>
    </>);
}

export default KeyMetricsColumnQueries;