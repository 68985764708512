import React, { ReactNode } from 'react';
import { Button, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import { AnsweredQuestion } from '../../../contexts/DashboardQueriesContext';
import useContentCopy from '../../../hooks/useContentCopy';
import classnames from 'classnames';
import theme from '../../../theme';
import AnswerMenu from './AnswerMenu';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

const useStyles = makeStyles((theme) => ({
    title: {
        fontFamily: 'Inter',
        fontSize: '1.2rem',
        fontWeight: 'bold',
        color: theme.palette.primary.main,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    gradientTitle: {
        color: '#7bd4d4',
        fontStyle: 'italic',
        fontSize: '18px',
    },
    AIIcon: {
        height: 20,
        width: 20,
        marginLeft: 8,
        alignItems: 'baseline',
        color: theme.palette.primary.main,
    },
    gradientAIicon: {
        color: '#7bd4d4',
    },
    copyButton: {
        minWidth: 32,
        color: theme.colors.neutral['400'],
        padding: 'unset !important',
        "&:hover": {
            color: theme.colors.neutral['600'],
            backgroundColor: 'inherit',
        },
    },
    lastUpdatedDate: {
        color: theme.colors.neutral['600'],
        fontSize: '0.8rem',
        whiteSpace: 'nowrap',
    },
    icon: {
        width: 32,
        height: 32,
        padding: 'unset',
        "& > .MuiSvgIcon-root": {
            fill: theme.colors.neutral['600'],
        }
    },
}));

const AnswerHeader: React.FC<{
    answeredQuestion: AnsweredQuestion,
    optionalChild?: ReactNode,
    lastUpdatedDate?: Date,
    manualOverride?: boolean,
    hideAiGenerated?: boolean,
    hideCopy?: boolean,
    showEditIcon?: boolean,
    displayMenu?: boolean,
    gradientStyle?: boolean,
    onSetIsEditingAnswer?: (question: string | undefined) => void,
    onSetEditAnswer?: (answer: string | undefined) => void,
    onModal?: (open: boolean) => void,
    onDelete?: () => void,
}> = ({
    answeredQuestion, optionalChild, lastUpdatedDate, manualOverride, hideAiGenerated, hideCopy, showEditIcon, displayMenu, gradientStyle,
    onSetIsEditingAnswer, onSetEditAnswer, onModal, onDelete,
}) => {
    const classes = useStyles();
    const { copyQueryAnswer } = useContentCopy();

    return (<>
        <Stack direction="row" alignItems="center" justifyContent="space-between" px={2}>
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start" overflow="hidden" height="32px">
                <Typography className={classnames(classes.title, gradientStyle && classes.gradientTitle)}>
                    {answeredQuestion.question}
                </Typography>
                {manualOverride ? (
                    <Typography component="span"
                        fontSize="0.85rem"
                        color={theme.colors.neutral['600']}
                        sx={{ ml: 1 }}> {'(edited)'} </Typography>
                ) : (!hideAiGenerated) && (
                    <Tooltip title={"AI-generated"} placement="top-start">
                        <AutoAwesomeIcon className={classnames(classes.AIIcon, gradientStyle && classes.gradientAIicon)} />
                    </Tooltip>
                )}
                {lastUpdatedDate && (
                    <Typography className={classes.lastUpdatedDate}>{moment(lastUpdatedDate).fromNow()}</Typography>
                )}
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="flex-end">
                {optionalChild}
                {!hideCopy && (<>
                    <Button className={classes.copyButton}
                        startIcon={<ContentCopyIcon sx={{ height: '20px', width: '20px' }} />}
                        onClick={(e: any) => {
                            e.preventDefault();
                            e.stopPropagation();
                            copyQueryAnswer(answeredQuestion.question);
                        }}
                    />
                </>)}
                {!!onModal && (
                    <Button className={classes.copyButton}
                        startIcon={<OpenInFullIcon sx={{ height: '24px', width: '24px' }} />}
                        onClick={(e: any) => {
                            e.preventDefault();
                            e.stopPropagation();
                            onModal?.(true);
                        }}
                    />
                )}
                {showEditIcon && (
                    <IconButton size="small"
                        className={classes.icon}
                        onClick={(e) => {
                            e.stopPropagation();
                            onSetEditAnswer?.(answeredQuestion.answer as string);
                            onSetIsEditingAnswer?.(answeredQuestion.question);
                        }} disableRipple>
                        <EditOutlinedIcon fontSize="small" />
                    </IconButton>
                )}
                <AnswerMenu
                    answeredQuestion={answeredQuestion}
                    displayMenu={displayMenu}
                    onSetIsEditingAnswer={onSetIsEditingAnswer}
                    onSetEditAnswer={onSetEditAnswer}
                    onDelete={onDelete}
                />
            </Stack>
        </Stack>
    </>);
}

export default AnswerHeader;
