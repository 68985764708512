import TeamQuery from "../../molecules/dashboard-queries/TeamQuery";
import KeyPeopleQuery from "../../molecules/dashboard-queries/KeyPeopleQuery";
import AddPeople from "../../atoms/AddPeople";

const PeopleColumnQueries: React.FC<{}> = () => {
    return (<>
        <TeamQuery />
        <KeyPeopleQuery />
        <AddPeople />
    </>);
}

export default PeopleColumnQueries;