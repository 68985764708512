import React, { ReactNode, useCallback, useContext, useEffect, useState } from "react";
import { Typography, Stack, Collapse, Button } from "@mui/material";
import { CompanyFile, DashboardNote } from "../../../types/files";
import { MetricData, NoteMetric } from "../../../types/search";
import moment, { Moment } from "moment";
import {DashboardQueriesContext} from "../../../contexts/DashboardQueriesContext";
import DashboardCreationElement from "../../molecules/dashboard-timeline-elements/DashboardCreationElement";
import FoundedElement from "../../molecules/dashboard-timeline-elements/FoundedElement";
import FundingElement from "../../molecules/dashboard-timeline-elements/FundingElement";
import { DashboardContext } from "../../../contexts/DashboardContext";
import MetricsTimeline, { MetricsData } from "../../atoms/MetricsTimeline";
import theme from "../../../theme";
import { DateFmt, DollarMetrics, PercentMetrics, RevenueSubcategories } from "../../molecules/dashboard-query-answer/MetricsAnswer";
import AddCustomMetricsModal from "../../modals/dashboard-queries/AddCustomMetricsModal";
import { makeStyles } from "@mui/styles";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';

export enum DashboardEventType {
    Start = "start",
    Action = "action"
}

export interface DashboardEvent {
    date: Date;
    type: DashboardEventType;
    files: CompanyFile[];
    notes: DashboardNote[];
}

const useStyles = makeStyles(() => ({
    expandIcon: {
        fill: '#666666 !important',
        alignItems: 'center !important',
        justifyContent: 'right',
        cursor: 'pointer',
    },
    addSectionButton: {
        fontFamily: 'Lato',
        fontSize: '1rem',
        fontWeight: '500 !important',
        color: theme.palette.primary.light,
        background: 'transparent',
        textTransform: 'none !important' as any,
        transition: 'ease-in-out 300ms',
        "&:hover": {
            background: 'transparent',
        }
    },
    icon: {
        width: 16,
        height: 'auto',
        stroke: theme.palette.primary.light,
    },
}));

const QueryElements = ['Funding', 'Note Metrics', 'Founded'];

const DashboardMetricsTimeline: React.FC<{}> = () => {
    const classes = useStyles();
    const { dashboard, isPublicView } = useContext(DashboardContext);
    const { queries } = useContext(DashboardQueriesContext);

    const [isExpanded, setExpanded] = useState<boolean>(true);
    const [withFounded, setWithFounded] = useState<boolean>(false);
    const [dateHeader, setDateHeader] = useState<string[]>([]);
    const [metricsData, setMetricsData] = useState<MetricsData[][]>([]);
    const [timelineElements, setTimelineElements] = useState<ReactNode[]>([]);
    const [addCustomMetricsModalOpen, setAddCustomMetricsModalOpen] = useState<boolean>(false);
    const [customValues, setCustomValues] = useState<MetricsData|undefined>(undefined);
    const [freeStyled, setFreeStyled] = useState<number[]>([]);

    const loadTimelineElements = useCallback((metrics: any[], founded: any, funding: any[], events: DashboardEvent[]) => {
        const keyDateFmt = 'MMM YYYY';
        const timelineItems: { [tlDate: string]: {
            funding?: { funding_type: string, money_raised?: string, investor_list: string, },
            founded?: { year: string, month?: string, },
            events?: { created?: Date },
            metrics?: { metric: string, value: string, date: Moment, units?: string },
        }[] } = {};
        const timelineElements: (ReactNode | undefined)[] = [];
        const header: string[] = ['Metrics'];
        const rows: MetricsData[][] = [];
        let hasFoundedYear = false;

        if (typeof founded === 'number') {
            const year = moment({year: founded}).format('YYYY');

            timelineItems[year] = [];
            timelineItems[year].push({founded: { year }});
            hasFoundedYear = true;
        } else {
            hasFoundedYear = false;
        }

        if (Array.isArray(funding) && !!funding.length) {
            funding.filter((funding: any) => !!funding.announced_date)
                .forEach((funding: any) => {
                    const {announced_date, funding_type, money_raised, investor_list} = funding;
                    const tlDate = moment({
                        year: announced_date.year, month: announced_date.month - 1, day: announced_date.day,
                    }).format(keyDateFmt);
                    const moneyRaised = money_raised ? `US$${(funding.money_raised >= 1e6)
                        ? (funding.money_raised / 1e6).toFixed(1) + 'M': (funding.money_raised >= 1e3)
                            ? (funding.money_raised / 1e3).toFixed(1) + 'K': funding.money_raised.toString()}`
                                .replace('.0', '') : undefined;
                    const investorList = investor_list?.map((investor: any) => investor.name)?.join(", ");

                    if (!timelineItems[tlDate])
                        timelineItems[tlDate] = [];
                    timelineItems[tlDate].push({funding: {funding_type, money_raised: moneyRaised, investor_list: investorList}});
                });
        }

        if (Array.isArray(metrics) && !!metrics.length) {
            const historyRecord = metrics.splice(1) ?? [];
            const historyList = Object.entries(metrics[0] ?? {})
                .map(([metric, value]) => {
                    const filteredHistory = historyRecord
                        .map(history => history[metric as keyof NoteMetric] as MetricData)
                        .filter(history => !!history && Object.values(history).every(value => !!value));
                    const sortedHistory = [{...value as MetricData}, ...filteredHistory as MetricData[]]
                        .sort((prev, next) => moment(prev.date).isAfter(moment(next.date)) ? -1 : 1);

                    return ({ metric, history: sortedHistory });
                });

            const fundraisingHistory = historyList.find(metrics => metrics.metric === 'Fundraising')?.history;
            const valuationHistory = historyList.find(metrics => metrics.metric === 'Valuation')?.history;
            const revenueHistory = historyList.find(metrics => metrics.metric === 'Revenue')?.history;
            const subRevenueHistories = historyList.filter(metrics =>
                RevenueSubcategories.map(cat => cat.toLowerCase()).includes(metrics.metric.trim().toLowerCase()))
                    ?.map(metrics => ({ metric: metrics.metric, history: metrics.history }));
            const acvHistory = historyList.find(metrics => metrics.metric === 'ACV')?.history;
            const nullMetrics: {metric: string, history: MetricData}[] = [];

            if (Array.isArray(fundraisingHistory) && Array.isArray(valuationHistory)) {
                valuationHistory.forEach(valH => fundraisingHistory.forEach(fundH => {
                    if (moment(fundH?.date).format(DateFmt) === moment(valH?.date).format(DateFmt)
                        && fundH?.units === valH?.units && fundH?.value === valH?.value)
                        nullMetrics.push({ metric: 'Valuation', history: valH });
                }));
            }

            if (Array.isArray(revenueHistory)) {
                let sameRevenue: string[] = [];
                let sameSubRevenue: string[] = [];

                subRevenueHistories?.forEach(({metric, history}, mainIndex, self) => {
                    if (moment(revenueHistory?.[0]?.date).format(DateFmt) === moment(history?.[0]?.date).format(DateFmt)
                        && revenueHistory?.[0]?.units === history?.[0]?.units
                        && revenueHistory?.[0]?.value === history?.[0]?.value)
                        sameRevenue.push(metric);

                    const matchingMetrics = self.filter(({history: otherHistory}, otherIndex) =>
                            otherIndex !== mainIndex && history?.[0]?.value === otherHistory?.[0]?.value
                        ).map(({metric}) => metric);

                    if (matchingMetrics.length > 0)
                        sameSubRevenue = Array.from(new Set([...sameSubRevenue, metric, ...matchingMetrics]));
                });

                if (sameRevenue.length === 1) {
                    nullMetrics.push({ metric: 'Revenue', history: revenueHistory?.[0] });
                } else if (sameRevenue.length > 1 || !!sameSubRevenue.length) {
                    subRevenueHistories?.forEach(({metric, history}) => {
                        if ([...sameRevenue, ...sameSubRevenue].join(',').includes(metric))
                            nullMetrics.push({ metric, history: history?.[0] });
                    });
                }

                acvHistory?.forEach(acvH => {
                    revenueHistory.forEach(revH => {
                        if (moment(revH?.date).format(DateFmt) === moment(acvH?.date).format(DateFmt)
                            && revH?.units === acvH?.units && revH?.value === acvH?.value)
                            nullMetrics.push({ metric: 'ACV', history: acvH });
                    });
                });
            }

            nullMetrics.forEach(metricsData => { metricsData.history.value = null });

            historyList.forEach(({metric, history}) => {
                history.forEach(({date, units, value}) => {
                    const tlDate = moment(date || new Date()).format(keyDateFmt);
                    let tlValue: string|number|null = value;
                    let tlUnits = units ?? undefined;

                    if (tlValue === null) {
                        tlUnits = '#';
                        tlValue = '-';
                    } else {
                        let cleanInput = String(tlValue!).replace(/[^0-9bmkt.$%-]/gi, '').toLowerCase();
                        let multiplier = 1;

                        if (cleanInput.includes('t'))
                            multiplier = Math.pow(10, 12);
                        else if (cleanInput.includes('b'))
                            multiplier = Math.pow(10, 9);
                        else if (cleanInput.includes('m'))
                            multiplier = Math.pow(10, 6);
                        else if (cleanInput.includes('k'))
                            multiplier = 1000;

                        const numericValue = multiplier * parseFloat(cleanInput.replace(/[^0-9.-]/g, ''));

                        if (!tlUnits && DollarMetrics.includes(metric)) {
                            tlUnits = '$';
                            tlValue = `${numericValue < 0 ? '-' : ''}$${Math.abs(numericValue).toFixed(0)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        } else if (!tlUnits && PercentMetrics.includes(metric)) {
                            tlUnits = '%';
                            tlValue = `${numericValue.toFixed(1)}%`;
                        } else {
                            if (tlUnits === '$')
                                tlValue = `${numericValue < 0 ? '-' : ''}$${Math.abs(numericValue).toFixed(0)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                            else if (tlUnits === '%')
                                tlValue = `${numericValue.toFixed(1)}%`;
                            else
                                tlValue = `${numericValue}`;
                        }
                    }

                    if (!timelineItems[tlDate])
                        timelineItems[tlDate] = [];
                    if (!timelineItems[tlDate].find(({ metrics }) => metrics?.metric === metric))
                        timelineItems[tlDate].push({ metrics: { metric, value: tlValue, date: moment(tlDate), units: tlUnits } });
                });
            });
        }

        events.forEach((event) => {
            if (event.type === DashboardEventType.Start) {
                const tlDate = moment(event.date).format(keyDateFmt);

                if (!timelineItems[tlDate])
                    timelineItems[tlDate] = [];
                timelineItems[tlDate].push({events: {created: event.date }});
            }
        });

        Object.entries(timelineItems as any)
            .sort((curr: any, next: any) => moment(curr, keyDateFmt).isBefore(moment(next, keyDateFmt)) ? -1 : 1)
            ?.forEach(([key, value], i, self) => {
                const foundedItems = (value as {founded?: { year: string, month?: string }}[])
                    .map((item) => ({...item.founded})).filter((item) => !!Object.keys(item).length);
                const fundingItems = (value as {funding?: { funding_type: string, money_raised?: string, investor_list: string, }}[])
                    .map((item) => ({...item.funding})).filter((item) => !!Object.keys(item).length);
                const metricItems = (value as {metrics: MetricsData}[])
                    .map((item) => ({...item.metrics})).filter((item) => !!Object.keys(item).length);
                const eventItems = (value as {events?: { created: Date, files: CompanyFile[], notes: DashboardNote[] }}[])
                    .map((item) => ({...item.events})).filter((item) => !!Object.keys(item).length);

                if (!!foundedItems.length && self.length > 1) {
                    timelineElements.push(<FoundedElement items={foundedItems} />);
                    return;
                }

                header.push(key);
                metricItems.forEach(metricData => {
                    const lookUpRow = rows.find(row => row[0].metric === metricData.metric) ?? [];

                    if (!!lookUpRow?.length) {
                        Array(header.length - lookUpRow.length).fill({value: '-', date: undefined, units: undefined})
                            .forEach(emptyValues => lookUpRow.push({...metricData, ...emptyValues}));
                        lookUpRow[header.length - 1] = {...metricData};
                    } else {
                        const row: any[] = [];

                        Array(header.length).fill({value: '-', date: undefined, units: undefined})
                            .forEach(emptyValues => row.push({...metricData, ...emptyValues}));
                        row[header.length - 1] = {...metricData};
                        rows.push(row);
                    }
                });
                rows.forEach(row => {
                    Array(header.length - row.length).fill({value: '-', date: undefined, units: undefined})
                        .forEach(emptyValues => row.push({...row[0], ...emptyValues}));
                    header.forEach((tlDate, i) => { row[i].date = moment(tlDate); });
                });

                if (!fundingItems.length && !!metricItems.length && !eventItems.length) {
                    timelineElements.push(undefined);
                } else if (!!fundingItems.length || !!metricItems.length || !!eventItems.length) {
                    if (!!eventItems.length)
                        setFreeStyled(prev => [...prev, i]);
                    timelineElements.push(
                        <Stack direction="column" spacing={1} alignItems="center" key={'timeline-element-' + i}>
                            {!!eventItems.length ? eventItems.map((event, i) => (
                                <DashboardCreationElement item={event} key={'dashboard-metrics-timeline-228-' + i} />
                            )) : (<>
                                <FundingElement items={fundingItems} />
                                <Typography color="#666666" fontStyle="italic" fontSize="0.9rem">
                                    {`${moment(new Date(key)).format(keyDateFmt)}`}
                                </Typography>
                            </>)}
                        </Stack>
                    );
                }
            });

        setTimelineElements(timelineElements);
        setWithFounded(hasFoundedYear);
        setDateHeader(header);
        setMetricsData(rows.filter(row => !row.every(metricData => metricData.value === '-')));
    }, []);

    useEffect(() => {
        if (!!dashboard && queries.filter((query) => QueryElements.includes(query.title))?.some((query) => !!query.answer)) {
            const queryMap: { [key: string]: any } = queries.reduce((result, query) => {
                if (QueryElements.includes(query.title)) {
                    if (query.title === 'Note Metrics') {
                        const answer = query?.answer ? JSON.parse(query.answer)?.answer : null;
                        const history = query?.history ? JSON.parse(query.history) : [];

                        result[query.title] = [answer, ...history].map((history: any) => history?.answer ?? history) ?? [];
                    } else {
                        result[query.title] = query?.answer ? JSON.parse(query.answer)?.answer : null;
                    }
                }

                return result;
            }, {} as any);

            loadTimelineElements(
                queryMap['Note Metrics'],
                queryMap['Founded'],
                queryMap['Funding'],
                [{
                    date: new Date(dashboard.createdAt),
                    files: [],
                    notes: [],
                    type: DashboardEventType.Start,
                }]
            );
        }
    // eslint-disable-next-line
    }, [dashboard, queries]);

    if (!!timelineElements.length) {
        return (<>
            <Collapse in={isExpanded} collapsedSize={40}>
                <Stack direction="row" spacing={2} alignItems="flex-start" marginLeft={1} marginBottom={2}>
                    <Typography fontFamily="Inter" fontWeight="bold" fontSize="1.2rem" color={theme.colors.neutral['400']}>
                        {'Financial Timeline'}
                    </Typography>
                    <ExpandMoreIcon
                        className={classes.expandIcon}
                        sx={isExpanded ? { transform: 'rotate(180deg) !important' } : {}}
                        onClick={(e)=> {
                            e.stopPropagation();
                            e.preventDefault();
                            setExpanded((prev) => !prev);
                        }} />
                </Stack>
                <MetricsTimeline
                    elements={timelineElements}
                    visibleItems={6}
                    dateHeader={dateHeader}
                    metricsData={metricsData}
                    unstyledStart={withFounded}
                    freeStyled={freeStyled}
                    readOnly={isPublicView}
                    onEdit={(customValues) => {
                        setCustomValues(customValues);
                        setAddCustomMetricsModalOpen(true);
                    }} />
                <Stack alignItems="flex-start" marginLeft={8} marginBottom={2} width="100%">
                    <Button variant="text"
                        className={classes.addSectionButton}
                        startIcon={<AddIcon className={classes.icon} />}
                        onClick={() => setAddCustomMetricsModalOpen(true)}>
                        {'Add metrics'}
                    </Button>
                </Stack>
            </Collapse>
            {addCustomMetricsModalOpen && (
                <AddCustomMetricsModal
                    isOpen={addCustomMetricsModalOpen}
                    customValues={customValues}
                    onClose={() => {
                        setCustomValues(undefined);
                        setAddCustomMetricsModalOpen(false);
                    }} />
            )}
        </>);
    }

    return (<></>);
};

export default DashboardMetricsTimeline;