import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { DashboardQueryAnswer } from "../../../contexts/DashboardQueriesContext";
import useDashboardQuery from "../../../hooks/useDashboardQuery";
import FundingQueryModal from "../../modals/dashboard-queries/FundingQueryModal";
import FundingListAnswer, { FundingAnsweredQuestion } from "../dashboard-query-answer/FundingListAnswer";
import EmptyQueryContainer from "../dashboard-query-container/EmptyQueryContainer";

const FundingQuery: React.FC<{}> = () => {
    const { queryAnswer } = useDashboardQuery('Funding');
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [updatedQueryAnswer, setUpdatedQueryAnswer] = useState<DashboardQueryAnswer|undefined>(undefined);

    useEffect(() => {
        setUpdatedQueryAnswer(({ ...queryAnswer,
            answeredQuestion: { ...queryAnswer.answeredQuestion, question: 'Prior Funding'}
        }));
    }, [queryAnswer]);

    return (<>
        <EmptyQueryContainer queryAnswer={updatedQueryAnswer ?? queryAnswer} onModal={setModalOpen} hoverEffect>
            <Box sx={{ cursor: 'pointer' }}
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setModalOpen(true);
                }}> <FundingListAnswer
                        answeredQuestion={queryAnswer.answeredQuestion as FundingAnsweredQuestion}
                        showPriorOnly />
            </Box>
        </EmptyQueryContainer>
        {modalOpen && (
            <FundingQueryModal isOpen={modalOpen} onClose={() => setModalOpen(false)} />
        )}
    </>);
}

export default FundingQuery;
