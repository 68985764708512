import React, { useEffect, useState } from 'react';
import { Stack, Typography } from "@mui/material";
import { AnsweredQuestion } from '../../../contexts/DashboardQueriesContext';
import { splitter } from '../../../helpers/textSplitter';
import { NoInfoYet } from '../../../types/search';
import theme from '../../../theme';

const DigestAnswer: React.FC<{
    answeredQuestion: AnsweredQuestion,
    onModal?: (open: boolean) => void,
 }> = ({ answeredQuestion, onModal }) => {
    const [sentences, setSentences] = useState<string[]>([]);

    useEffect(() => {
        if (typeof answeredQuestion.answer === 'string')
            setSentences(splitter(answeredQuestion.answer as string).map((split) => split.sent));
    }, [answeredQuestion]);

    return (<>
        <Stack direction="column" spacing={1} alignItems="baseline" mb={1}>
            <Typography fontFamily="Inter" color="#666666">
                {!!sentences.length ? (sentences[0]) : (NoInfoYet.answer)}
                <Typography
                    component="span"
                    fontFamily="Inter"
                    fontSize="0.9rem"
                    fontWeight={600}
                    color={theme.palette.primary.light}
                    onClick={() => onModal?.(true)}
                    sx={{ cursor: 'pointer' }}>
                       {' See more...'}
                </Typography>
            </Typography>
        </Stack>
    </>);
}

export default DigestAnswer;
