import { Box, Skeleton, Stack, Typography, } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext, useMemo } from "react";
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { EmailBody } from "../../../types/files";
import classNames from "classnames";
import {DashboardsContext} from "../../../contexts/DashboardsContext";

const useStyles = makeStyles((theme) => ({
    summaryContainer: {
        display: 'block',
        width: '100%',
        height: 'auto',
        background: 'linear-gradient(90deg, rgba(212,236,237,1) 0%, rgba(240,254,254,1) 100%)',
        border: `1px solid ${theme.colors.neutral['100']}`,
        boxShadow: '0px 4px 10px -1px rgba(16, 24, 40, 0.06)',
        borderRadius: 16,
        padding: 16,
        breakInside: 'avoid',
        transition: '0.05s ease-in-out',
    },
    simpleContainer: {
        background: '#fff',
        border: `1px solid ${theme.colors.neutral['300']}`,
        "&:hover": 'unset',
    },
    summaryContentBlock: {
        width: '100%',
        height: 'fit-content',
        maxHeight: '112px',
        overflowY: 'auto',
    },
    summaryTitle: {
        color: '#7bd4d4',
        fontSize: '1rem',
        fontWeight: 'bold',
        fontStyle: 'italic',
        fontFamily: 'Inter',
    },
    simpleTitle: {
        fontFamily: 'Inter',
        fontSize: '0.925rem',
        fontWeight: 'bold',
        color: theme.colors.neutral['700'],
    },
    summaryText: {
        fontFamily: 'Inter',
        fontSize: '0.9rem',
        color: '#048290',
    },
    simpleText: {
        color: theme.colors.neutral['600'],
    },
    aiGeneratedIcon: {
        color: '#7bd4d4',
        height: '20px',
        width: '20px',
        marginLeft: '16px',
    },
    wave: {
        borderRadius: 4,
        height: 24,
        background: `linear-gradient(90deg, ${theme.colors.primary['200']} 0%, ${theme.colors.primary['100']} 50%, ${theme.colors.primary['50']} 100%)`,
        "&::after": {
            background: `linear-gradient(90deg, transparent, ${theme.colors.cyan['100']}, transparent)`,
        },
    },
}));

const ContentSummaryAnswer: React.FC<{
    content: EmailBody & {summary : string},
    simple?: boolean,
 }> = ({ content, simple }) => {
    const classes = useStyles();
    const { mappedOverviewQueries } = useContext(DashboardsContext);

    const queriesLoaded = useMemo(() => !!mappedOverviewQueries.size, [mappedOverviewQueries.size]);

    return (<>
        <Stack className={classNames(classes.summaryContainer, simple && classes.simpleContainer)}>
            <Stack direction="row" alignItems="center" justifyContent="flex-start" mb={1}>
                {simple ? (
                    <Typography className={classes.simpleTitle}>
                        {`Summary of this ${content.type === 'CALL' ? 'call' : 'email'}`}
                    </Typography>
                ): (<>
                    <Typography className={classes.summaryTitle}>Summary</Typography>
                    <AutoAwesomeIcon className={classes.aiGeneratedIcon} />
                </>)}
            </Stack>
            <Stack className={classes.summaryContentBlock}>
                {queriesLoaded ? (
                    <Typography className={classNames(classes.summaryText, simple && classes.simpleText)}>
                        {content.summary || 'No summary yet.'}
                    </Typography>
                ) : (
                    <Box sx={{ width: '100%' }}>
                        {[...Array(3)].map((_, i) => (
                            <Skeleton animation="wave" className={classes.wave} key={'content-summary-answer-100-' + i} />
                        ))}
                    </Box>
                )}
            </Stack>
        </Stack>
    </>);
}

export default ContentSummaryAnswer;
